/**
 * The enum that is used in the drop down and the Application
 */
export enum EApplicationTypes {
  NO_PRIOR_SERVICE = 'NO Prior Service',
  ROTC = 'ROTC',
  TRADITIONAL_RESERVIST = 'Traditional Reservist (TR-AF)',
  ACTIVE_GUARD_RESERVE = 'Active Guard Reserve (AGR-AF)',
  ACTIVE_DUTY = 'Active Duty (AD-AF)',
  AIR_NATIONAL_GUARD = 'Air National Guard (ANG)',
  SISTER_SERVICE = 'Sister Service',
  INDIVIDUAL_MOBILIZATION_AUGMENTEE = 'Individual Mobilization Augmentee (IMA)'
}
