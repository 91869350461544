
import {
  Component,
  Emit,
  Prop,
  Provide,
  Vue,
  Watch
} from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';
import IconComponent from '@/components/IconComponent.vue';
import {
  DropdownOptions,
  FormOptionValue,
  yesNoOptions
} from '@/models/Forms/FormOption';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LabelComponent from './LabelComponent.vue';

@Component<DropdownComponent>({
  components: { IconComponent, FontAwesomeIcon, LabelComponent }
})
export default class DropdownComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: FormOptionValue;

  @Prop()
  prefill?: string;

  @Prop({ default: '' })
  @Provide()
  label!: string;

  @Prop({ default: '' })
  @Provide()
  helpText!: string;

  @Prop({
    default: 'Select one'
  })
  placeholder!: string | undefined;

  @Prop({
    default: () => yesNoOptions
  })
  values!: string[] | DropdownOptions[];

  @Prop({ default: '' })
  defaultOption!: FormOptionValue;

  @Prop({ default: 'Please make a selection' })
  errorText!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: true })
  isUnSelectable!: boolean;

  @Prop({ default: false })
  hideDisabledOptions!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  isPristine = true;

  // False values were being ignored
  getValue(
    item: string | DropdownOptions
  ): FormOptionValue | DropdownOptions | undefined | string {
    if (
      typeof item !== 'string' &&
      (item.value !== undefined || item.value !== null)
    ) {
      return item.value;
    }
    if (typeof item !== 'string' && item.label) {
      return item.label;
    }
    return item;
  }

  get dropdownValue(): FormOptionValue | DropdownOptions | undefined | string {
    if (typeof this.value === 'boolean') {
      return this.value;
    }
    return this.value || this.defaultOption || '';
  }
  set dropdownValue(
    newVal: FormOptionValue | DropdownOptions | undefined | string
  ) {
    // if the data is cleared the newVal will be ''
    // if cleared, try defaultOption first
    if (newVal === '') {
      this.$emit('input', this.defaultOption || null);
    } else {
      this.$emit('input', newVal);
    }
  }

  get showFirstOption(): boolean {
    return !!(
      this.defaultOption ||
      (this.dropdownValue !== '' && this.isUnSelectable)
    );
  }

  get dynamicPlaceholder(): string | undefined {
    return this.dropdownValue === ''
      ? this.placeholder
      : '-- Clear Selection --';
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    return !this.required ? true : !!this.dropdownValue;
  }

  @Watch('isValid')
  @Emit('validationState')
  emitValidationState(): FormInputState {
    return new FormInputState({
      isValid: this.isValid,
      errorMessage: this.errorText
    });
  }

  mounted(): void {
    // this will allow for programmatic auto-fill
    if (this.defaultOption || typeof this.defaultOption === 'boolean') {
      this.dropdownValue = this.defaultOption;
    }
    this.emitValidationState();
    // prefill from another entity
    if (this.prefill) {
      this.dropdownValue = this.prefill;
    }
  }

  @Emit('blur')
  onBlur(): void {
    this.isPristine = false;
  }
}
