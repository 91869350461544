import {
  BadgeType,
  EArb,
  ECivilianFlightCFITypes,
  ECivilianFlightRatingCategory,
  ECivilianFlightRatingClass,
  ECivilianPilotCertificate,
  EEthnicity,
  EGainsPriority,
  EGender,
  EPreferredUptBaseTypes,
  ERace,
  ESponsorUnits
} from '@/enums';
import { ECurrentGrade, EMilitaryRank } from '@/enums/ECurrentGrade';
import { EDependentsTypes } from '@/enums/EDependentsTypes';
import { EHoldTypes } from '@/enums/EHoldTypes';
import {
  EMedicalClearance,
  EMedicalClearanceStatus
} from '@/enums/EMedicalClearance';
import { Application } from '@/models/Entities/Application/Application';
import { Board } from '@/models/Entities/Board';
import BreakInTraining from '@/models/Entities/BreakInTraining';
import {
  ApplicantTypes,
  HousingFinancialTypes,
  PropertyType,
  RecruitmentStatus
} from '@/models/Entities/Personas/Constants';
import {
  DutyStatus,
  ExitReason,
  GroupInfo,
  PocType,
  PriorAirCrewRatings,
  SecurityClearance
} from '@/models/Entities/Student/Constants';
import { CourseSection } from '@/models/Entities/Student/CourseSection';
import { FlightTrainingCourse } from '@/models/Entities/Student/FlightTrainingCourse';
import { FlightTrainingProgress } from '@/models/Entities/Student/FlightTrainingProgress';
import { Order } from '@/models/Entities/Student/Order';
import { deduplicate } from '@/util/array/deduplicate';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { dateIsBetween } from '@/util/date';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntity } from './BaseEntity';
import { OfficerTrainingSchoolClass } from './Course/OfficerTrainingSchoolClass';
import Dependent from './Dependent/Dependent';
import { Log } from './Log';
import { Staff } from './Personas/Staff';
import { InProcessingClass } from './Student/InProcessingClass';
import { SponsoringUnit } from './Student/SponsoringUnit';
import { FssContactInformation, StaffPointOfContactInformation } from './types';

const sectionsToCourses = (section: CourseSection) =>
  section.flightTrainingCourse;

type YesNo = 'Yes' | 'No';

/**
 * the main data model of the application, this represents are personel
 * who are known as applicants, students, and/or candidates.
 *
 * @export
 * @class BaseCandidate
 * @extends {BaseEntity}
 */
export class BaseCandidate extends BaseEntity {
  @Type(() => Application)
  applications?: Application[];

  @Type(() => Number)
  progress?: number;

  @Type(() => Number)
  applicationIds: number[] = [];

  @Type(() => Log)
  logs: Log[] = [];

  @Type(() => Number)
  logIds: number[] = [];

  //candidates point of contact
  @Type(() => Staff)
  manager: Staff = new Staff();

  @Type(() => Number)
  managerId!: number | null;

  contactInformationEmail!: string | null;

  @Type(() => Number)
  contactInformationHomephone!: number;

  @Type(() => Number)
  contactInformationWorkphone!: number | null;

  contactInformationAddress1!: string | null;

  contactInformationAddress2!: string | null;

  contactInformationCity!: string | null;

  contactInformationState!: string | null;

  contactInformationCountry!: string | null;

  contactInformationZipcode!: string | null;

  contactInformationPreferredcontactmethod!: string[] | null;

  identificationInformationFirstname!: string | null;

  identificationInformationMiddlename!: string | null;

  identificationInformationLastname!: string | null;

  identificationInformationSuffix!: string | null;

  identificationInformationSocialsecuritynumber!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  identificationInformationDateofbirth!: Date;

  @Type(() => Number)
  identificationInformationEdipi!: number | null;

  identificationInformationPlaceofbirth!: string | null;

  recruitmentStatus!: RecruitmentStatus;

  applicantType!: ApplicantTypes;

  @Type(() => Boolean)
  interestedInSpecialWarfare!: boolean | null;

  @Type(() => Boolean)
  isSingleParent!: boolean | null;

  @Type(() => Boolean)
  hasBeenConvictedOfAFelony!: boolean | null;

  @Type(() => Boolean)
  hasUsedIllegalDrugs!: boolean | null;

  @Type(() => Boolean)
  hasCovidVaccine!: boolean | null;

  @Type(() => Boolean)
  sereCompleted!: boolean | null;

  medicalClearance!: EMedicalClearance | null;

  //profile information ========================================

  @Type(() => Board)
  acceptedBoard!: Board | null;

  @Type(() => Number)
  acceptedBoardId!: number | null;

  hasDependents!: EDependentsTypes;

  @Type(() => Dependent)
  dependents: Dependent[] = [];

  @Type(() => Boolean)
  sponsorshipIssponsored!: boolean | null;

  sponsorshipSponsorunit!: ESponsorUnits | null;

  sponsorshipSponsorshipletterlocation!: string | null;

  sponsoringUnit?: SponsoringUnit;

  demographicsRace!: ERace | null;

  demographicsGender!: EGender | null;

  demographicsEthnicity!: EEthnicity | null;

  @Type(() => Boolean)
  demographicsIsdualcitizen!: boolean | null;

  demographicsCitizenship!: string | null;

  priorServiceGrade!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  priorServiceDateofrank!: Date | null;

  @Type(() => Number)
  priorServiceFlighthours!: number | null;

  priorServiceAirframe!: string | null;

  priorServiceDutytitle!: string | null;

  @Type(() => Number)
  priorServiceDutyphone!: number | null;

  priorServiceDutyphonetype!: string | null;

  priorServiceDutystation!: string | null;

  priorServiceComponent!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  priorServiceCommissionservicedate!: Date | null;

  @Type(() => Boolean)
  priorServiceIsenjjptvolunteer!: boolean | null;

  @Type(() => Boolean)
  priorServiceInactivereadyreservestatus!: boolean | null;

  /** @deprecated */
  priorServiceAirforcespecialtycode!: string | null;

  @Type(() => Boolean)
  priorServiceIsconditionalreleaseapproved!: boolean | null;

  priorServicePalace!: string | null;

  priorServiceSisterservice!: string | null;

  priorServiceArb!: EArb | null;

  academicInformationInstitution!: string | null;

  academicInformationMajor!: string | null;

  academicInformationDegree!: string | null;

  @Type(() => Boolean)
  academicInformationAviationprogramgraduate!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  academicInformationGraduationdate!: Date | null;

  @Type(() => Number)
  academicInformationGpa!: number | null;

  @Type(() => Boolean)
  academicInformationStemmajor!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  academicInformationExpectedrotcgraduation!: Date | null;

  academicInformationRotcfieldtrainingreportlocation!: string | null;

  @Type(() => Boolean)
  flyExperienceHasprivatelicense!: boolean | null;

  @Type(() => Boolean)
  flyExperienceDroppedonrequest!: boolean | null;

  flyExperienceDroppedonrequestexplanation!: string | null;

  flyExperienceCivilianpilotcertificate!: ECivilianPilotCertificate | null;

  flyExperienceCivilianflightratingcategory!: ECivilianFlightRatingCategory | null;

  flyExperienceCivilianflightratingclass!: ECivilianFlightRatingClass | null;

  flyExperienceCivilianflightcfi!: ECivilianFlightCFITypes | null;

  @Type(() => Number)
  flyExperienceCiviliantotalflighthours!: number | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flyExperienceLastdateflown!: Date | null;

  flyExperiencePilotslicenselocation!: string | null;

  flyExperienceMilitaryaeronauticalrating!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flyExperienceMilitaryaeronauticaldateawarded!: Date | null;

  @Type(() => Number)
  flyExperienceTotalmilitaryflighthours!: number | null;

  flyExperienceLastpageofpilotlogbooklocation!: string | null;

  @Type(() => Number)
  physicalAssessmentsScore!: number | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  physicalAssessmentsDate!: Date | null;

  physicalAssessmentsPhysicalfitnessdocumentlocation!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  testingInformationAfoqtdate!: Date | null;

  @Type(() => Number)
  testingInformationPilotscore!: number | null;
  @Type(() => Number)
  testingInformationCsoscore!: number | null;
  @Type(() => Number)
  testingInformationAbmscore!: number | null;
  @Type(() => Number)
  testingInformationAascore!: number | null;
  @Type(() => Number)
  testingInformationVerbalscore!: number | null;
  @Type(() => Number)
  testingInformationPcsmscore!: number | null;
  @Type(() => Number)
  testingInformationQuantitativescore!: number | null;

  documentsProfilepicturelocation!: string | null;

  documentsGovernmentphotoidlocation!: string | null;

  documentsResumelocation!: string | null;

  documentsHousingdocumentationlocation!: string | null;

  documentsEducationcertificatelocation!: string | null;

  /** @deprecate */
  militaryStatusRank!: ECurrentGrade | null;

  militaryStatusRanking!: EMilitaryRank | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  militaryStatusDateofrank!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  militaryStatusExpirationtermofservice!: Date | null;

  /** @deprecate Remove after no issues */
  militaryStatusSecurityclearance!: SecurityClearance | null;

  militaryStatusSecurityclearancev2!: SecurityClearance | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  militaryStatusSecuritycloseoutdate!: Date | null;

  militaryStatusAssignment2pas!: string | null;

  militaryStatusAssignment2!: string | null;

  militaryStatusProjectedgrade!: string | null;

  militaryStatusBadge!: BadgeType | null;

  @Type(() => Boolean)
  militaryStatusHaspriorservice!: boolean | null;

  militaryStatusBranch!: string | null;

  @Type(() => Boolean)
  militaryStatusInactivereadyreserves!: boolean | null;

  @Type(() => Boolean)
  militaryStatusCivilianpathtowings!: boolean | null;

  @Type(() => Boolean)
  militaryStatusAllinformationvalidated!: boolean | null;

  @Type(() => Boolean)
  militaryStatusStipend!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  militaryStatusEstimatedtimeofseparation!: Date | null;

  /** @deprecated */
  militaryStatusCurrentunit!: string | null;

  @Type(() => Boolean)
  militaryStatusIscurtailmentstarted!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  militaryStatusLastdayofagrorder!: Date | null;

  //student/340th information====================================

  @Type(() => FlightTrainingProgress)
  flightTrainingProgress: FlightTrainingProgress[] = [];

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flightPayCmsclosedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flightPayCmsopendate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flightPayAorecieveddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flightPayHoldeffectivedate!: Date | null;

  flightPayHoldtype!: EHoldTypes | null;

  housingInformationFinancials!: HousingFinancialTypes | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  startPayInitialstartdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  startPayInitialenddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  startPayModifiedenddate!: Date | null;

  @Type(() => Number)
  startPayMod!: number | null;

  @Type(() => Number)
  startPayTrackingnumber!: number | null;

  @Type(() => Number)
  startPayTransactionid!: number | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  startPayEmailsentdate!: Date | null;

  @Type(() => Boolean)
  housingInformationBasicallowanceforhousing!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  housingInformationWaiverlognotification!: Date | null;

  @Type(() => Boolean)
  housingInformationIsvalid!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  housingInformationTermrouted!: Date | null;

  housingInformationPropertytype!: PropertyType | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  housingInformationLeaseexpirationdate!: Date | null;

  @Type(() => Boolean)
  priorAirCrewIsprioraircrew!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv80acompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv83acompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv84afcompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv85acompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv88alcompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv90acompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv97acompleted!: boolean | null;

  @Type(() => Boolean)
  priorAirCrewSv98acompleted!: boolean | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  priorAirCrewAfoqtdate!: Date | null;

  @Type(() => Number)
  priorAirCrewPilotscore!: number | null;

  @Type(() => Number)
  priorAirCrewCsoscore!: number | null;

  @Type(() => Number)
  priorAirCrewAbmscore!: number | null;

  @Type(() => Number)
  priorAirCrewAascore!: number | null;

  @Type(() => Number)
  priorAirCrewVerbalscore!: number | null;

  @Type(() => Number)
  priorAirCrewPcsmscore!: number | null;

  @Type(() => Number)
  priorAirCrewQuantitativescore!: number | null;

  @Type(() => Number)
  priorAirCrewTotalcivilianflighthours!: number | null;

  priorAirCrewRating!: PriorAirCrewRatings | null;

  @Type(() => Number)
  priorAirCrewNavscore!: number | null;

  @Type(() => Boolean)
  priorAirCrewHasprivatelicense!: boolean | null;

  /** @deprecated */
  @Type(() => Number)
  gainsBump!: number;

  gainsGainspriority!: EGainsPriority;

  gainsGroupinfo?: GroupInfo | null;

  gainsDutystatus?: DutyStatus | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsShareddrivefolderdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsVitalscommcheckdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsMedicalclearancedate!: Date | null;

  gainsMedicalClearanceStatus!: EMedicalClearanceStatus | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsSecuritycheckdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gains1288sentdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsMilpdsgainprojectiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsMilpdspascodeconfirmationate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsGaindate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsDdaformationdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsDutytitledate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsAefbandate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsAfrsectioniddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsSurfdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsCompcatconfirmeddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsPostactiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsGainscompleteddate!: Date | null;

  gainsPoctype?: PocType | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsEdcsa!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  gainsAf1288received!: Date | null;

  gainsIncomingfsspoc!: string | null;

  gainsIncomingfssemail!: string | null;

  gainsPositionNumber!: string | null;

  @Type(() => Number)
  gainsIncomingfssphone!: number | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingInproccessingstartdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingWelcomeemailsentdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtcsupervisordate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtcapcdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtcactivationdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtccsoudate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtccbtdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOrderdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingAmrdecdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOrdersentdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOutlookdistrodate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingDtsaccountdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingFmpackagedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingLettersdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingRscdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingFundingdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingShotrecorddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingAf2583date!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingConfirmeftdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingMillconnectdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingEducationtransdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingNatobriefdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingCertifiedinprocessingdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOrdermodrouteddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingInproccesingvoucherdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingRouteorderdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingMmpadate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingMylearningtranscriptdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingCbttransdate: Date | null = null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingPoadate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingWillworkdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingMovemilitarybasedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingSglidate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingSf86date!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingJpasreviewdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingDissreviewdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingNdadate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingFingerprintsdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingUniformlettersdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingAlterationsdate!: Date | null;

  @Type(() => Boolean)
  inProcessingRentalcarassigned!: boolean;

  inProcessingRank!: string | null;

  @Type(() => Boolean)
  inProcessingPeerleader!: boolean;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOfficertrainingschoolstartdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingOfficertrainingschoolorderdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingEdipidate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingGtccemail!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  inProcessingInprocessingcompleteddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolWingscompletiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolGtcccompletiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolMissioncriticalcompletiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolPromotecompletiondate!: Date | null;

  officerTrainingSchoolStaffa!: string | null;

  officerTrainingSchoolStaffb!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolClassrosteremaileddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolTdydates!: Date | null; //is this supposed to be an array???

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolTdyprepdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolDegreeauditdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolConfirmo1rankdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolUpdatedutyinfodate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolDeletedoedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolDeleteetsdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolDeleteetodate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolAwardsdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolRscuptdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolAofromafpcdate!: Date | null;

  /**
   *jpasDate is no longer being used, but might make a return in the future
   * @deprecated
   */
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolJpasdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolDissdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolGtcmccompletiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolSf600rosterdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolSchedulepostotscallcompletiondate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolCompcatdate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolPostcompletedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolAf475completedate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  officerTrainingSchoolOathofofficedate!: Date | null;

  @Type(() => Order)
  orders: Order[] = [];

  @Type(() => Number)
  orderIds: number[] = [];

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingLeavereconrequested!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingLeavebalance!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingLeaveenddate!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingAf988partone!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingFinalmodapproved!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingSurveysent!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingSurveyreceived!: Date | null;

  outProcessingExitreason!: ExitReason | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingFinal!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingPackagesent!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingPackagereceived!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingEdcsa1288!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingSent1288!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingReceived1288!: Date | null;

  //Reserve Service Commitment
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingRsc!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingAssignmentorder!: Date | null;

  //Government Travel Card
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingGtcc!: Date | null;

  //Defense Counterintelligence and Security Agency
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingDiss!: Date | null;

  //Defense Travel System
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingDetachdtsaccount!: Date | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingDecorationdate!: Date | null;

  //Assignment Action Number
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingAan!: Date | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingMilpdsaction1288!: Date | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingArcnetrelease!: Date | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingRemovefromdistro!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingArchivefolder!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  outProcessingDd214submitteddate!: Date | null;

  gainingUnitName!: string | null;

  gainingUnitCurrentpascode!: string | null;

  gainingUnitTransofpascode!: string | null;

  gainingUnitGainingpascode!: string | null;

  gainingUnitDirectedunitpascode!: string | null;

  gainingUnitIdentifier!: string | null;

  gainingUnitBase!: string | null;

  gainingUnitCommander!: string | null;

  @Type(() => Number)
  gainingUnitCommanderphone!: number | null;

  gainingUnitForcesupportsquadron!: string | null;

  gainingUnitForcesupportsquadronemail!: string | null;

  @Type(() => Number)
  gainingUnitForcesupportsquadronphone!: number | null;

  gainingUnitPointofcontact!: string | null;

  @Type(() => Number)
  gainingUnitPointofcontactphonenumber!: number | null;

  gainingUnitAircraft!: string | null;

  @Type(() => OfficerTrainingSchoolClass)
  officerTrainingSchoolClass!: OfficerTrainingSchoolClass | null;

  @Type(() => InProcessingClass)
  inProcessingClass!: InProcessingClass | null;

  @Type(() => CourseSection)
  sections: CourseSection[] = [];

  @Type(() => Number)
  sectionIds: number[] = [];

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  availableTrainingStartDate!: Date | null;

  trainingFirstBasePreference!: EPreferredUptBaseTypes | null;

  /**
   * sets the applicant's prior service indicator under military status
   * Checks if any of the varying prior services was selected
   *
   * @memberof BaseCandidate
   */
  set applicantTypeAndPriorService(applicantType: ApplicantTypes) {
    const isNotPriorService = [
      ApplicantTypes.NO_PRIOR_SERVICE,
      ApplicantTypes.ROTC
    ];
    this.applicantType = applicantType;
    this.militaryStatusHaspriorservice =
      applicantType && !isNotPriorService.includes(applicantType)
        ? true
        : false;
  }

  get applicantTypeAndPriorService(): ApplicantTypes {
    return this.applicantType;
  }

  /**
   * returns a yes or no string if the student has prior service or not
   *
   * @readonly
   * @type {YesNo}
   * @memberof BaseCandidate
   */
  get hasPriorService(): YesNo {
    return this.militaryStatusHaspriorservice ? 'Yes' : 'No';
  }

  /**
   * returns a yes or no string depending on if the student has a private pilots license
   *
   * @readonly
   * @type {YesNo}
   * @memberof BaseCandidate
   */
  get hasPrivatePilotsLicense(): YesNo {
    return this.priorAirCrewHasprivatelicense ? 'Yes' : 'No';
  }

  /**
   * returns a yes or no string depending on if the student was prior aircrew
   *
   * @readonly
   * @type {YesNo}
   * @memberof BaseCandidate
   */
  get wasPriorAirCrew(): YesNo {
    return this.priorAirCrewIsprioraircrew ? 'Yes' : 'No';
  }

  /**
   * gets the sections which the student is currently participating in
   *
   * @readonly
   * @type {(CourseSection | null)}
   * @memberof Student
   */
  get activeSections(): CourseSection[] {
    return this.sections.filter((section) => {
      return dateIsBetween(
        section.classStartDate,
        section.classGraduationDate,
        new Date()
      );
    });
  }

  /**
   * returns the
   *
   * @readonly
   * @type {(CourseSection | null)}
   * @memberof BaseCandidate
   */
  get upcomingSection(): CourseSection | null {
    return (
      this.futureSections
        .sort(CourseSection.sortAscendingByStartDate)
        .filter(
          (section) =>
            !dateIsBetween(
              section.classStartDate,
              section.classGraduationDate,
              new Date()
            )
        )[0] ?? null
    );
  }

  /**
   * the candidates full combined name
   *
   * format: {first name} {middle initial} {last name}
   *
   * note: this automatically omits any parts that might be missing
   *
   * @readonly
   * @type {string}
   * @memberof BaseCandidate
   */
  get fullName(): string {
    const middleInitial = this.identificationInformationMiddlename
      ? ' ' + this.identificationInformationMiddlename?.charAt(0)
      : '';
    if (this.identificationInformationFirstname) {
      return `${this.identificationInformationFirstname}${middleInitial} ${
        this.identificationInformationLastname || ''
      }`.trim();
    }
    return '';
  }

  /**
   * the longer combined name for table views
   *
   * format: {last name}, {first name} {middle initial}.
   *
   * @readonly
   * @type {string}
   * @memberof BaseCandidate
   */
  get combinedName(): string {
    const shortName =
      this.identificationInformationLastname +
      ', ' +
      this.identificationInformationFirstname;

    if (this.identificationInformationMiddlename) {
      return (
        shortName +
        ' ' +
        this.identificationInformationMiddlename.charAt(0).toUpperCase() +
        '.'
      );
    }
    return shortName;
  }

  /**
   * returns a string with all their name sections for searching
   *
   * @readonly
   * @type {string}
   * @memberof BaseCandidate
   */
  get searchName(): string {
    return `${this.identificationInformationFirstname}${this.identificationInformationMiddlename}${this.identificationInformationLastname}`;
  }

  /**
   * the candidates shortened name for table views
   *
   * format: {first name}, {last initial}
   *
   * @readonly
   * @type {string}
   * @memberof BaseCandidate
   */
  get shortName(): string {
    return (
      this.identificationInformationLastname +
      ', ' +
      this.identificationInformationFirstname?.charAt(0)
    ).toUpperCase();
  }

  /**
   * indicates if the candidate has been "soft deleted" and should not
   * show up in some views
   *
   * @readonly
   * @type {boolean}
   * @memberof BaseCandidate
   */
  get isArchived(): boolean {
    return !!this.deletedAt;
  }

  /**
   * returns the age by calculating the difference in days
   * from their date of birth to today
   *
   * age is in years
   *
   * @readonly
   * @type {(number | null)}
   * @memberof BaseCandidate
   */
  get age(): number {
    const delta = +(
      new Date().valueOf() - this.identificationInformationDateofbirth.valueOf()
    );
    return Math.floor(delta / (1000 * 3600 * 24 * 365.25));
  }

  /**
   * returns a list of all courses that a student is currently occurring
   * i.e where todays date is between the sections start and end dates
   *
   * @readonly
   * @type {FlightTrainingCourse[]}
   * @memberof Student
   */
  get currentCourses(): FlightTrainingCourse[] {
    return deduplicate(
      this.currentSections.map(sectionsToCourses),
      'courseNumber'
    );
  }

  /**
   * get all courses where the student has a TLN associated
   * with it
   *
   * @readonly
   * @type {FlightTrainingCourse[]}
   * @memberof BaseCandidate
   */
  get enrolledCourses(): FlightTrainingCourse[] {
    return deduplicate(
      this.enrolledSections.map(sectionsToCourses),
      'courseNumber'
    );
  }

  /**
   * returns all current and future sections where the student
   * has a TLN for the given section
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get enrolledSections(): CourseSection[] {
    return this.futureSections.filter((section) =>
      this.getTrainingLineNumberForCourse(section.course)
    );
  }

  /**
   * all courses that have not happened yet that the student
   * has been signed up for
   *
   * @readonly
   * @type {FlightTrainingCourse[]}
   * @memberof BaseCandidate
   */
  get futureCourses(): FlightTrainingCourse[] {
    return deduplicate(
      this.futureSections.map(sectionsToCourses),
      'courseNumber'
    );
  }

  /**
   * all sections for a course that will happen in the future
   * for this student
   *
   * NOTE: includes the current section as well
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get futureSections(): CourseSection[] {
    return [...this.sections].filter(
      (section: CourseSection) =>
        section.classGraduationDate.getTime() > new Date().getTime()
    );
  }

  /**
   * all sections where the graduation date is less than
   * todays date
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get pastSections(): CourseSection[] {
    return [...this.sections].filter(
      (section: CourseSection) =>
        section.classGraduationDate.getTime() < new Date().getTime()
    );
  }

  /**
   * sections where todays date is between their
   * start and graduation dates
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get currentSections(): CourseSection[] {
    return this.sections.filter((section) =>
      dateIsBetween(
        section.classStartDate,
        section.classGraduationDate,
        new Date()
      )
    );
  }

  /**
   * courses where the graduation date for the
   * students assigned section has already happened
   *
   * @readonly
   * @type {FlightTrainingCourse[]}
   * @memberof BaseCandidate
   */
  get completedCourses(): FlightTrainingCourse[] {
    return deduplicate(
      this.completedSections.map(sectionsToCourses),
      'courseNumber'
    );
  }

  /**
   * the sections a student has been signed up for where
   * the graduation date is older than today
   *
   * TODO: might need to re-evaluate what is mean for a section or course to be "complete"
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get completedSections(): CourseSection[] {
    return this.sections.filter(
      (section) => section.classGraduationDate < new Date()
    );
  }

  /**
   * sections sorted in ascending order by start date
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get sortedAscendingSectionsByStartDate(): CourseSection[] {
    return [...this.sections].sort(CourseSection.sortAscendingByStartDate);
  }

  /**
   * sections sorted in descending order by start date
   *
   * @readonly
   * @type {CourseSection[]}
   * @memberof BaseCandidate
   */
  get sortedDescendingSectionsByStartDate(): CourseSection[] {
    return this.sections.sort(CourseSection.sortDescendingByStartDate);
  }

  get trainingDurationSectionGroups(): CourseSection[][] {
    //sort in ascending order based on start date
    const sectionGroups: CourseSection[][] = [];
    let currentSectionGroup: CourseSection[] = [];

    const stuff = this.sortedAscendingSectionsByStartDate.filter(
      (section) => section.classGraduationDate.getTime() > new Date().getTime()
    );
    stuff.forEach((current, index, allSections) => {
      if (index == allSections.length - 1) {
        currentSectionGroup.push(current);
        return;
      }

      if (currentSectionGroup.length === 0) {
        currentSectionGroup.push(current);
      }

      const next = allSections[index + 1];
      if (
        current.classGraduationDate.getTime() <=
          next.classGraduationDate.getTime() &&
        next.classStartDate.getTime() < current.classGraduationDate.getTime()
      ) {
        currentSectionGroup.push(next);
      } else {
        sectionGroups.push(currentSectionGroup);
        currentSectionGroup = [];
      }
    });

    sectionGroups.push(currentSectionGroup);

    return sectionGroups.filter((sectionGroup) => sectionGroup.length > 0);
  }

  get breaksInTraining(): BreakInTraining[] {
    return this.trainingDurationSectionGroups
      .map((trainingGlob, index, arr) => {
        if (index === arr.length - 1) {
          return null;
        }
        const next = arr[index + 1];
        return new BreakInTraining(
          trainingGlob[trainingGlob.length - 1]!.classGraduationDate,
          next[next.length - 1]!.classStartDate
        );
      })
      .filter((bit) => !!bit) as BreakInTraining[];
  }

  /**
   * gets the training line number for this student
   * for a given course if it exists
   *
   * @param {FlightTrainingCourse} course
   * @returns  {(string | null)}
   * @memberof BaseCandidate
   */
  public getTrainingLineNumberForCourse(
    course: FlightTrainingCourse
  ): string | null {
    const progress = this.flightTrainingProgress.find(
      (flightTrainingProgress) => flightTrainingProgress.courseId === course.id
    );
    return progress ? progress.trainingLineNumber : null;
  }

  /**
   * returns a boolean determining if a given course
   * has been completed
   *
   * @param {FlightTrainingCourse} course
   * @returns  {boolean}
   * @memberof BaseCandidate
   */
  public hasCompletedCourse(course: FlightTrainingCourse): boolean {
    return this.completedCourses.includes(course);
  }

  /**
   * returns whether or not the canidate is medically cleared
   *
   * @returns  {YesNo}
   * @memberof BaseCandidate
   */
  get isMedicallyCleared(): boolean {
    return (
      this.medicalClearance == EMedicalClearance.COMPLETE ||
      this.medicalClearance == EMedicalClearance.CERTIFIED
    );
  }

  /**
   * Returns required recruiter information for candidate
   *
   * @returns  {StaffPointOfContactInformation}
   * @memberof BaseCandidate
   */
  get recruiterPocInformation(): StaffPointOfContactInformation {
    const email = this.manager.contactInformationEmail;
    const phone = this.manager.contactInformationWorkphone;
    const name = this.manager.fullName;
    return { email, phone, name };
  }

  /**
   * Returns required gaining unit FSS information for candidate
   *
   * @returns  {FssContactInformation}
   * @memberof BaseCandidate
   */
  get gainingUnitFssInformation(): FssContactInformation {
    const email = this.gainingUnitForcesupportsquadronemail;
    const name = this.gainingUnitForcesupportsquadron;
    const phone = this.gainingUnitForcesupportsquadronphone;
    return { email, name, phone };
  }

  constructor(partial?: Partial<BaseCandidate>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(BaseCandidate, partial));
    }
  }

  /**
   * returns a list of the completed fields
   *
   * previous; step
   *
   * @readonly
   * @type {string[]}
   * @memberof Gains
   */
  get completedRequiredGainsFields(): any[] {
    const requiredGainsFields = [
      this.gainsGainspriority,

      this.gainsGroupinfo,

      this.gainsDutystatus,
      this.gainsShareddrivefolderdate,
      this.gainsVitalscommcheckdate,
      this.gainsMedicalclearancedate,
      this.gainsSecuritycheckdate,
      this.gains1288sentdate,
      this.gainsMilpdsgainprojectiondate,
      this.gainsMilpdspascodeconfirmationate,

      this.gainsGaindate,

      this.gainsDdaformationdate,

      this.gainsDutytitledate,
      this.gainsAefbandate,
      this.gainsAfrsectioniddate,
      this.gainsSurfdate,
      this.gainsCompcatconfirmeddate,
      this.gainsPostactiondate,
      this.gainsGainscompleteddate,

      this.gainsPoctype,
      this.gainsEdcsa,

      this.gainsAf1288received,

      this.gainsIncomingfsspoc,

      this.gainsIncomingfssemail,

      this.gainsIncomingfssphone
    ];
    return requiredGainsFields.filter((item) => item);
  }

  private formatPhone(num: number | null): string {
    if (!num) {
      return '';
    }
    const numString = num.toString();
    const beginning = numString.slice(0, 3);
    const middle = numString.slice(3, 6);
    const end = numString.slice(6);
    return `(${beginning})-${middle}-${end}`;
  }

  get formattedHomePhone(): string {
    return this.formatPhone(this.contactInformationHomephone);
  }
  get formattedWorkPhone(): string {
    return this.contactInformationWorkphone
      ? this.formatPhone(this.contactInformationWorkphone)
      : '';
  }

  get abbreviationMap() {
    return new Map([
      [GroupInfo.ACTIVEDUTY, 'AA'],
      [GroupInfo.ART, 'AR'],
      [GroupInfo.AGR, 'G'],
      [GroupInfo.INTERSERVICE, 'IS'],
      [GroupInfo.NONPRIOR, 'NP'],
      [GroupInfo.ROTC, 'R'],
      [GroupInfo.TRADITIONALRES, 'TR'],
      [GroupInfo.WAIVER, 'W']
    ]);
  }
}
