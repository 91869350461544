import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { Application } from './Application/Application';
import { BaseEntity } from './BaseEntity';

class Board extends BaseEntity {
  public title!: string;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  meetingDate!: Date;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  public startDate!: Date;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  public endDate!: Date;
  @Type(() => Boolean)
  public isActive!: boolean;
  @Type(() => Application)
  public applications: Application[] = [];
  public applicationIds: number[] = [];

  constructor(partial?: Partial<Board>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Board, partial));
    }
  }
}

export { Board, Board as IBoard };
