var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleOutsideClick,
          expression: "handleOutsideClick",
        },
      ],
      staticClass:
        "z-30 text-white transition-all duration-300 ease-in-out transform border-t border-gray-700 print:hidden bg-primary-dark lg:left-0 lg:bottom-0 lg:w-64 lg:absolute lg:h-full",
      class: _vm.isOpen
        ? "block lg:translate-x-0"
        : "hidden lg:block lg:-translate-x-full",
    },
    [
      _c(
        "div",
        { staticClass: "menu-items" },
        _vm._l(_vm.sections, function (section, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "strong",
                {
                  staticClass:
                    "block p-2 pl-4 capitalize border border-l-0 border-r-0 border-transparent whitespace-nowrap",
                },
                [_vm._v(_vm._s(section))]
              ),
              _vm._l(_vm.menuItemsBySection(section), function (item, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass:
                      "block py-2 pl-8 text-sm capitalize border border-l-0 border-r-0 border-transparent cursor-pointer whitespace-nowrap hover:underline",
                    attrs: { "active-class": "border-blue-500", to: item.to },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }