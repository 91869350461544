var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { title: "I can't sign in" } },
        [
          _c("div", { staticClass: "mb-8 text-gray-500" }, [
            _c("p", [_vm._v("Please enter the email for your account")]),
            _c("p", [_vm._v("and we will send you a reset link")]),
          ]),
          _c(
            "label",
            {
              staticClass: "block mb-1 text-xs text-gray-600",
              attrs: { for: "email" },
            },
            [_vm._v(" Enter your email address ")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass:
              "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
            attrs: {
              id: "email",
              type: "text",
              placeholder: "Enter your email",
            },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _vm.customErrorMessage != ""
            ? _c("login-form-error-message-component", {
                attrs: { message: _vm.customErrorMessage },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex items-center justify-end mt-6" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: _vm.routes.AUTH_LOGIN } } },
                [
                  _c("a", { staticClass: "ml-4 mr-6 underline" }, [
                    _vm._v(" Cancel reset "),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-6 py-2 font-bold text-white bg-black rounded disabled:opacity-50",
                  attrs: {
                    type: "button",
                    disabled: !_vm.validForm || _vm.isProcessing,
                  },
                  on: { click: _vm.handlePasswordReset },
                },
                [_vm._v(" Send password reset ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }