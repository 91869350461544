import config from '@/config';
import Metrics from '@/pages/QA/Metrics.vue';
import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';

export const generateRoutes: (
  store: Store<RootState>
) => RouteConfig[] = () => [
  {
    path: '/qa',
    name: Routes.QA,
    component: Metrics,
    beforeEnter: (to, from, next) => {
      if (config.buildStage === 'production') {
        next('/home');
      }
      if (config.environment === 'firebase') {
        next();
      }
    }
  }
];
