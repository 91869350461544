import Bff from '@/api/bff';
import firebase from 'firebase/app';
import { ActionTree } from 'vuex';
import { AuthClient } from '../../services/auth';
import { RootState } from '../types';
import { UserState } from './types';
import { Invitation } from '@/models/Invitation/invitation';
import { IAuthUser, User } from '@/models/Entities/User';
import { SearchResponseDto } from '../../models/Dtos/common/searchResultDto';
import { SearchDto } from '../../models/Dtos/common/searchDto';

export function createActions(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bff: Bff
): ActionTree<UserState, RootState> {
  return {
    logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_IS_LOGGED_IN', false);
      commit('SET_IS_EMAIL_VERIFIED', false);
      commit('SET_FIREBASE_USER', null);
    },

    async login({ commit }, auth: IAuthUser) {
      try {
        const authUser = User.fromAuthClient(auth);

        const verified = await AuthClient.getInstance().isEmailVerified();

        commit('SET_FIREBASE_USER', auth);
        commit('SET_IS_LOGGED_IN', true);
        commit('SET_IS_EMAIL_VERIFIED', verified);

        let user = null;
        try {
          user = await bff.fetchUser(auth.uid);
        } catch (err) {
          console.log(err);
          const userDto = new User(authUser);
          await bff.createUser(userDto);
          user = authUser;
        }
        commit('SET_USER', user);
      } catch (err) {
        console.log(err);
      }
    },
    fetchInvitation(_, payload: Invitation): Promise<string> {
      return bff.fetchInvitation(payload);
    },
    processInvitation(_, hash: string): Promise<User> {
      return bff.processInvitation(hash);
    },
    async setUser({ commit }, user: User) {
      await bff.patchUser(user);
      commit('SET_USER', user);
    },
    async setUserInitialRole({ commit }, user: User) {
      await bff.setUserInitialRole(user);
      commit('SET_USER', user);
    },
    setFirebaseUser({ commit }, user: firebase.User) {
      commit('SET_FIREBASE_USER', user);
    },
    async patchUser({ commit }, user: User) {
      const patchedUser = await bff.patchUser(user);
      commit('UPDATE_USER', patchedUser);
    },
    async deleteUser(_, uid: string) {
      await bff.deleteUser(uid);
    },
    async restoreUser(_, uid: string) {
      await bff.restoreUser(uid);
    },
    async fetchAll({ commit }, query = {}) {
      const users = await bff.fetchUsers(query);
      commit('SET_USERS', users);
    },
    fetchUser(_, uid: string) {
      return bff.fetchUser(uid);
    },
    fetchWithSelectParameters: async (
      _,
      query: SearchDto<User, never>
    ): Promise<SearchResponseDto<User>> => {
      const result: SearchResponseDto<User> =
        await bff.fetchWithSelectParameters(`user/user/find`, query);
      result.data = result.data.map((json) => {
        return new User(json);
      });
      return result;
    }
  };
}
