
import { IconDatum, IconTable } from '@/assets/icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<IconComponent>({})
export default class IconComponent extends Vue {
  @Prop({
    validator: (value) => {
      if (typeof value !== 'string') {
        return false;
      }
      return Object.keys(IconTable).indexOf(value) !== -1;
    }
  })
  readonly name!: string;

  @Prop()
  readonly icon!: IconDatum;

  @Prop({ default: 12 })
  readonly width!: number;

  @Prop({ default: 12 })
  readonly height!: number;

  @Prop()
  readonly fill?: string;

  @Prop()
  readonly stroke?: string;

  get IconMetaData(): IconDatum {
    if (this.icon) {
      return this.icon;
    }
    const icon = (IconTable as Record<string, IconDatum>)[this.name];

    if (!icon) {
      throw new ReferenceError(
        `Icon '${this.name}' not found in IconHashTable`
      );
    }
    if (!icon.body) {
      throw new TypeError(
        `Icon prop 'body' not found in IconHashTable for '${this.name}' `
      );
    }
    return icon;
  }
}
