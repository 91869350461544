import { NestedDropDown } from '@/models/Forms/FormOption';

/**
 * status of where the candidate is in the pipeline
 *
 * applicant --> A3
 *
 * student --> 340th
 *
 * NOTE: mainly used for limiting get calls from the api
 *
 * @export
 * @enum {string}
 */
export enum RecruitmentStatus {
  APPLICANT = 'Applicant',
  STUDENT = 'Student'
}

/**
 * the type of applicant this is, this is meant to help with how they apply to the application.
 *
 * @export
 * @enum {string}
 */
export enum ApplicantTypes {
  NO_PRIOR_SERVICE = 'NO Prior Service',
  ROTC = 'ROTC',
  TRADITIONAL_RESERVIST = 'Traditional Reservist (TR-AF)',
  ACTIVE_GUARD_RESERVE = 'Active Guard Reserve (AGR-AF)',
  ACTIVE_DUTY = 'Active Duty (AD-AF)',
  AIR_NATIONAL_GUARD = 'Air National Guard (ANG)',
  SISTER_SERVICE = 'Sister Service',
  INDIVIDUAL_MOBILIZATION_AUGMENTEE = 'Individual Mobilization Augmentee (IMA)'
}

/**
 * type of applicants formatted for the nested drop-down options
 */
export const ApplicantTypeNestedDropDownOption: NestedDropDown[] = [
  { label: 'NO_PRIOR_SERVICE', value: ApplicantTypes.NO_PRIOR_SERVICE },
  { label: 'ROTC', value: ApplicantTypes.ROTC },
  {
    label: 'Select Prior Service',
    value: [
      {
        label: 'Traditional_Reservist',
        value: ApplicantTypes.TRADITIONAL_RESERVIST
      },
      {
        label: 'Active_Guard_Reserve',
        value: ApplicantTypes.ACTIVE_GUARD_RESERVE
      },
      { label: 'Active_Duty', value: ApplicantTypes.ACTIVE_DUTY },
      { label: 'Air_National_Guard', value: ApplicantTypes.AIR_NATIONAL_GUARD },
      { label: 'Sister_Service', value: ApplicantTypes.SISTER_SERVICE },
      {
        label: 'Individual_Mobilization_Augmentee',
        value: ApplicantTypes.INDIVIDUAL_MOBILIZATION_AUGMENTEE
      }
    ]
  }
];

/**
 * type of housing financings they might have, this is only relevent to their applicant profile
 *
 * @export
 * @enum {number}
 */
export enum HousingFinancialTypes {
  LEASE = 'lease',
  MORTAGE = 'mortgage',
  NONE = 'none'
}

/**
 * property type, used on the profile page
 *
 * @export
 * @enum {number}
 */
export enum PropertyType {
  LEASE = 'lease',
  Mortgage = 'mortgage',
  NONE = 'none'
}
