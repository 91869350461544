export type ExitReason =
  | 'academics'
  | 'airSickness'
  | 'airWorthiness'
  | 'disciplinary'
  | 'humanitarian'
  | 'medical'
  | 'otsPtFailure'
  | 'sie'
  | 'washout'
  | 'graduated';

/**
 * @deprecated
 */
export enum DutyStatus {
  NONPRIOR = 'Non-Prior',
  PRIOR = 'Prior',
  ACTIVEDUTY = 'Active Duty',
  TR = 'TR',
  ROTC = 'ROTC'
}

export enum GroupInfo {
  ACTIVEDUTY = 'Active Duty (AF)',
  ART = 'ART',
  AGR = 'AGR',
  INTERSERVICE = 'Inter-Service',
  NONPRIOR = 'Non-Prior',
  ROTC = 'ROTC',
  TRADITIONALRES = 'Traditional Res',
  WAIVER = 'Waiver'
}

export enum OrderType {
  SCH = 'SCH',
  ADOS = 'ADOS',
  TDY = 'TDY'
}

export enum OrderStatus {
  MOD = 'MOD',
  INITIAL = 'INITIAL'
}

export enum TrainingRequestorQuota {
  RR10 = 'RR10',
  A0M0 = 'A0M0',
  CNN0 = 'CNN0',
  AJLA = 'AJLA'
}

export enum ClassStatus {
  A = 'A',
  J = 'J',
  X = 'X',
  Z = 'Z'
}

export enum CourseCategory {
  FLY = 'FLY',
  OTH = 'OTH',
  PME1 = 'PME1',
  PME3 = 'PME3',
  PME4 = 'PME4'
}

export enum QuotaType {
  AN = 'AN',
  AP = 'AP',
  AT = 'AT',
  ON = 'ON',
  OP = 'OP',
  OT = 'OT'
}

export enum QuotaStatus {
  A = 'A',
  B = 'B',
  D = 'D',
  E = 'E'
}
export enum QuotaAction {
  A = 'A',
  B = 'B',
  F = 'F',
  G = 'G',
  N = 'N'
}

export enum StudentStatus {
  AE = 'AE',
  ET = 'ET',
  EW = 'EW',
  GE = 'GE',
  NS = 'NS'
}

export enum Soc {
  A = 'A',
  F = 'F',
  G = 'G',
  J = 'J',
  L = 'L',
  S = 'S'
}

export enum SecurityClearance {
  INTERIM_SECRET = 'Interim SECRET',
  INTERIM_TOP_SECRET = 'Interim TOP SECRET',
  NO_OPEN_INVESTIGATION = 'No Open Investigation',
  OPEN_INVESTIGATION = 'Open Investigation',
  SECRET = 'SECRET',
  TOP_SECRET = 'TOP SECRET'
}

export enum Bases {
  COLUMBUS = 'Columbus',
  LAUGHLIN = 'Laughlin',
  VANCE = 'Vance',
  SHEPPARD = 'Sheppard',
  FORT_RUCKER = 'Ft Rucker',
  RANDOLPH = 'Randolph 2.5'
}

export enum CourseNames {
  OTS = 'OTS',
  SERE = 'SERE',
  IFT = 'IFT',
  T_6 = 'T-6',
  T_6_ALT = 'T-6 2.5',
  T_38 = 'T-38',
  T_38_ALT = 'T-38 2.5',
  T_1 = 'T-1',
  T_1_ALT = 'T-1 2.5',
  T_1A_XPW = 'T-1A XPW',
  AIR_FORCE_FUNDAMENTALS = 'Air Force Fundamentals',
  T_1A_RPA_REFRESHER = 'T-1A RPA Refresher',
  ADVANCED_UNGRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING_ELECTRONIC_WARFARE = 'Advanced Undergraduate Combat Systems Officer Training -Electronic Warfare',
  UNDERGRADUATE_CSO_TRAINING = 'Undergraduate CSO Training',
  INTERMEDIATE_UNDERGRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING = 'Intermediate Undergraduate Combat Systems Officer Training',
  ADVANCED_UNDERGRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING_NAVIGATION = 'Advanced Undergraduate Combat Systems Officer Training -Navigation',
  ADVANCED_UNDERGRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING_SPECIAL_OPERATIONS = 'Advanced Undergraduate Combat Systems Officer Training -Special Operations',
  ADVANCED_UNDERGRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING_WEAPONS_SYSTEMS_OFFCIER = 'Advanced Undergraduate Combat Systems Officer Training -Weapon Systems Officer',
  GRADUATE_COMBAT_SYSTEMS_OFFICER_TRAINING_ELECTRONIC_COMBAT = 'Graduate Combat Systems Officer Training -Electronic Combat',
  UNDERGRADUATE_COMBAT_SYSTEMS_OFFICER_AEROSPACE_PHYSIOLOGY = 'Undergraduate Combat Systems Officer Aerospace Physiology',
  INITIAL_FLIGHT_SCREENING_PILOT = 'Initial Flight Screening (Pilot)',
  INTERNATIONAL_INITIAL_FLIGHT_TRAINING_INITIAL_IFT = "International Initial Flight Training (Int'l IFT)",
  RPA_FLIGHT_TRAINING_RFT = 'RPA Flight Training (RFT)',
  HELICOPTER_INITIAL_FLIGHT_TRAINING = 'Helicopter Initial Flight Training'
}

export enum MwsType {
  A10 = 'A-10',
  B1 = 'B-1',
  B52 = 'B-52',
  C5 = 'C-5',
  C17 = 'C-17',
  C40 = 'C-40',
  C130 = 'C-130',
  C130J = 'C-130J',
  C130H = 'C-130H',
  C145 = 'C-145',
  C146 = 'C-146',
  E3 = 'E-3',
  F16 = 'F-16',
  F22 = 'F-22',
  F35 = 'F-35',
  HH60 = 'HH-60',
  HC130 = 'HC-130',
  KC10 = 'KC-10',
  KC46 = 'KC-46',
  KC135 = 'KC-135',
  MQ9 = 'MQ-9',
  WC130J = 'WC-130J'
}

export enum PocType {
  FSS = 'FSS',
  RECRUITER = 'Recruiter'
}

export enum PhaseType {
  UFT = 'UFT',
  OTS = 'OTS',
  IFT = 'IFT',
  FTU = 'FTU',
  SURVIVAL = 'Survival'
}

export enum PriorAirCrewRatings {
  AMEL = 'AMEL',
  ASET = 'ASEL',
  ASES = 'ASES',
  ATP = 'ATP',
  CFI = 'CFI',
  CFII = 'CFII',
  CMEI = 'CMEI',
  COMM = 'COMM',
  HELO = 'HELO',
  INST = 'INST',
  MEI = 'MEI'
}

export enum AirForcePrograms {
  PALACE_CHASE = 'Palace Chase',
  PALACE_FRONT = 'Palace Front'
}

export enum SisterServices {
  ARMY = 'Army',
  USMC = 'USMC',
  NAVY = 'Navy',
  COAST_GUARD = 'Coast Guard',
  SPACE_FORCE = 'Space Force'
}
