
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import SocialSecurity from '@/components/Forms/Elements/SocialSecurityComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Regex from '@/models/Forms/Regex';
import { DropdownOptions } from '@/models/Forms/FormOption';
import Routes from '@/router/Routes';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Staff } from '@/models/Entities/Personas/Staff';

@Component<CreateApplicantModalComponent>({
  components: {
    DateSelector,
    Modal,
    PhoneInput,
    SocialSecurity,
    TextInput,
    DropdownComponent
  }
})
export default class CreateApplicantModalComponent extends Vue {
  @Prop({ default: '' })
  size!: string;

  @Prop()
  manager!: Staff | Staff[];

  applicant: Applicant = new Applicant();
  formState: IFormState = {};
  Regex = Regex;
  show = false;
  //TODO: fix any typing
  resolve: (value: Applicant | PromiseLike<Applicant>) => void = (): void =>
    void 0;
  reject: () => void = (): void => void 0;
  isLoading = false;
  initialDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

  close(): void {
    this.show = false;
    this.applicant = new Applicant();
    this.resolve(this.applicant);
  }

  public open(): Promise<Applicant> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  async createApplicant(goToProfilePage: boolean): Promise<void> {
    this.isLoading = true;
    try {
      if (this.manager && !Array.isArray(this.manager)) {
        //set the manager id
        this.applicant.manager.id = this.manager.id;
      }
      const applicant = await this.$store.dispatch(
        'candidateModule/create',
        this.applicant
      );
      this.applicant = applicant;
      if (goToProfilePage) {
        this.gotoProfilePage(applicant.id);
        this.close();
      } else {
        this.close();
      }
    } catch (err: unknown) {
      this.reject();
    }
    this.isLoading = false;
  }

  gotoProfilePage(id: string): void {
    this.$router.push({
      name: Routes.APPLICANT_DETAILS,
      params: {
        id
      }
    });
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1 &&
      !this.isLoading
    );
  }

  get recuiterOptions(): DropdownOptions[] | null {
    if (!this.manager || !Array.isArray(this.manager)) {
      return null;
    }
    return this.manager?.map((recruiter) => {
      return {
        label: recruiter.fullName,
        value: recruiter.id || 0
      };
    });
  }
}
