/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ModalMixin extends Vue {
  @Prop({ default: '' })
  title!: string;

  resolve: any = () => void 0;
  reject: any = () => void 0;

  show = false;
  message = 'Are you sure?';

  confirm(resolvedObject: unknown = undefined): void {
    this.show = false;
    this.resolve(resolvedObject);
  }

  cancel(): void {
    this.show = false;
    this.reject();
  }
}
