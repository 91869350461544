import { EFamilyRelations } from '@/enums/EFamilyRelations';
import { plainToClass, Type } from 'class-transformer';

export default class Dependent {
  @Type(() => String)
  name!: string | null;
  @Type(() => String)
  relationship!: EFamilyRelations | null;
  @Type(() => Date)
  dateOfBirth!: Date | null;
  @Type(() => Number)
  phoneNumber!: number | null;

  constructor(partial?: Partial<Dependent>) {
    if (partial) {
      Object.assign(this, plainToClass(Dependent, partial));
    }
  }
}
