
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { OpenProps } from '@/components/types';
import { IconKeys, IconProps } from '@/assets/icons';

const DEFAULT_ICON: IconProps = {
  name: 'checkGreen',
  height: 14,
  width: 14
};

@Component<SnackbarComponent>({ components: { IconComponent } })
export default class SnackbarComponent extends Vue {
  @Prop({ default: 5000 })
  timeoutDurationMs!: number;

  show = false;

  icon: IconProps = DEFAULT_ICON;
  message = 'test';

  timeout: any = 0;

  get messageQueue(): OpenProps[] {
    return this.$store.getters['snackBarModule/queue'];
  }

  private reset() {
    this.show = false;
    this.message = '';
    this.icon = DEFAULT_ICON;
    clearTimeout(this.timeout);
  }

  @Watch('messageQueue')
  private showMessageFromQueue(): any {
    const message = this.messageQueue[0];
    if (message && !this.show) {
      this.open(message);
    }
  }

  public close(): void {
    this.show = false;
    this.$store.dispatch('snackBarModule/dequeue');
  }

  public open(
    { message, icon: icon, timeout }: OpenProps = { message: '' }
  ): void {
    this.show = true;
    this.message = message;
    if (icon) {
      if (typeof icon === 'string') {
        this.icon = { name: icon as IconKeys };
      } else {
        this.icon = { ...this.icon, ...icon };
      }
    }
    this.timeout = setTimeout(
      this.close,
      timeout ? +timeout : +this.timeoutDurationMs
    );
  }
}
