import { dateDifference } from '@/util/date';

export default class BreakInTraining {
  begin!: Date;
  end!: Date;
  days!: number;

  constructor(begin: Date, end: Date) {
    this.begin = begin;
    this.end = end;
    this.days = dateDifference(this.begin, this.end);
  }
}
