/* eslint-disable @typescript-eslint/no-explicit-any */
import Bff from '@/api/bff';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { StorageState } from './types';

export function createActions(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bff: Bff
): ActionTree<StorageState, RootState> {
  return {
    async uploadObject({ commit }, file: File) {
      try {
        // eslint-disable-next-line prefer-const
        let { url, location } = await bff.getPresignedUploadUrl(file.name);

        const progressEventHandler = (progressEvent: ProgressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded /
              (progressEvent.total ?? progressEvent.loaded)) *
              100
          );
          commit('SET_PROGRESS', percentage);
        };
        const uploadLocation = await bff.uploadObject(
          url,
          location == null,
          file,
          progressEventHandler
        );
        if (!location) {
          location = uploadLocation.data.location;
        }
        commit('SET_FILE', file.name);
        return location;
      } catch (err: any) {
        throw Error('Storage Error occurred.' + err);
      }
    },
    async downloadObject(_, location) {
      try {
        const { url } = await bff.getPresignedDownloadUrl(location);
        window.location.href = url;
        return url;
      } catch (err: any) {
        throw Error('Storage Error occurred.' + err);
      }
    },
    async getObjectUrl(_, location) {
      try {
        const { url } = await bff.getPresignedDownloadUrl(location);
        return url;
      } catch (err: any) {
        throw Error('Storage Error occurred.' + err);
      }
    }
  };
}
