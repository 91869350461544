export const calendars = {
  calendar: {
    body: `<rect
                x="0.75"
                y="2.75"
                width="15.5"
                height="12.5"
                rx="1.25"
                stroke="currentColor"
                stroke-width="1.5"
              />
              <rect x="3" y="5" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="3" y="8" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="3" y="11" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="6" y="5" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="6" y="8" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="6" y="11" width="2" height="2" fill="currentColor" stroke="none"  />
              <rect x="9" y="5" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="9" y="8" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="9" y="11" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="12" y="5" width="2" height="2" fill="currentColor" stroke="none" />
              <rect x="12" y="8" width="2" height="2" fill="currentColor" stroke="none" />
              <line
                x1="13"
                y1="2"
                x2="13"
                y2="4.37114e-08"
                stroke="currentColor"
                stroke-width="2"
              />
              <line
                x1="4"
                y1="2"
                x2="4"
                y2="4.37114e-08"
                stroke="currentColor"
                stroke-width="2"
              />`,
    fill: 'none',
    viewBox: '0 0 17 16'
  },
  calendarImportant: {
    body: ` <svg  xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 1.16885H12.8311V13.9999H0V1.16885H2.33105V-9.15527e-05H3.5V1.16885H9.33105V-9.15527e-05H10.5V1.16885ZM11.6689 12.831V4.66885H1.16895V12.831H11.6689ZM11.6689 3.49991V2.33096H1.16895V3.49991H11.6689ZM5.83105 9.33096V5.83096H7V9.33096H5.83105ZM5.83105 11.6689V10.4999H7V11.6689H5.83105Z"/>
    </svg>
    `,
    fill: 'none',
    viewBox: '0 0 13 14'
  },
  calendarStar: {
    body: `<path d="M23.8008 18.416L20.6836 20.7245L21.8789 24.3925L18.75 22.1308L15.6328 24.4042L16.8164 20.7245L13.6992 18.416H17.625L18.7617 14.7831L19.875 18.416H23.8008ZM24 1.90424V16.541H22.5V7.90424H1.5V21.4042H14.5781L14.625 21.4394L14.1445 22.9042H0V1.90424H4.5V0.404236H6V1.90424H18V0.404236H19.5V1.90424H24ZM22.5 6.40424V3.40424H19.5V4.90424H18V3.40424H6V4.90424H4.5V3.40424H1.5V6.40424H22.5Z"/>`,
    fill: 'none',
    viewBox: '0 0 24 25'
  }
};
