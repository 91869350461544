var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _c("label", { staticClass: "field-labels" }, [
      _vm._v(" " + _vm._s(_vm.label) + " "),
    ]),
    _c(
      "div",
      {
        staticClass:
          "relative flex items-center w-full text-sm leading-tight text-gray-600 border rounded focus:outline-none focus:shadow-outline h-9",
        class: { "border-red-500": _vm.showError },
        attrs: { "data-cy": "phoneInputArea" },
      },
      [
        _c("div", { staticClass: "absolute py-2 pl-3 whitespace-nowrap" }, [
          _vm._v("+ 1"),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phoneNumber,
              expression: "phoneNumber",
            },
          ],
          staticClass: "w-full py-2 appearance-none focus:outline-none",
          attrs: {
            disabled: _vm.readonly,
            "data-cy": "phoneInput",
            type: "tel",
            maxlength: "12",
            placeholder: _vm.placeholder,
          },
          domProps: { value: _vm.phoneNumber },
          on: {
            blur: _vm.onBlur,
            change: function ($event) {
              !_vm.isValid && _vm.$emit("input", undefined)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.phoneNumber = $event.target.value
            },
          },
        }),
      ]
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }