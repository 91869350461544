import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';
import { FlightTrainingCourse } from './FlightTrainingCourse';

export class CourseSection extends BaseEntity {
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classStartDate!: Date;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classGraduationDate!: Date;

  @Type(() => Number)
  minimumClassSize!: number;

  @Type(() => Number)
  maximumClassSize!: number;

  @Type(() => FlightTrainingCourse)
  flightTrainingCourse: FlightTrainingCourse = new FlightTrainingCourse();

  @Type(() => Number)
  courseId!: number;

  @Type(() => BaseCandidate)
  students: BaseCandidate[] = [];

  studentIds: number[] = [];

  /**
   * checks if student is enrolled in the section
   *
   *
   * @param {number} studentId
   * @returns  {boolean} if the student is in the section
   * @memberof CourseSection
   */
  public hasStudent(studentId: number): boolean {
    return !!this.studentIds.find((id) => id === studentId);
  }

  /**
   * property name synonym for flightTrainingCourse
   *
   * @readonly
   * @type {FlightTrainingCourse}
   * @memberof CourseSection
   */
  get course(): FlightTrainingCourse {
    return this.flightTrainingCourse;
  }

  set course(course: FlightTrainingCourse) {
    this.flightTrainingCourse = course;
  }

  /**
   * sorts a section by start date in ascending order,meant to be used
   * with Array.sort()
   *
   * @static
   * @param {CourseSection} a
   * @param {CourseSection} b
   * @returns  {number}
   * @memberof CourseSection
   */
  static sortAscendingByStartDate(a: CourseSection, b: CourseSection): number {
    return a.classStartDate.getTime() - b.classStartDate.getTime();
  }

  /**
   * sorts a section by start date in descending order,meant to be used
   * with Array.sort()
   *
   * @static
   * @param {CourseSection} a
   * @param {CourseSection} b
   * @returns  {number}
   * @memberof CourseSection
   */
  static sortDescendingByStartDate(a: CourseSection, b: CourseSection): number {
    return b.classStartDate.getTime() - a.classStartDate.getTime();
  }

  constructor(partial?: Partial<CourseSection>) {
    super();
    if (partial) {
      Object.assign(this, plainToClass(CourseSection, partial));
    }
  }
}
