
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { IModal, IModalOptions } from '../Modals/IModal';
import IconComponent from '../IconComponent.vue';

@Component<YesNoModalComponent>({
  components: {
    ButtonComponent,
    Modal,
    IconComponent
  }
})
export default class YesNoModalComponent
  extends Vue
  implements IModal<boolean>
{
  @Prop({ default: 'small' })
  size!: string;

  private readonly DEFAULT_MESSAGE = 'Are you sure?';

  title: string | undefined = undefined;
  message = 'Are you sure?';
  show = false;
  noLabel = 'No';
  yesLabel = 'Yes';
  resolve: (value: boolean | PromiseLike<boolean>) => void = (): void => void 0;

  public open(options?: IModalOptions): Promise<boolean> {
    this.show = true;
    this.message = options?.message ?? this.DEFAULT_MESSAGE;
    this.title = options?.title;
    this.noLabel = options?.noLabel ?? 'No';
    this.yesLabel = options?.yesLabel ?? 'Yes';
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  confirm(): void {
    this.show = false;
    this.resolve(true);
  }

  cancel(): void {
    this.show = false;
    this.resolve(false);
  }
}
