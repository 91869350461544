
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';

@Component<PhoneInputComponent>({})
export default class PhoneInputComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: number;

  @Prop()
  label?: string;

  @Prop({ default: 'Enter phone' })
  placeholder!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: 'Please enter a valid phone number' })
  errorText!: string;

  @Prop({ default: false })
  readonly!: boolean;

  regex = new RegExp(/^\d{10}$/);
  isPristine = true;
  phoneNumber = '';

  @Watch('phoneNumber')
  handlePhoneNumberInput(newValue: string | number): void {
    this.phoneNumber = this.formatPhoneByNumString(newValue);
    // skip if coming from watcher of this.value
    if (newValue !== this.value) {
      const newPhoneNumber = this.sanitizedNumberString; //sanitize
      const toEmit = newPhoneNumber ? Number(newPhoneNumber) : undefined;
      // check if value is already the same
      if (this.value !== toEmit) {
        this.$emit('input', toEmit);
      }
    }
  }
  get sanitizedNumberString(): string {
    return this.toNumberString(this.phoneNumber) || '';
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    return !this.required || this.regex.test(this.sanitizedNumberString);
  }

  @Watch('isValid')
  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  @Watch('value')
  updateInputValue(): void {
    if (this.value || this.value === 0) {
      this.phoneNumber = this.value.toString();
    }
  }

  mounted(): void {
    this.updateInputValue();
    this.emitValidationState();
  }

  formatPhoneByNumString(value?: string | number): string {
    let numString = this.toNumberString(value);
    if (!numString) {
      return '';
    } else {
      if (numString.length > 3) {
        numString = numString.slice(0, 3) + '-' + numString.slice(3);
      }
      if (numString.length > 7) {
        numString = (
          numString.slice(0, 7) +
          '-' +
          numString.slice(7)
        ).substring(0, 12);
      }
      return numString;
    }
  }

  /**
   * Strips out everything exept digits in the string
   * @param {string | number} value - Source string or number to strip.
   *
   * @returns {string | undefined}
   * String of only digits or undefined if none existed.
   * - Ex. ('A1b2.-3') => '123'
   */
  toNumberString(value?: string | number): string | undefined {
    return value?.toString().match(/\d/g)?.join('');
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }
}
