import { plainToClass } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';

export class Student extends BaseCandidate {
  constructor(partial?: Partial<Student>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Student, partial));
    }
  }
}
