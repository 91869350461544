import Bff from '@/api/bff';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { MiniTableState } from './types';

export function createActions(bff: Bff): ActionTree<MiniTableState, RootState> {
  return {
    async fetchGainsWithSelectParameters(
      _,
      query: SearchDto<BaseCandidate, never>
    ): Promise<SearchResponseDto<BaseCandidate>> {
      const result: SearchResponseDto<BaseCandidate> = await bff.fetch<
        SearchResponseDto<BaseCandidate>
      >(`rpc/gains/find`, query);
      return result;
    }
  };
}
