import { Application } from '@/models/Entities/Application/Application';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import { User } from '@/models/Entities/User';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SearchResponseDto } from '../models/Dtos/common/searchResultDto';
import { Invitation } from '../models/Invitation/invitation';
import { IDownloadUrl, IUploadUrl } from '../models/presignedUrls';
import { SkyPatchApi } from './SkyPatchApi';
import { dataIngestionPath } from './types';

class Bff {
  constructor(private skyPatchApi: SkyPatchApi) {}

  public async fetchInvitation(partialUser: Invitation): Promise<string> {
    const inviteHash = await this.skyPatchApi.post('invite', partialUser);
    return `${window.location.host}/invite/${inviteHash}`;
  }

  public processInvitation(hash: string) {
    return this.skyPatchApi.get(`invite/${hash}`);
  }

  public async fetchApplicants(): Promise<Applicant[]> {
    const applicants: Applicant[] = await this.skyPatchApi.get('applicant');
    return applicants.map((applicant: Applicant) => {
      return new Applicant(applicant);
    });
  }

  public async fetchApplicant(id: number): Promise<Applicant> {
    const applicant: Applicant = await this.skyPatchApi.get(`applicant/${id}`);
    return new Applicant(applicant);
  }

  public createApplicant(applicant: Applicant): Promise<Applicant | undefined> {
    return this.skyPatchApi.post('applicant', applicant);
  }

  public async patchApplicant(
    id: number,
    applicant: Applicant
  ): Promise<Applicant> {
    return new Applicant(
      await this.skyPatchApi.patch(`applicant/${id}`, applicant)
    );
  }

  public deleteApplicant(id: number) {
    return this.skyPatchApi.delete(`applicant/${id}`);
  }

  public getPresignedUploadUrl(fileName: string): Promise<IUploadUrl> {
    return this.skyPatchApi.get(`storage/upload/url/${fileName}`);
  }

  public getPresignedDownloadUrl(location: string): Promise<IDownloadUrl> {
    return this.skyPatchApi.get(`storage/download/url/${location}`);
  }

  public ingestFile(file: File, path: dataIngestionPath) {
    const data = new FormData();
    data.append('file', file);
    const args: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    return this.skyPatchApi.post(`ingestion/parse/${path}`, data, args, 1);
  }

  public uploadObject(
    url: string,
    useMultipart: boolean,
    file: File,
    progressHandler: (progressEvent: ProgressEvent) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<any>> {
    return this.skyPatchApi.putObject(file, useMultipart, url, progressHandler);
  }

  public patchApplicantLog(id: number, log: Log): Promise<Log | undefined> {
    return this.skyPatchApi.patch(`log/${id}`, log);
  }

  public createApplicantLog(log: Log): Promise<Applicant | undefined> {
    return this.skyPatchApi.post(`log`, log);
  }

  public async fetchApplicantLogs(id: number): Promise<Log[] | undefined> {
    const logs: Log[] = await this.skyPatchApi.get(`log/candidate/${id}`);
    return logs.map((log: Log) => {
      return new Log(log);
    });
  }

  public async fetchUsers(query = {}): Promise<User[]> {
    const users: User[] = (await this.skyPatchApi.get('user', query)) || [];
    return users.map((user: User) => {
      return new User(user);
    });
  }

  public async fetchUser(uid: string): Promise<User | undefined> {
    const user: User = await this.skyPatchApi.get(`user/${uid}`, {
      maxTries: 1
    });
    if (user) {
      return new User(user);
    }
  }

  public createUser(user: User): Promise<User> {
    return this.skyPatchApi.post('user', user);
  }

  public async patchUser(user: User): Promise<User | undefined> {
    const patchedUser = await this.skyPatchApi.patch(`user/${user.uid}`, user);
    if (patchedUser) {
      return new User(patchedUser);
    }
  }

  public async setUserInitialRole(user: User): Promise<User | undefined> {
    const patchedUser = await this.skyPatchApi.patch(`user/setRole`, user);
    if (patchedUser) {
      return new User(patchedUser);
    }
  }

  public deleteUser(uid: string): Promise<void> {
    return this.skyPatchApi.delete(`user/${uid}`);
  }

  public restoreUser(uid: string): Promise<void> {
    return this.skyPatchApi.get(`user/${uid}/restore`);
  }

  public async fetchApplications(
    query: { candidateIds: string[]; archived: boolean } = {
      candidateIds: [],
      archived: true
    }
  ): Promise<Application[]> {
    const applications: Application[] = await this.skyPatchApi.get(
      `applicant/application/application-list/application-list`,
      query
    );
    return applications.map((application: Application) => {
      return new Application(application);
    });
  }

  public async fetchApplication(
    applicantId: number,
    applicationId: number
  ): Promise<Application | undefined> {
    return new Application(
      await this.skyPatchApi.get(`applicant/application/${applicationId}`)
    );
  }

  public createApplication(
    application: Application
  ): Promise<Application | undefined> {
    return this.skyPatchApi.post(`applicant/application`, application);
  }

  public async patchApplication(
    applicationId: number,
    application: Application
  ): Promise<Application | undefined> {
    return new Application(
      await this.skyPatchApi.patch(
        `applicant/application/${applicationId}`,
        application
      )
    );
  }

  public async deleteApplication(applicationId: number): Promise<void> {
    await this.skyPatchApi.delete(`applicant/application/${applicationId}`);
  }

  public async patchSponsoringUnit(
    sponsoringUnit: SponsoringUnit
  ): Promise<SponsoringUnit | undefined> {
    const patchedSponsoringUnit = await this.skyPatchApi.patch(
      `sponsoringUnit/${sponsoringUnit.id}`,
      sponsoringUnit
    );
    if (patchedSponsoringUnit) {
      return new SponsoringUnit(patchedSponsoringUnit);
    }
  }

  public deleteSponsoringUnit(sponsoringUnit: SponsoringUnit): Promise<void> {
    return this.skyPatchApi.delete(`sponsoringUnit/${sponsoringUnit.id}`);
  }

  public restoreSponsoringUnit(sponsoringUnit: SponsoringUnit): Promise<void> {
    return this.skyPatchApi.get(`sponsoringUnit/${sponsoringUnit.id}/restore`);
  }

  public async fetchSponsoringUnits(query = {}): Promise<SponsoringUnit[]> {
    const sponsoringUnits: SponsoringUnit[] =
      (await this.skyPatchApi.get('sponsoringUnit', query)) || [];
    return sponsoringUnits.map((sponsoringUnit: SponsoringUnit) => {
      return new SponsoringUnit(sponsoringUnit);
    });
  }

  public async fetchSponsoringUnit(
    id: number
  ): Promise<SponsoringUnit | undefined> {
    const sponsoringUnit: SponsoringUnit = await this.skyPatchApi.get(
      `sponsoringUnit/${id}`,
      {
        maxTries: 1
      }
    );
    if (sponsoringUnit) {
      return new SponsoringUnit(sponsoringUnit);
    }
  }

  /* Generic BFF calls */
  public create<T, K>(path: string, payload: T): Promise<K> {
    return this.skyPatchApi.post(`${path}`, payload);
  }

  public fetch<T>(path: string, params = {}): Promise<T> {
    return this.skyPatchApi.get(path, params);
  }

  public fetchAll<T>(path: string, params = {}): Promise<T[]> {
    return this.skyPatchApi.get(path, params);
  }

  public fetchWithSelectParameters<T>(
    path: string,
    params = {}
  ): Promise<SearchResponseDto<T>> {
    return this.skyPatchApi.get(path, params);
  }

  public patch<T>(path: string, payload: T, params = {}): Promise<T> {
    return this.skyPatchApi.patch(path, payload, params);
  }

  public delete<T>(path: string): Promise<T> {
    return this.skyPatchApi.delete(path);
  }
}

export default Bff;
