import { plainToClass, Type } from 'class-transformer';
import {
  ClassStatus,
  CourseCategory,
  MwsType,
  SecurityClearance,
  TrainingRequestorQuota
} from './Constants';
import { BaseEntity } from '../BaseEntity';
import { PhaseType } from './Constants';
import { CourseSection } from './CourseSection';

export class FlightTrainingCourse extends BaseEntity {
  courseTitle!: string;

  courseNumber!: string;

  base!: string;

  trainingRequestorQuota!: TrainingRequestorQuota;

  requiredSecurityClearance!: SecurityClearance;

  /**
   * @type {string}
   * @memberof FlightTrainingCourse
   * @deprecated
   */
  classNumber!: string;

  courseOwner!: string | null;

  address!: string | null;

  pasCode!: string | null;

  phase!: PhaseType | null;

  @Type(/* istanbul ignore next */ () => Number)
  registrarPhone!: number | null;

  registrar!: string | null;

  courseAka!: string | null;

  courseManager!: string;

  classStatus!: ClassStatus;

  @Type(/* istanbul ignore next */ () => Date)
  dateStatusUpdate!: Date;

  courseCategory!: CourseCategory | null;

  mws!: MwsType | null;

  @Type(/* istanbul ignore next */ () => CourseSection)
  sections: CourseSection[] = [];

  /**
   * property synonym for mws
   *
   * @readonly
   * @memberof BadgeAndMwsCourseMap
   */
  get airCraft(): MwsType | null {
    return this.mws;
  }

  set airCraft(aircraft: MwsType | null) {
    this.mws = aircraft;
  }

  /**
   * checks if a student is in a course
   *
   * @param {number} studentId
   * @returns  {boolean} true if the student is in the course
   * @memberof FlightTrainingCourse
   */
  public hasStudent(studentId: number): boolean {
    return !!this.sections.find((section) => section.hasStudent(studentId));
  }

  get earliestSection(): CourseSection | null {
    return (
      this.sections
        .filter((section) => {
          return section.classGraduationDate.getTime() >= new Date().getTime();
        })
        .sort((a: CourseSection, b: CourseSection) => {
          return b.classStartDate.getTime() - a.classStartDate.getTime();
        })
        .pop() ?? null
    );
  }

  constructor(partial?: Partial<FlightTrainingCourse>) {
    super();
    if (partial) {
      Object.assign(this, plainToClass(FlightTrainingCourse, partial));
    }
  }
}
