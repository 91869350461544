var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-4 bg-gray-100 w-full min-h-screen" }, [
    _c("h3", { staticClass: "my-4 ml-4" }, [_vm._v("QA Dashboard")]),
    _c(
      "div",
      {
        staticClass:
          "bg-white border rounded mb-4 flex items-center justify-center py-2 h-16",
      },
      [
        _c("p", { staticClass: "text-red-700 px-4" }, [
          _vm._v(_vm._s(_vm.error)),
        ]),
        _c("p", { staticClass: "text-green-800 px-4" }, [
          _vm._v(_vm._s(_vm.status)),
        ]),
      ]
    ),
    _c("div", { staticClass: "bg-white border rounded" }, [
      _c("div", { staticClass: "py-6 px-4 border-b flex" }, [
        _vm._m(0),
        _c("div", { staticClass: "flex-1" }, [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex items-center border rounded w-3/12 relative h-10",
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected,
                        expression: "selected",
                      },
                    ],
                    staticClass:
                      "block pl-3 pr-8 appearance-none focus:outline-none w-full",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selected = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.seederValues, function (item, index) {
                    return _c("option", { key: index }, [
                      _vm._v(" " + _vm._s(item.label) + " "),
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "absolute right-0 mr-2" },
                  [
                    _c("icon-component", {
                      attrs: {
                        name: "chevronDown",
                        fill: "black",
                        height: 24,
                        width: 24,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "h-10 rounded bg-blue-600 text-white ml-6 hover:bg-blue-800 transition ease-out duration-500 disabled:bg-gray-600 w-44 flex items-center justify-center",
                class: { "cursor-not-allowed": _vm.isDisabled },
                attrs: { type: "button", disabled: _vm.isDisabled },
                on: { click: _vm.runSeeder },
              },
              [
                _vm.loading
                  ? _c("icon-component", {
                      staticClass: "ml-2 transform rotate-180 animate-spin",
                      attrs: { name: "loading", width: 20, height: 20 },
                    })
                  : _c("span", [_vm._v("Seed Candidates")]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-1" }, [
      _c("h4", [_vm._v("Seeder")]),
      _c("p", [_vm._v("Description: Seed candidates in stage")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }