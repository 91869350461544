import { plainToClass } from 'class-transformer';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';

export class Applicant extends BaseCandidate {
  constructor(partial?: Partial<Applicant>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Applicant, partial));
    }
  }
}
