
import { Component, Watch } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import Vue from 'vue';
import { Student } from '@/models/Entities/Student/Student';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import Routes from '@/router/Routes';
import { EUserRoles } from '@/enums';
import clickOutside from '@/util/directives/clickOutside';

type model = { name: string; id: number };

@Component<SearchComponent>({
  components: { IconComponent },
  directives: {
    clickOutside
  }
})
export default class SearchComponent extends Vue {
  searchValue = '';

  searchableRoles = [
    EUserRoles.ADMIN,
    EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
    EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
    EUserRoles.A3_RB,
    EUserRoles.A3_SR_LEADER
  ];

  get searchInput(): string {
    return this.searchValue;
  }

  set searchInput(value: string) {
    this.searchValue = value.toLowerCase();
  }

  //HACK: watches the other two "candidate" modules for changes to tell it to fire off
  //and fetch any new possible candidates
  @Watch('getApplicants')
  @Watch('getStudents')
  @Watch('canSearch')
  fetchCandidates(): void {
    this.$store.dispatch('candidateModule/fetchAll');
  }

  get canSearch(): boolean {
    const user = this.$store.getters['userModule/currentUser'];
    return user ? this.searchableRoles.includes(user.role) : false;
  }

  get menuOpen(): boolean {
    return !!this.results.length && this.searchInput !== '';
  }

  //HACK: this is just present to check and see if this store was repopulated
  getApplicants(): Applicant {
    return this.$store.getters['applicantModule/collection'];
  }

  //HACK: this is just present to check and see if this store was repopulated
  getStudents(): Student {
    return this.$store.getters['studentModule/collection'];
  }

  get results(): model[] {
    return this.$store.getters['candidateModule/collection']
      .filter((candidate: BaseCandidate) =>
        candidate.searchName.toLowerCase().includes(this.searchInput)
      )
      .slice(0, 6)
      .map((candidate: BaseCandidate) => {
        return {
          name: candidate.fullName,
          id: candidate.id
        };
      });
  }

  handleClick(searchResult: model): void {
    this.clearInput();
    //HACK: only push to applicant profile page, will handle more use cases later
    this.$router
      .push({
        name: Routes.APPLICANT_REDIRECT,
        params: {
          id: `${searchResult.id}`
        }
      })
      .catch((err) => console.log(err));
  }

  clearInput(): void {
    this.searchInput = '';
  }
}
