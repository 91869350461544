var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "flex items-center mt-2" },
    [
      _c("icon-component", {
        staticClass: "mr-2",
        attrs: { name: "triangle", width: 13, height: 12 },
      }),
      _c("p", { staticClass: "inline text-sm text-red-700" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }