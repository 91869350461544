export enum EMedicalClearance {
  NOT_SCHEDULED = 'NOT SCHEDULED',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
  CERTIFIED = 'CERTIFIED',
  DISQULIFIED = 'DISQUALIFIED'
}

export enum EMedicalClearanceStatus {
  CERTIFIED = 'Certified',
  COMPLETE = 'Complete',
  DISQUALIFIED = 'Disqualified',
  IN_PROGRESS_1_OF_7 = 'In Progress 1/7',
  IN_PROGRESS_2_OF_7 = 'In Progress 2/7',
  IN_PROGRESS_3_OF_7 = 'In Progress 3/7',
  IN_PROGRESS_4_OF_7 = 'In Progress 4/7',
  IN_PROGRESS_5_OF_7 = 'In Progress 5/7',
  IN_PROGRESS_6_OF_7 = 'In Progress 6/7',
  NOT_SCHEDULED = 'Not Scheduled',
  SCHEDULED = 'Scheduled'
}
