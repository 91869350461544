//1x is equivalent to 1em, which is 16 pixels
export enum EIconSizes {
  TWO_TIMES_EXTRA_SMALL = '2xs',
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl',
  TWO_TIMES_EXTRA_LARGE = '2xl',
  ONE_TIMES = '1x',
  TWO_TIMES = '2x',
  THREE_TIMES = '3x',
  FIVE_TIMES = '5x',
  SEVEN_TIMES = '7x',
  TEN_TIMES = '10x'
}
