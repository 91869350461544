
import firebase from 'firebase/app';
import 'firebase/auth';
import { Component, Mixins } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import Routes from '../../router/Routes';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component<ForgotPasswordPage>({
  components: {
    LoginFormErrorMessageComponent,
    SignUpFormComponent
  }
})
export default class ForgotPasswordPage extends Mixins(DebounceMixin) {
  routes = Routes;
  email = '';
  customErrorMessage = '';
  handlePasswordReset = this.debounce(this.sendPasswordResetEmail, 750);
  isProcessing = false;

  mounted() {
    if (this.$route.query.refresh === 'true') {
      firebase.auth().signOut();
    }
  }

  get validForm() {
    return this.email != '';
  }

  async sendPasswordResetEmail() {
    this.isProcessing = true;
    try {
      this.customErrorMessage = '';
      await firebase.auth().sendPasswordResetEmail(this.email);
      this.$router.push({ name: Routes.AUTH_PASSWORD_RESET });
    } catch (err: any) {
      this.customErrorMessage = err.message;
    }
    this.isProcessing = false;
  }
}
