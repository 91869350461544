import { GetterTree } from 'vuex';
import { ApplicantState } from './types';
import { RootState } from '../types';
import { Application } from '@/models/Entities/Application/Application';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';

export const getters: GetterTree<ApplicantState, RootState> = {
  applicants(state): Applicant[] {
    return state.applicants;
  },
  applicant(state): Applicant {
    return state.applicant;
  },
  applicantLogs(state): Log[] {
    return state.applicantLogs; // rename to just logs, rename User to User
  },
  applications(state): Application[] {
    return state.applications;
  },
  application(state): Application {
    return state.application;
  },
  isCreatingApplication(state): boolean {
    return state.isCreatingApplication;
  },
  isUpdatingApplication(state): boolean {
    return state.isUpdatingApplication;
  },
  isUpdating(state): boolean {
    return state.isUpdating;
  }
};
