
import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { FormInputState } from '@/models/Forms/FormState';
import IconComponent from '@/components/IconComponent.vue';
import { formatDate } from '@/services/formatService';
import clickOutside from '@/util/directives/clickOutside';
import { noSpaceAlphaOrSymbols } from '@/models/Forms/Regex';

type AlignmentTypes = 'top' | 'bottom' | 'middle';

type NumberOrEmptyString = number | '';

@Component<DateSelectorComponent>({
  components: {
    TextInput,
    IconComponent
  },
  directives: {
    clickOutside
  }
})
export default class DateSelectorComponent extends Vue {
  @Prop()
  readonly id?: string;

  @Prop()
  readonly value?: Date | null;

  @Prop()
  readonly label?: string;

  @Prop()
  readonly link?: string;

  @Prop()
  prefill?: Date;

  // not going too far back due to ui extending screen height
  // year range here for easier select on calendar ui
  @Prop({
    default: () => 20
  })
  readonly yearsBack!: number;

  @Prop({
    default: () => 10
  })
  readonly yearsForward!: number;

  @Prop({ default: 'Please enter a valid date' })
  readonly errorText!: string;

  @Prop({ default: false })
  readonly required!: boolean;

  @Prop({ default: `dd-mm-yyyy` })
  readonly placeholder!: string;

  @Prop()
  readonly initialDate?: Date;

  @Prop({ default: () => true })
  readonly canClear!: boolean;

  @Prop({ default: () => true })
  readonly nullable!: boolean;

  @Prop({ default: () => false })
  readonly setTodaysDateOnFirstClick!: boolean;

  @Prop({ default: false })
  readonly readonly!: boolean;

  @Prop({ default: false })
  readonly disableDateEntry?: boolean;

  @Prop({ default: 'middle' })
  readonly alignment!: AlignmentTypes;

  @VModel()
  inputDate?: Date | string | null;

  showCalendar = false;
  today: Date = new Date();
  dateContext: Date = new Date();
  days: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  yearRange: number[] = [];
  isPristine = true;

  editDate = false;
  dateString = '';
  dateError = '';
  showDateError = false;

  created(): void {
    // Create year drop down for calendary widget
    const endYear = this.today.getFullYear() + Number(this.yearsForward);
    const startYear = this.today.getFullYear() - Number(this.yearsBack);
    const yearRange = [];
    for (let i = startYear; i <= endYear; i++) {
      yearRange.push(i);
    }
    this.yearRange = yearRange;
    this.dateContext = new Date(this.value ?? this.initialDate ?? new Date());
    this.emitValidationState();
    // prefill from another entity
    if (this.prefill) {
      this.inputDate = this.prefill;
    }
  }

  get disableButton(): boolean {
    return this.isLastYear && this.isLastMonth;
  }

  get model(): string | null {
    if (!this.value && !this.dateString) return null;
    // convert text month to numeric month for editing
    if (this.value && this.editDate) {
      const dateArray = this.value
        .toLocaleDateString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .split('/');
      return `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
    }
    // datepicker
    if (!this.editDate && !this.showDateError) {
      return this.formattedDate;
    }
    return this.getDateString(this.dateString);
  }

  set model(input: string | null) {
    if (!input) {
      this.inputDate = null;
      return;
    }
    // handle autofilled dates
    const hasInvalidCharacters = input.replace(
      /[^a-zA-Z&\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ''
    );
    if (hasInvalidCharacters) {
      const validateInput = new Date(input);
      this.inputDate = this.validateDateObject(validateInput, input)
        ? validateInput
        : null;
      return;
    }

    this.inputDate = null;
    this.dateString = input.replaceAll('-', '');
    const date = parseInt(this.sliceDay(this.dateString), 10);
    if (!this.validateDay(date)) return;
    const month = parseInt(this.sliceMonth(this.dateString), 10);
    if (!this.validateMonth(month)) return;
    const year = parseInt(this.sliceYear(this.dateString), 10);
    if (!this.validateYear(year)) return;
    this.inputDate = new Date(year, month - 1, date);
  }

  getDateString(dateString: string): string {
    const length = dateString.length;
    if (length < 2) return dateString;
    if (length < 3) return `${dateString}-`;
    const day = this.sliceDay(dateString);
    const month = this.sliceMonth(dateString);
    if (length < 4) return `${day}-${month}`;
    if (length < 5) return `${day}-${month}-`;
    const year = this.sliceYear(dateString);
    return `${day}-${month}-${year}`;
  }

  validateDay(date: number | undefined): boolean {
    if (date) {
      if (date > 0 && date < 32) {
        return true;
      }
      this.handleDateError('Please enter a valid date');
    }
    return false;
  }

  validateMonth(month: number | undefined): boolean {
    if (month) {
      if (month > 0 && month < 13) {
        return true;
      }
      this.handleDateError('Please enter a valid month');
    }
    return false;
  }

  // client requests no restrictions on the date
  validateYear(year: number | undefined): boolean {
    if (year && year > 1000 && year < 9999) {
      return true;
    }
    if (year && year > 9999) {
      this.handleDateError('Please enter a valid year');
    }
    return false;
  }

  validateDateObject(value: Date, input: string): boolean {
    if (isNaN(value.getTime()) || value.toString() === 'Invalid Date') {
      this.handleDateError(`"${input}" is an invalid date.`);
      return false;
    }
    return true;
  }

  handleCursor(evt: HTMLInputElement, keyType: string): void {
    // handle cursor being 1 off when auto-filled
    if (!keyType) return;

    if (keyType === 'insertFromPaste') {
      this.handleCopyPaste();
      return;
    }
    const selection = evt.selectionStart || 0;
    this.$nextTick(() => {
      let cursorPosition =
        (selection === 2 || selection === 5) &&
        keyType !== 'deleteContentBackward'
          ? selection + 1
          : selection;
      evt.setSelectionRange(cursorPosition, cursorPosition);
    });
  }

  handleCopyPaste(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateInputField = this.$refs.dateInputField as any;
    // handle date entries that are pasted using refs, pasted values not detected by model
    const validateInput = new Date(dateInputField.value);
    dateInputField.value =
      !this.validateDateObject(validateInput, dateInputField.value) && null;

    if (!dateInputField.value) return;

    const formattedDate = validateInput
      .toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .split('/');

    const validateYearRange = this.validateYear(parseInt(formattedDate[2], 10));

    if (!validateYearRange) {
      this.inputDate = null;
      return;
    }
    this.inputDate = validateInput;
    return;
  }

  handleFocus(): void {
    this.editDate = true;
  }

  isNumber(evt: KeyboardEvent): void {
    if (evt.key === 'Enter') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dateInputField = this.$refs.dateInputField as any;
      dateInputField.blur();
    }
    if (noSpaceAlphaOrSymbols.test(evt.key)) {
      evt.preventDefault();
    }
  }

  validateEntries(evt: KeyboardEvent): void {
    if (this.showDateError) {
      this.showDateError = false;
    }
    this.isNumber(evt);
  }

  sliceDay(input: string): string {
    return input.slice(0, 2);
  }

  sliceMonth(input: string): string {
    return input.slice(2, 4);
  }

  sliceYear(input: string): string {
    return input.slice(4);
  }

  handleDateError(errorText: string): void {
    this.dateError = errorText;
    this.showDateError = true;
  }

  get showError(): boolean {
    return (!this.isPristine && !this.isValid) || this.showDateError;
  }

  get showClearButton(): boolean {
    return this.value instanceof Date && this.canClear && !this.readonly;
  }

  get isValid(): boolean {
    if (!this.required) {
      return true;
    }
    return this.value instanceof Date;
  }

  get formattedDate(): string {
    return this.value instanceof Date
      ? formatDate(this.value)
      : formatDate(this.dateContext);
  }
  /* Current model */
  get selectedDate(): NumberOrEmptyString {
    return !this.value ? '' : this.value.getDate();
  }
  get selectedYear(): NumberOrEmptyString {
    return !this.value ? '' : this.value.getFullYear();
  }
  get selectedMonth(): string {
    return !this.value
      ? ''
      : this.value.toLocaleString('default', { month: 'long' });
  }
  /* Todays date */
  get todaysDate(): number {
    return this.today.getDate();
  }
  get todaysMonth(): string {
    return this.today.toLocaleString('default', { month: 'long' });
  }
  get todaysYear(): number {
    return this.today.getFullYear();
  }
  /* Current calendar context date and helpers */
  get year(): number {
    return this.dateContext.getFullYear();
  }
  get month(): string {
    return this.dateContext.toLocaleString('default', { month: 'long' });
  }
  // why do we need this
  get isFirstYear(): boolean {
    return this.year === this.yearRange[0];
  }
  // why do we need this
  get isLastYear(): boolean {
    return this.year === this.yearRange[this.yearRange.length - 1];
  }
  get isFirstMonth(): boolean {
    return this.dateContext.getMonth() === 0;
  }
  get isLastMonth(): boolean {
    return this.dateContext.getMonth() === 11;
  }
  get daysInMonth(): number {
    return new Date(
      this.dateContext.getFullYear(),
      this.dateContext.getMonth() + 1,
      0
    ).getDate();
  }
  get firstDayOfMonth(): number {
    return new Date(
      this.dateContext.getFullYear(),
      this.dateContext.getMonth(),
      1
    ).getDay();
  }

  /* Methods */
  handleOnClick(): void {
    if (this.setTodaysDateOnFirstClick && this.isPristine) {
      this.$emit('input', new Date());
      this.onBlur();
    } else {
      this.showCalendar = !this.showCalendar;
    }
  }

  updateValue(date: number): void {
    const selectedDate = new Date(
      this.dateContext.getFullYear(),
      this.dateContext.getMonth(),
      date
    );
    this.$emit('input', selectedDate);
    this.showCalendar = false;
  }

  clearValue(): void {
    this.$emit('input', null);
    this.dateString = '';
    this.showDateError = false;
    this.showCalendar = false;
    this.isPristine = true;
  }

  addMonth(): void {
    this.dateContext = new Date(
      this.dateContext.setMonth(this.dateContext.getMonth() + 1)
    );
  }
  subtractMonth(): void {
    this.dateContext = new Date(
      this.dateContext.setMonth(this.dateContext.getMonth() - 1)
    );
  }

  changeYear(year: number): void {
    this.dateContext = new Date(this.dateContext.setFullYear(year));
  }

  hideCalendar(): void {
    if (this.showCalendar) {
      this.showCalendar = false;
    }
  }

  @Watch('isValid')
  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  onBlur(): void {
    this.isPristine = false;
    this.editDate = false;
    if (!this.inputDate) {
      this.dateString = '';
      this.showDateError = false;
      this.dateError = '';
    }
    this.$emit('blur');
  }
}
