import { render, staticRenderFns } from "./PasswordResetConfirmationPage.vue?vue&type=template&id=fd1bda56&"
import script from "./PasswordResetConfirmationPage.vue?vue&type=script&lang=ts&"
export * from "./PasswordResetConfirmationPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fd1bda56')) {
      api.createRecord('fd1bda56', component.options)
    } else {
      api.reload('fd1bda56', component.options)
    }
    module.hot.accept("./PasswordResetConfirmationPage.vue?vue&type=template&id=fd1bda56&", function () {
      api.rerender('fd1bda56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Auth/PasswordResetConfirmationPage.vue"
export default component.exports