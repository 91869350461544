import { IAuthClient } from '.';
import store from '@/store';
import { SkyPatchApi } from '@/api/SkyPatchApi';
import firebase from 'firebase';
import { User } from '@/models/Entities/User';

export default class PlatformOneAuthClient implements IAuthClient {
  constructor(private api: SkyPatchApi) {}

  async signIn(): Promise<User | undefined> {
    const res = await this.api.get('api/user/ssoUser');
    return store.dispatch('userModule/login', res);
  }

  async signOut(): Promise<void> {
    return;
  }

  async isEmailVerified(): Promise<boolean> {
    return true;
  }

  async sendPasswordResetEmail(): Promise<void> {
    return;
  }

  async sendEmailVerification(): Promise<void> {
    return;
  }

  async createUserWithEmailAndPassword(): Promise<firebase.User | null> {
    return null;
  }
}
