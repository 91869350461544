import { MutationTree } from 'vuex';
import { StorageState } from './types';

export const mutations: MutationTree<StorageState> = {
  SET_FILE(state, file: string): void {
    state.file = file;
  },
  SET_FILES(state, files: string[]): void {
    state.files = files;
  },
  SET_PROGRESS(state, progress): void {
    state.progress = progress;
  }
};
