import { SkyPatchApi } from '@/api/SkyPatchApi';
import config from '@/config';
import { User } from '@/models/Entities/User';
import firebase from 'firebase';
import FirebaseAuthClient from './firebaseAuthClient';
import PlatformOneAuthClient from './platformOneAuthClient';

interface IAuthClient {
  signIn(email?: string, pass?: string): Promise<User | undefined | void>;
  signOut(): Promise<void>;
  isEmailVerified(): Promise<boolean>;
  sendPasswordResetEmail(email?: string): Promise<void>;
  sendEmailVerification(): Promise<void>;
  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.User | null>;
}

class AuthClient {
  private static _instance: IAuthClient;

  public static initialize(api: SkyPatchApi) {
    switch (config.environment) {
      case 'firebase':
        AuthClient._instance = new FirebaseAuthClient();
        break;
      case 'platform_one':
        AuthClient._instance = new PlatformOneAuthClient(api);
        break;
    }
  }

  public static getInstance() {
    if (!AuthClient._instance) {
      throw new Error('AuthClient not initialized');
    }
    return AuthClient._instance;
  }
}

export { AuthClient, IAuthClient };
