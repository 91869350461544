import { render, staticRenderFns } from "./SocialSecurityComponent.vue?vue&type=template&id=578524a8&"
import script from "./SocialSecurityComponent.vue?vue&type=script&lang=ts&"
export * from "./SocialSecurityComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('578524a8')) {
      api.createRecord('578524a8', component.options)
    } else {
      api.reload('578524a8', component.options)
    }
    module.hot.accept("./SocialSecurityComponent.vue?vue&type=template&id=578524a8&", function () {
      api.rerender('578524a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Forms/Elements/SocialSecurityComponent.vue"
export default component.exports