import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { EUserRoles } from '../enums';
import Routes from './Routes';

export const generateRoutes: (
  store: Store<RootState>
) => RouteConfig[] = () => {
  const meta = {
    roles: [
      EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
      EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
      EUserRoles.ADMIN,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/340th/reports',
      meta,
      component: () => import('@/pages/340th/Reports/ReportPage.vue'),
      children: [
        {
          path: '',
          name: Routes.REPORTS_DASHBOARD,
          meta,
          component: () =>
            import('@/pages/340th/Reports/ReportDashboardPage.vue')
        },
        {
          path: 'ordersEnding',
          name: Routes.ORDERS_ENDING,
          meta,
          component: () =>
            import('@/pages/340th/Reports/OrdersEnding/OrdersEndingPage.vue')
        },
        {
          path: 'gainsPriority',
          name: Routes.GAINS_PRIORITY,
          meta,
          component: () => import('@/pages/340th/Reports/GainsReportPage.vue')
        }
      ]
    }
  ];
};
