import Vue from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter } from './router';
import './assets/styles/palette.scss';
import axios from 'axios';
import config from '@/config';
import AuthRouteHandler from './router/AuthRouteHandler';
import { AuthClient } from '@/services/auth';
import { SkyPatchApi } from './api/SkyPatchApi';
import VueWindow from './plugins/window';
import { init as initFontAwesome } from './util/font-awesome';
import { ApiClient } from './api/ApiClient';

initFontAwesome(Vue);

const router = createRouter(store); // contains Vue.use(Router)

Vue.use(VueWindow);
Vue.config.productionTip = false;

AuthClient.initialize(
  new SkyPatchApi(
    axios.create({
      baseURL: config.skypatch.apiUrl,
      timeout: config.skypatch.timeout
    })
  )
);

AuthRouteHandler.initialize(store, router);

const clientApiUrl = config.skypatch.clientApiUrl;
const apiClient = new ApiClient(clientApiUrl);

new Vue({
  router,
  store,
  provide: {
    apiClient
  },
  render: (h) => h(App)
}).$mount('#app');
