
import 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import IconComponent from '@/components/IconComponent.vue';

@Component<PasswordResetPage>({
  components: {
    SignUpFormComponent,
    IconComponent
  }
})
export default class PasswordResetPage extends Vue {}
