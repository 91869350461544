var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "modal",
        {
          attrs: { size: _vm.size },
          on: {
            close: function ($event) {
              _vm.show = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "p-8", attrs: { "data-cy": "yesNoModalComponent" } },
            [
              _vm.title
                ? _c(
                    "div",
                    { staticClass: "flex justify-between mb-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-2xl capitalize font-medium" },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _c("icon-component", {
                        staticClass: "cursor-pointer mt-2",
                        attrs: { name: "close", width: 20, height: 20 },
                        on: { click: _vm.cancel },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.message
                ? _c("div", { staticClass: "text-base" }, [
                    _vm._v(" " + _vm._s(_vm.message) + " "),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex mt-12" },
                [
                  _c(
                    "button-component",
                    {
                      staticClass: "ml-auto",
                      attrs: { "data-cy": "noButton", border: false },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.noLabel))]
                  ),
                  _c(
                    "button-component",
                    {
                      staticClass: "ml-4",
                      attrs: { "data-cy": "yesButton" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v(_vm._s(_vm.yesLabel))]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }