
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';
import IconComponent from '@/components/IconComponent.vue';

type TextInputType = string | number | null;

@Component<TextInputComponent>({
  components: {
    IconComponent
  },
  inheritAttrs: false
})
export default class TextInputComponent extends Vue {
  @Prop({ default: 'textInputComponentInput' })
  dataCy!: string;

  @Prop()
  id?: string;

  @Prop()
  value?: string | number;

  @Prop()
  label?: string;

  @Prop()
  prefill?: string;

  @Prop({ default: 'Enter a value' })
  placeholder!: string;

  @Prop({ default: 'text' })
  type!: string;

  @Prop({ default: () => new RegExp(/^(?=.{1,75}$).*/) })
  regex!: RegExp;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: 'Please enter a value' })
  errorText!: string;

  @Prop({ default: '55' })
  maxlength!: string;

  @Prop({ default: false })
  canHide!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  isPristine = true;
  textInputValue: TextInputType = null;
  visible = false;

  getCleanValue(newValue: TextInputType): TextInputType {
    let value: TextInputType = newValue;
    if (
      this.type === 'text' &&
      typeof newValue === 'string' &&
      this.textInputValue
    ) {
      value = newValue.toString().replace(/^\s+/g, ''); // Replace leading whitespace only
    } else if (this.type === 'number' && typeof newValue === 'string') {
      value = parseFloat(newValue);
    }
    if (!value && value !== 0) {
      value = null;
    }
    return value;
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    return (
      !this.required ||
      (!!(this.textInputValue || this.textInputValue === 0) &&
        this.regex?.test(String(this.textInputValue))) ||
      false
    );
  }

  handleInputEvent(inputValue: TextInputType): void {
    const value = this.getCleanValue(inputValue);
    this.textInputValue = value;
    this.emitValidationState();
    this.$emit('input', value);
  }

  handleChangeEvent(inputValue: TextInputType): void {
    const value = this.getCleanValue(inputValue);
    this.textInputValue = value;
    this.emitValidationState();
    this.$emit('change', value);
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }

  @Watch('isValid')
  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  @Watch('value')
  updateInputValue(): void {
    if (this.value || this.value === 0) {
      this.textInputValue = this.value;
    }
  }

  created(): void {
    this.updateInputValue(); //seed text value from this.value
    this.emitValidationState();
    if (this.prefill) {
      this.textInputValue = this.prefill;
      this.$emit('input', this.prefill);
    }
  }
}
