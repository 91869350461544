
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Regex from '@/models/Forms/Regex';

import ButtonComponent from '@/components/ButtonComponent.vue';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { IEditModal } from '../IModal';

@Component<OfficerTrainingSchoolClassModalComponent>({
  components: {
    ButtonComponent,
    DateSelector,
    Modal,
    TextInput
  }
})
export default class OfficerTrainingSchoolClassModalComponent
  extends Vue
  implements IEditModal<OfficerTrainingSchoolClass>
{
  @Prop({ default: '' })
  readonly size!: string;

  officerTrainingSchool: OfficerTrainingSchoolClass =
    new OfficerTrainingSchoolClass();
  formState: IFormState = {};
  Regex = Regex;
  show = false;
  resolve: (
    value: OfficerTrainingSchoolClass | PromiseLike<OfficerTrainingSchoolClass>
  ) => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  close(): void {
    this.show = false;
    this.officerTrainingSchool = new OfficerTrainingSchoolClass();
  }

  public async open(id?: number | string): Promise<OfficerTrainingSchoolClass> {
    this.show = true;
    //case for when Add Class button is clicked
    if (id === undefined) {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    }
    const result = await this.$store.dispatch(
      'officerTrainingSchoolClassModule/fetch',
      { id }
    );
    this.officerTrainingSchool = result;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  done(): void {
    this.resolve(this.officerTrainingSchool);
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1
    );
  }
}
