import Bff from '@/api/bff';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import CrudModule from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';

export class InProcessingClassModule
  extends CrudModule<InProcessingClass>
  implements ICrudModule<InProcessingClass>
{
  constructor(bff: Bff) {
    const path = 'inProcessingClass';
    super(InProcessingClass, bff, path);
    const customActions: ActionTree<
      IModuleState<InProcessingClass>,
      RootState
    > = {};
    // Apply custom actions
    this.applyActions(customActions);
  }
}
