import { MutationTree } from 'vuex';
import { UserState } from './types';
import firebase from 'firebase';
import { User } from '@/models/Entities/User';

export const mutations: MutationTree<UserState> = {
  SET_USER(state, user: User): void {
    state.currentUser = user;
  },
  SET_FIREBASE_USER(state, user: firebase.User) {
    state.firebaseUser = user;
  },
  SET_IS_LOGGED_IN(state, isLoggedIn: boolean): void {
    state.isLoggedIn = isLoggedIn;
  },
  SET_IS_EMAIL_VERIFIED(state, isEmailVerified: boolean): void {
    state.isEmailVerified = isEmailVerified;
  },
  SET_USERS(state, users: User[]): void {
    state.users = users;
  },
  UPDATE_USER(state, user: User) {
    const index = state.users.findIndex((usr) => usr.uid == user.uid);
    if (index > -1) {
      state.users.splice(index, 1, user);
    }
  }
};
