import { MutationTree } from 'vuex';
import { LoadingState } from './types';

export const mutations: MutationTree<LoadingState> = {
  SET_IS_LOADING(state, isLoading: boolean): void {
    state.isLoading = isLoading;
  },
  SET_MESSAGE(state, message: string): void {
    state.message = message;
  }
};
