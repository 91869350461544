
import firebase from 'firebase/app';
import 'firebase/auth';
import { Component, Mixins } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component<PasswordResetConfirmationPage>({
  components: {
    LoginFormErrorMessageComponent,
    SignUpFormComponent
  }
})
export default class PasswordResetConfirmationPage extends Mixins(
  DebounceMixin
) {
  customErrorMessage = '';
  pwInput = '';
  pwConfirmInput = '';
  handleFormSubmit = this.debounce(this.resetPassword, 750);
  isProcessing = false;

  mounted() {
    if (this.$route.query.refresh === 'true') {
      firebase.auth().signOut();
    }
  }

  get validForm(): boolean {
    return !this.passwordError && !this.confirmPasswordError;
  }

  get passwordError(): boolean {
    return this.pwInput == '' || this.pwInput.length < 6;
  }
  get confirmPasswordError(): boolean {
    return this.pwConfirmInput != this.pwInput || this.pwConfirmInput == '';
  }

  async resetPassword() {
    this.isProcessing = true;
    try {
      this.validatePassword();
      this.customErrorMessage = '';
    } catch (err: any) {
      this.customErrorMessage = err.message;
    }
    this.isProcessing = false;
  }

  validatePassword(): string {
    if (this.passwordError || this.confirmPasswordError) {
      throw new Error('Invalid password input');
    }
    return this.pwInput;
  }
}
