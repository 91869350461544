export enum ECivilianPilotCertificate {
  ATP = 'ATP (Airline Transport Pilot)',
  Commercial = 'Commercial Pilot',
  Private = 'Private Pilot',
  Recreational = 'Recreational Pilot',
  Sport = 'Sport Pilot',
  Student = 'Student Pilot'
}

export enum ECivilianFlightRatingCategory {
  Airplane = 'Airplane',
  Glider = 'Glider',
  Helicopter = 'Helicopter'
}

export enum ECivilianFlightRatingClass {
  MEL = 'Multi Engine Land',
  MELI = 'Multi Engine Land, Instrument',
  MES = 'Multi Engine Sea',
  MESI = 'Multi Engine Sea, Instrument',
  SEL = 'Single Engine Land',
  SELI = 'Single Engine Land, Instrument',
  SES = 'Single Engine Sea',
  SESI = 'Single Engine Sea, Instrument'
}
