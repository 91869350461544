import { Application } from '@/models/Entities/Application/Application';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { MutationTree } from 'vuex';
import { ApplicantState } from './types';

export const mutations: MutationTree<ApplicantState> = {
  SET_ALL_APPLICANTS(state, applicants: Applicant[]) {
    state.applicants = applicants;
  },
  SET_APPLICANT(state, applicant: Applicant) {
    state.applicant = applicant;
  },
  SET_APPLICANT_LOGS(state, logs: Log[]) {
    state.applicantLogs = logs;
  },
  SET_APPLICATIONS(state, applications: Application[]) {
    state.applications = applications;
  },
  SET_APPLICATION(state, application: Application) {
    state.application = application;
  },
  SET_IS_CREATING_APPLICATION(state, isCreatingApplication: boolean) {
    state.isCreatingApplication = isCreatingApplication;
  },
  SET_IS_UPDATING_APPLICATION(state, isUpdatingApplication: boolean) {
    state.isUpdatingApplication = isUpdatingApplication;
  },
  UPSERT_APPLICANT(state, applicant: Applicant) {
    Object.assign(
      state.applicants.find((element) => element.id === applicant.id) || {},
      applicant
    );
  },
  SET_IS_UPDATING(state, isUpdating: boolean) {
    state.isUpdating = isUpdating;
  }
};
