var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-2 text-center border-t border-gray-400" },
    [_vm._v("Skypatch 2020")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }