import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { SideNavigationState } from './type';

export const state: SideNavigationState = {
  sectionStatus: []
};

export function create(): Module<SideNavigationState, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}
