var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show && _vm.board
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.close } }, [
        _c("div", { staticClass: "p-4 px-8" }, [
          _c("div", { staticClass: "flex justify-between" }, [
            _c("div", { staticClass: "text-3xl capitalize" }, [
              _vm._v("Edit board"),
            ]),
            _c(
              "div",
              {
                staticClass: "text-2xl cursor-pointer",
                on: { click: _vm.cancel },
              },
              [_vm._v("X")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("text-input", {
                attrs: {
                  label: "Board title",
                  required: true,
                  errorText: "Please enter a board title",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("title", $event)
                  },
                },
                model: {
                  value: _vm.board.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.board, "title", $$v)
                  },
                  expression: "board.title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector", {
                attrs: {
                  "data-cy": "boardMeetingDateSelector",
                  label: "Board convening date",
                  required: true,
                  errorText: "Please enter a date the board will convene",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("meetingDate", $event)
                  },
                },
                model: {
                  value: _vm.board.meetingDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.board, "meetingDate", $$v)
                  },
                  expression: "board.meetingDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector", {
                attrs: {
                  "data-cy": "boardStartDateSelector",
                  label: "Open date",
                  required: true,
                  errorText: "Please enter a start date",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("startDate", $event)
                  },
                },
                model: {
                  value: _vm.board.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.board, "startDate", $$v)
                  },
                  expression: "board.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("date-selector", {
                attrs: {
                  "data-cy": "boardEndDateSelector",
                  label: "Close date",
                  required: true,
                  errorText: "Please enter an end date",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("endDate", $event)
                  },
                },
                model: {
                  value: _vm.board.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.board, "endDate", $$v)
                  },
                  expression: "board.endDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-row-reverse mt-4" },
            [
              _c(
                "button-component",
                {
                  class: {
                    "opacity-50": !_vm.valid,
                    "cursor-default": !_vm.valid,
                  },
                  attrs: { "data-cy": "saveButton", disabled: !_vm.valid },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "button-component",
                {
                  attrs: { "data-cy": "cancelButton", border: false },
                  on: { click: _vm.cancel },
                },
                [_c("span", [_vm._v("Cancel")])]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }