import { BaseCandidate } from '@/models/Entities/BaseCandidate';

export class DisplayNameUtil {
  // usually first last
  public static splitDisplayName(displayName: string): {
    firstName: string;
    lastName: string;
  } {
    const strArray = displayName.split(' ').filter((str) => str !== '');
    const firstName = strArray[0];
    const lastName = strArray.slice(1).join(' ');

    return {
      firstName,
      lastName
    };
  }

  public static flattenCandidateName(candidate: BaseCandidate): string {
    const firstName = candidate.identificationInformationFirstname
      ? candidate.identificationInformationFirstname.toUpperCase()
      : '';
    const lastName = candidate.identificationInformationLastname
      ? candidate.identificationInformationLastname.toUpperCase()
      : '';
    return this.flattenName(firstName, lastName);
  }

  public static flattenName(firstName: string, lastName: string): string {
    const nameArray = [lastName];
    if (firstName && firstName.length > 0) {
      nameArray.push(firstName[0]);
    }
    return nameArray.join(', ');
  }

  public static getFullName(first: string | null, last: string | null): string {
    if (first && last) {
      return `${last.toUpperCase()}, ${first.toUpperCase()} `;
    }
    return '';
  }
}
