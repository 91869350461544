/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ReportState } from './types';

export const getters: GetterTree<ReportState, RootState> = {
  data(state): any {
    return state.data;
  }
};
