/**
 * gets the difference in days between two dates
 *
 * @export
 * @param {Date} earlierDate
 * @param {Date} laterDate
 * @returns  {number}
 */
export function dateDifference(earlierDate: Date, laterDate: Date): number {
  return Math.round(
    (laterDate.getTime() - earlierDate.getTime()) / (1000 * 60 * 60 * 24)
  );
}

/**
 * checks if 'date' is between two given dates
 *
 * @export
 * @param {Date} earlierDate
 * @param {Date} laterDate
 * @returns  {boolean}
 */
export function dateIsBetween(
  earlierDate: Date,
  laterDate: Date,
  compareDate = new Date()
): boolean {
  return (
    earlierDate.getTime() < compareDate.getTime() &&
    laterDate.getTime() > compareDate.getTime()
  );
}

/**
 * sort dates in descending order
 *
 * @export
 * @param {Date} dateOne
 * @param {Date} dateTwo
 * @returns  {number}
 */
export function sortDatesDescending(dateOne: Date, dateTwo: Date): number {
  return dateTwo.getTime() - dateOne.getTime();
}

/**
 * sort dates in ascending order
 *
 * @export
 * @param {Date} dateOne
 * @param {Date} dateTwo
 * @returns  {number}
 */
export function sortDatesAscending(dateOne: Date, dateTwo: Date): number {
  return dateOne.getTime() - dateTwo.getTime();
}

/**
 * gets the difference in days from now
 *
 * @export
 * @param {Date} dateToCompare
 * @returns  {number}
 */
export function dateDiffFromNow(dateToCompare: Date): number {
  const timeDifference = new Date().getTime() - dateToCompare.getTime();
  return Math.round(timeDifference / (1000 * 3600 * 24));
}

export function getTime(date?: Date): number {
  if (date) {
    return new Date(date).getTime();
  }
  return 0;
}
