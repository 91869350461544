export type FormOption<T = FormOptionValue> = {
  label: string;
  value?: T;
  disabled?: boolean;
};

export type FormOptionValue = number | string | boolean | null;

export type RadioOptions = FormOption<FormOptionValue>;

export type CheckBoxOptions = FormOption;

export type DropdownOptions = FormOption;

export const yesNoOptions: DropdownOptions[] | RadioOptions[] = [
  { label: 'yes', value: true },
  { label: 'no', value: false }
];

export type NestedDropDown = {
  label: string;
  value: string | NestedDropDown[];
};
