/* eslint-disable @typescript-eslint/no-explicit-any */
import { name as userModuleName } from '@/store/user';
import firebase from 'firebase/app';
import 'firebase/auth';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import config from '@/config';
import { AuthClient } from '@/services/auth';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from './Routes';
import { User } from '@/models/Entities/User';

class AuthRouteHandler {
  private static store: Store<RootState>;
  private static firebaseApp: any;

  static initialize(
    store: Store<RootState>,
    router: VueRouter,
    firebaseApp = firebase,
    handlerConfiguration = config
  ): void {
    this.store = store;
    this.firebaseApp = firebaseApp;

    if (handlerConfiguration.environment === 'firebase') {
      firebaseApp.auth().onAuthStateChanged(async (user) => {
        await AuthRouteHandler.onStateChange(router, user);
      });
    } else {
      AuthClient.getInstance().signIn();
    }

    router.beforeEach((to, from, next) => {
      AuthRouteHandler.onRouterEntry(to, next);
    });
  }

  /*
    Auth state changed handler. This gets triggered when there is a change to the currently logged in firebase user
    Login, logout, signup etc.
  */
  static async onStateChange(router: VueRouter, user: firebase.User | null) {
    try {
      // Logout
      if (!user) {
        await this.store.dispatch(`${userModuleName}/logout`);
        return;
      } else {
        await this.store.dispatch(`${userModuleName}/login`, user);
      }

      // Redirect user if on the login/signup page and there is a redirect query param
      const currentRoute = router.currentRoute;
      const isEmailVerified =
        this.store.getters[`${userModuleName}/isEmailVerified`];

      if (
        !isEmailVerified &&
        currentRoute.name !== Routes.AUTH_SIGNUP_VERIFICATION &&
        currentRoute.name !== Routes.AUTH_SIGNUP
      ) {
        // redirect the user to the verification page
        router.push({ name: Routes.AUTH_SIGNUP_VERIFICATION });
        return;
      }

      const redirect = currentRoute.query.redirect
        ? String(currentRoute.query.redirect)
        : null;
      if (
        (currentRoute.name === Routes.AUTH_LOGIN ||
          currentRoute.name === Routes.AUTH_SIGNUP) &&
        redirect
      ) {
        delete currentRoute.query.redirect; // Don't need this hanging around
        const newRoute = {
          path: redirect,
          query: currentRoute.query
        };
        router.push(newRoute);
      }
    } catch (err: any) {
      /* tslint:disable:no-console */
      console.log('ROUTER ERROR ', err);
    }
  }

  /*
    Router middleware. Guard each route depending on the route configuration in router.ts.
    Ensure the currently signed in user has the correct role to see the route.
  */
  static onRouterEntry(to: Route, next: NavigationGuardNext) {
    try {
      const currentUser = this.store.getters[`${userModuleName}/currentUser`];
      const isLoggedIn = this.store.getters[`${userModuleName}/isLoggedIn`];
      const isEmailVerified =
        this.store.getters[`${userModuleName}/isEmailVerified`];
      const requiresRoles =
        to.meta && to.meta.roles && to.meta.roles.length > 0 ? true : false;
      const requiresAuth = to.matched.some(
        (record) => record.meta.requiresAuth
      );
      if (
        isLoggedIn &&
        !isEmailVerified &&
        to.name !== Routes.AUTH_SIGNUP_VERIFICATION &&
        to.name !== Routes.AUTH_LOGIN &&
        to.name !== Routes.AUTH_LOGOUT
      ) {
        next({
          name: Routes.AUTH_SIGNUP_VERIFICATION
        });
      } else if (requiresAuth && !isLoggedIn) {
        next({
          name: Routes.AUTH_LOGIN,
          query: { redirect: to.path, ...to.query }
        });
      } else if (
        requiresRoles &&
        !this.hasValidRole(currentUser, to.meta?.roles)
      ) {
        next({
          name: Routes.HOME
        });
      } else {
        next();
      }
    } catch (err) {
      console.log('ROUTER ERROR ', err);
    }
  }

  static hasValidRole(
    currentUser: User | null,
    requiredRoles: string[]
  ): boolean {
    if (!requiredRoles || !requiredRoles.length) {
      return true;
    }
    if (!currentUser || currentUser.role == null) {
      return false;
    }
    if (requiredRoles.indexOf(currentUser.role) > -1) {
      return true;
    }
    return false;
  }
}

export default AuthRouteHandler;
