import { EUserRoles } from '@/enums';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (
  store: Store<RootState>
) => RouteConfig[] = () => {
  const meta = {
    roles: [
      EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
      EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
      EUserRoles.ADMIN,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/letter/employee',
      name: Routes.LETTER_EMPLOYEE,
      meta,
      component: () => import('@/pages/Letter/EmployerLetterPage.vue'),
      props: true
    },
    {
      path: '/letter/housing',
      name: Routes.LETTER_HOUSING,
      meta,
      component: () => import('@/pages/Letter/HousingLetterPage.vue'),
      props: true
    },
    {
      path: '/letter/trafficManagement',
      name: Routes.LETTER_TRAFFIC,
      meta,
      component: () => import('@/pages/Letter/TrafficManagementLetterPage.vue'),
      props: true
    }
  ];
};
