import { Route } from 'vue-router';
import { MutationTree } from 'vuex';
import { routerState } from './types';

export const mutations: MutationTree<routerState> = {
  SET_TO_ROUTE(state, route: Route): void {
    state.toRoute = route;
  },
  SET_FROM_ROUTE(state, route: Route): void {
    state.fromRoute = route;
  }
};
