import { GetterTree } from 'vuex';
import { MenuState } from './types';
import { RootState } from '../types';
import { MenuItem } from '@/models/MenuItem';

export const getters: GetterTree<MenuState, RootState> = {
  isOpen(state): boolean {
    return state.isOpen;
  },
  menuItems(state): MenuItem[] {
    return state.menuItems;
  }
};
