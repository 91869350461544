
import firebase from 'firebase/app';
import 'firebase/auth';
import { Component, Mixins } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import Routes from '../../router/Routes';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component<LoginPage>({
  components: {
    LoginFormErrorMessageComponent,
    SignUpFormComponent
  }
})
export default class LoginPage extends Mixins(DebounceMixin) {
  routes = Routes;
  email = '';
  pwInput = '';
  signInFailed = false;
  isProcessing = false;
  handleLogin = this.debounce(this.login, 750);

  get validForm(): boolean {
    return this.email !== '' && this.pwInput !== '';
  }
  mounted(): void {
    if (this.$route.query.refresh === 'true') {
      firebase.auth().signOut();
    }
  }

  async login(): Promise<void> {
    this.isProcessing = true;
    await firebase.auth().signOut();
    try {
      const password = this.validatePassword();
      // login user with email and password through the firebase SDK
      // This will trigger the firebase.auth().onAuthStateChanged handler in main.ts and redirect accordingly
      await firebase.auth().signInWithEmailAndPassword(this.email, password);
      if (this.$route.query.invite) {
        await this.$router.back();
      }
      if (this.$route.query.redirect) {
        return; // let AuthGuard handle the redirect
      }

      this.$router.push({ name: Routes.HOME });
    } catch (err) {
      this.signInFailed = true;
    }
    this.isProcessing = false;
  }

  validatePassword(): string {
    if (!this.pwInput || this.pwInput.length <= 0) {
      throw new Error('Invalid password input');
    }
    return this.pwInput;
  }
}
