var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { title: "Select a sign in method" } },
        [
          _c("a", { attrs: { href: "https://code.il2.dso.mil" } }, [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center flex-grow py-2 border border-black px-28",
              },
              [
                _c("icon-component", {
                  staticClass: "mr-4",
                  attrs: {
                    name: "airForce",
                    width: 27,
                    height: 24,
                    fill: "#333333",
                  },
                }),
                _vm._v(" Sign in with my CAC "),
              ],
              1
            ),
          ]),
          _c("hr", { staticClass: "px-12 my-8 border-gray-700" }),
          _c(
            "router-link",
            { attrs: { to: { name: _vm.routes.AUTH_LOGIN } } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-center flex-grow py-2 border border-black px-28",
                  attrs: { "data-cy": "signInWithPersonalEmailButton" },
                },
                [_vm._v(" Sign in with a personal email account ")]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "flex justify-center mt-10" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: _vm.routes.AUTH_SIGNUP } } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "underline",
                      attrs: { "data-cy": "createAccountButton" },
                    },
                    [_vm._v(" Create a personal email account ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }