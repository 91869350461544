import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';

export class BaseEntity {
  @Type(() => Number)
  id!: number;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  deletedAt?: Date;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  createDateTime!: Date;

  @Type(() => Number)
  createdBy?: number | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  lastChangedDateTime!: Date;

  @Type(() => Number)
  lastChangedBy?: number | null;

  constructor(partial?: Partial<BaseEntity>) {
    if (partial) {
      Object.assign(this, plainToClass(BaseEntity, partial));
    }
  }

  get isArchived(): boolean {
    return !!this.deletedAt;
  }
}
