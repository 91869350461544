import { OpenProps } from '@/components/types';
import { MutationTree } from 'vuex';
import { snackBarState } from './types';

export const mutations: MutationTree<snackBarState> = {
  ENQUEUE(state, message: OpenProps): void {
    state.queue.push(message);
  },
  DEQUEUE(state): void {
    state.queue.shift();
  }
};
