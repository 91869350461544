import { Route } from 'vue-router';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { routerState } from './types';

export function createActions(): ActionTree<routerState, RootState> {
  return {
    setTo({ commit }, route: Route) {
      commit('SET_TO_ROUTE', route);
    },
    setFrom({ commit }, route: Route) {
      commit('SET_FROM_ROUTE', route);
    }
  };
}
