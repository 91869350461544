/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReportState } from '@/store/reports/types';
import { ActionTree } from 'vuex';
import { RootState } from '../types';

export function createActions(): ActionTree<ReportState, RootState> {
  return {
    setData({ commit }, data: any) {
      commit('SET_DATA', data);
    }
  };
}
