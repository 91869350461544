import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { routerState } from './types';

export const state: routerState = {
  toRoute: null,
  fromRoute: null
};

export function create(): Module<routerState, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}

export const name = 'routerModule';
