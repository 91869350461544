
import { Prop, Component, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<SignupFormComponent>({ components: { IconComponent } })
export default class SignupFormComponent extends Vue {
  @Prop({ default: '' })
  readonly title!: string;
  @Prop({ default: true })
  readonly showX!: boolean;
  @Prop({ default: true })
  readonly showArrow!: boolean;
}
