
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';

@Component<NumberInputComponent>({
  inheritAttrs: false
})
export default class NumberInputComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: string | number;

  @Prop()
  label?: string;

  @Prop()
  prefill?: string | number;

  @Prop({ default: 'Enter a value' })
  placeholder!: string;

  @Prop({ default: 'numberInputComponentInput' })
  dataCy!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: 'Please enter a value' })
  errorText!: string;

  @Prop({ default: 0 })
  min!: number;

  @Prop({ default: 50000 })
  max!: number;

  @Prop({ default: 10 })
  maxlength?: number;

  @Prop({ default: 1 })
  step!: number;

  @Prop({ default: false })
  readonly!: boolean;

  isPristine = true;
  textInputValue: string | number | null = null;

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    return !!(
      !this.required ||
      (this.max && this.textInputValue && this.textInputValue <= this.max)
    );
  }

  getNumberValue(trimmedString: string | null): number | null {
    return trimmedString ? Number(trimmedString) : null;
  }

  getTrimmedString(value: string | null): string | null {
    if (value === null) return null;
    let trimmedString = value;
    if (this.maxlength && trimmedString.length > this.maxlength) {
      trimmedString = trimmedString.substring(0, this.maxlength);
    }
    return trimmedString;
  }

  handleChangeEvent(eventName: string, value: string | null): void {
    const trimmedString = this.getTrimmedString(value);
    const numberValue = this.getNumberValue(trimmedString);
    this.textInputValue = trimmedString;
    this.$emit(eventName, numberValue);
    this.emitValidationState();
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }

  @Watch('isValid')
  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  @Watch('value')
  updateInputValue(): void {
    if (this.value || this.value === 0) {
      this.textInputValue = this.getTrimmedString(this.value.toString());
    }
  }

  created(): void {
    this.updateInputValue(); //seed text value from this.value
    this.emitValidationState();
    // prefill from another entity
    if (this.prefill) {
      this.textInputValue = this.prefill;
      this.$emit('input', this.prefill);
    }
  }
}
