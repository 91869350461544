import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { ApplicationState } from './types';
import Bff from '@/api/bff';

export const state: ApplicationState = {};

export function create(bff: Bff): Module<ApplicationState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}
