import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';
import { Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '../store/types';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const placeholderComponent = () => import('@/pages/PlaceHolderPage.vue');
  const applicantDetailProps = () => {
    return {
      boards: store.getters['boardModule/collection'],
      currentUser: store.getters['userModule/currentUser'],
      applicant: store.getters['applicantModule/applicant'],
      applications: store.getters['applicantModule/applications'],
      inProcessingClasses: store.getters['inProcessingClassModule/collection'],
      otsClasses: store.getters['officerTrainingSchoolClassModule/collection']
    };
  };

  const applicationDetailProps = () => {
    return {
      applicant: store.getters['applicantModule/applicant'],
      application: store.getters['applicantModule/application'],
      isUpdatingApplication:
        store.getters['applicantModule/isUpdatingApplication'],
      isCreatingApplication:
        store.getters['applicantModule/isCreatingApplication'],
      boards: store.getters['boardModule/collection'],
      currentBoard: store.getters['boardModule/details']
    };
  };

  const applicantDetailBeforeEnter = beforeEnterWrapper(async (to: Route) => {
    await Promise.all([
      store.dispatch(
        'applicantModule/fetchWithOtsAndInProClasses',
        to.params.id
      ),
      store.dispatch('applicantModule/fetchApplications', {
        candidateIds: [to.params.id],
        archived: false
      }),
      store.dispatch('boardModule/fetchAll'),
      store.dispatch('inProcessingClassModule/fetchAll'),
      store.dispatch('officerTrainingSchoolClassModule/fetchAll')
    ]);
  });

  const applicationCreateBeforeEnter = beforeEnterWrapper(async (to: Route) => {
    // FETCH CURRENT WILL BREAK WITH A 404, NEED TO HANDLE THIS CASE
    await Promise.all([
      store.dispatch('applicantModule/fetch', to.params.id),
      store.dispatch('boardModule/fetchAll'),
      store.dispatch('boardModule/fetchCurrent')
    ]);
  });

  const applicationDetailBeforeEnter = beforeEnterWrapper(async (to: Route) => {
    // FETCH CURRENT WILL BREAK WITH A 404, NEED TO HANDLE THIS CASE
    await Promise.all([
      store.dispatch('applicantModule/fetch', to.params.id),
      store.dispatch('applicantModule/fetchApplication', {
        applicationId: to.params.applicationId,
        query: { archived: false }
      }),
      store.dispatch('boardModule/fetchAll'),
      store.dispatch('boardModule/fetchCurrent')
    ]);
  });

  const meta = {
    roles: [
      EUserRoles.ADMIN,
      EUserRoles.RECRUITER,
      EUserRoles.UNIT_REPRESENTATIVE,
      EUserRoles.FLIGHT_CHIEF,
      EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
      EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
      EUserRoles.A3_SR_LEADER,
      EUserRoles.A3_RB,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/applicant',
      meta,
      component: () => import('@/pages/Applicant/Applicant.vue'),
      children: [
        {
          path: '',
          name: Routes.APPLICANT_COLLECTION,
          meta,
          component: () =>
            import('@/pages/Applicant/ApplicantCollectionPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('applicantModule/fetchAll');
            // Needs to load recruiters for the table selector
            await store.dispatch('recruiterModule/fetchAll');
          }),
          props: () => {
            return {
              applicants: store.getters['applicantModule/applicants']
            };
          }
        },
        {
          path: ':id',
          meta,
          component: () =>
            import('@/pages/Applicant/Details/ApplicantPageV2.vue'),
          beforeEnter: applicantDetailBeforeEnter,
          redirect: { name: Routes.V2_APPLICANT_PROFILE },
          props: applicantDetailProps,
          children: [
            {
              path: 'redirect',
              name: Routes.APPLICANT_REDIRECT,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/ProfileRedirectPage.vue')
            },
            {
              path: 'profile',
              name: Routes.V2_APPLICANT_PROFILE,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/ProfilePage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'application',
              name: Routes.V2_APPLICANT_APPLICATION,
              meta,
              redirect: { name: Routes.V2_APPLICANT_APPLICATION_CREATE },
              component: () =>
                import(
                  '@/pages/Applicant/Details/Application/ApplicantApplicationPage.vue'
                ),
              children: [
                {
                  path: '',
                  name: Routes.V2_APPLICANT_APPLICATION_CREATE,
                  meta,
                  component: () =>
                    import(
                      '@/pages/Applicant/Details/Application/NewApplicationFormPageV2.vue'
                    ),
                  beforeEnter: applicationCreateBeforeEnter,
                  props: applicationDetailProps
                },
                {
                  path: ':applicationId',
                  name: Routes.V2_APPLICANT_APPLICATION_DETAILS,
                  meta,
                  component: () =>
                    import(
                      '@/pages/Applicant/Details/Application/ApplicantApplicationDetailsPageV2.vue'
                    ),
                  beforeEnter: applicationDetailBeforeEnter,
                  props: applicationDetailProps
                }
              ]
            },
            {
              path: 'vitals',
              name: Routes.V2_APPLICANT_VITALS,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/VitalsPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'gains',
              name: Routes.V2_APPLICANT_GAINS,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/GainsPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'in-processing',
              name: Routes.V2_APPLICANT_IN_PROCESSING,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/InProcessingPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'officer-training-school',
              name: Routes.V2_APPLICANT_OFFICER_TRAINING_SCHOOL,
              meta,
              component: () => import('@/pages/Applicant/Details/OtsPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'documents',
              name: Routes.V2_APPLICANT_DOCUMENTS,
              meta,
              component: placeholderComponent,
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'notes-and-orders',
              name: Routes.V2_APPLICANT_NOTES_AND_ORDERS,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/NotesAndOrdersPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            },
            {
              path: 'processing',
              name: Routes.V2_APPLICANT_PROCESSING,
              meta,
              component: () =>
                import('@/pages/Applicant/Details/OutProcessingPage.vue'),
              beforeEnter: applicantDetailBeforeEnter,
              props: applicantDetailProps
            }
          ]
        }
      ]
    }
  ];
};
