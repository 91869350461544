
import { Component, Vue } from 'vue-property-decorator';
import { MenuItemsByRole } from '@/models/MenuItem';
import clickOutside from '@/util/directives/clickOutside';

@Component<MenuComponent>({
  directives: {
    clickOutside
  }
})
export default class MenuComponent extends Vue {
  get isOpen(): boolean {
    return this.$store.getters['menuModule/isOpen'];
  }

  get sections() {
    const currentUser = this.$store.getters['userModule/currentUser'];
    if (currentUser) {
      const menuItems = MenuItemsByRole.find(
        (item) => item.role === currentUser.role
      );
      return menuItems?.sections.map((section) => section.name);
    }
    return [];
  }

  menuItemsBySection(section: string) {
    const currentUser = this.$store.getters['userModule/currentUser'];
    const menuSection = MenuItemsByRole.find((item) => {
      if (item.role === currentUser.role) {
        return item;
      }
    });
    return menuSection?.sections.find((item) => item.name === section)?.items;
  }

  close() {
    this.$store.dispatch('menuModule/closeMenu');
  }

  handleOutsideClick() {
    if (this.isOpen) {
      this.close();
    }
  }
}
