export const arrows = {
  arrowDownFilter: {
    body: `<path
            d="M6.32422 9.97363L6.94629 10.5889L3.57617 13.959L0.199219 10.5889L0.821289 9.97363L3.13184 12.2842L3.125 0.0205078H4L4.00684 12.291L6.32422 9.97363Z"
            fill="#B8B8C1"
          />`,
    fill: 'none',
    viewBox: '0 0 7 14'
  },
  arrowDownThreeBars: {
    body: `<path d="M5.22735 21.0538L7.45641 18.8247L8.68718 20.0555L4.35213 24.4042L0.0170898 20.0555L1.24786 18.8247L3.47692 21.0538V0.404236H5.22735V21.0538ZM10.4786 16.1581V14.4077H17.4803V16.1581H10.4786ZM10.4786 10.9068V9.15637H22.7316V10.9068H10.4786ZM10.4786 3.90509H27.9829V5.65552H10.4786V3.90509Z"/>`,
    fill: 'none',
    viewBox: '0 0 28 25'
  },
  arrowLeft: {
    body: `<path
            d="M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z"
          />`,
    viewBox: '0 0 24 24'
  },
  arrowRight: {
    body: `<path
            d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
          />`,
    viewBox: '0 0 24 24'
  },
  arrowUpFilter: {
    body: ` <path
            d="M6.93262 3.41113L6.31738 4.02637L4.00684 1.72266L4 14H3.125L3.13184 1.70215L0.807617 4.02637L0.192383 3.41113L3.5625 0.0410156L6.93262 3.41113Z"
            fill="#B8B8C1"
          />`,
    fill: 'none',
    viewBox: '0 0 7 14'
  }
};
