import Bff from '@/api/bff';
import { QAState } from '@/store/qa/types';
import { ActionTree } from 'vuex';
import { RootState } from '../types';

export function createActions(bff: Bff): ActionTree<QAState, RootState> {
  return {
    async initiateSeeder(_, payload): Promise<QAState> {
      return await bff.create<string, QAState>('qa', payload);
    }
  };
}
