import { Module } from 'vuex';
import { RootState } from '../types';
import { createActions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { snackBarState } from './types';

export const state: snackBarState = {
  queue: []
};

export function create(): Module<snackBarState, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}

export const name = 'snackBarModule';
