/* eslint-disable @typescript-eslint/no-explicit-any */
import Bff from '@/api/bff';
import { RootState } from '../types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  ICrudModule,
  IModel,
  IModuleState,
  IUpdatePayloadPartial
} from './ICrudModule';
import { merge } from '@/util/object/merge';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';

type FetchWithQuery = { id: number; query: string };

export const MUTATIONS = {
  SET: 'SET',
  SET_ALL: 'SET_ALL',
  SET_SINGLE: 'SET_SINGLE'
} as const;

export default class CrudModule<T> implements ICrudModule<T> {
  private fetchAllQuery = {};

  public namespaced = true;

  public actions: ActionTree<IModuleState<T>, RootState> = {};

  public mutations: MutationTree<IModuleState<T>> = {
    [MUTATIONS.SET_ALL](state: IModuleState<T>, collection: T[]) {
      state.collection = collection;
    },
    [MUTATIONS.SET_SINGLE](state: IModuleState<T>, updatePartial: any) {
      const itemIndex = state.collection.findIndex(
        (i: any) => i.id === updatePartial.id
      );
      if (itemIndex > -1) {
        const item = state.collection[itemIndex];
        // Deep merge the update properties to the existing item
        const updatedItem: T = merge(item, updatePartial);
        // Update collection
        state.collection.splice(itemIndex, 1, updatedItem);
        // Update details if necessary
        const activeDetailsItem: any = state.details;
        if (activeDetailsItem.id === updatePartial.id) {
          state.details = updatedItem;
        }
      }
    },
    [MUTATIONS.SET](state: IModuleState<T>, details: T) {
      state.details = details;
    }
  };

  public getters: GetterTree<IModuleState<T>, RootState> = {
    collection(state: IModuleState<T>): T[] {
      return state.collection;
    },
    details(state: IModuleState<T>): T {
      return state.details;
    }
  };

  public state: IModuleState<T> = {
    collection: [],
    details: {} as T
  };

  constructor(model: IModel<T>, bff: Bff, path: string) {
    const crudActions = this.createCrudActions(model, bff, path);
    this.applyActions(crudActions);
  }

  public applyActions(actions: ActionTree<IModuleState<T>, RootState>): void {
    Object.assign(this.actions, actions);
  }

  public applyGetters(getters: GetterTree<IModuleState<T>, RootState>): void {
    Object.assign(this.getters, getters);
  }

  public applyMutations(mutations: MutationTree<IModuleState<T>>): void {
    Object.assign(this.mutations, mutations);
  }

  public applyState(state: IModuleState<T>): void {
    Object.assign(this.state, state);
  }

  private createCrudActions(
    model: IModel<T>,
    bff: Bff,
    path: string
  ): ActionTree<IModuleState<T>, RootState> {
    return {
      async fetch({ commit }, arg: number | FetchWithQuery): Promise<T> {
        let result: T;

        if ((arg as any).id || (arg as any).query) {
          result = await bff.fetch<T>(
            `${path}/${(arg as any).id}`,
            (arg as any).query
          );
        } else {
          result = await bff.fetch<T>(`${path}/${arg}`);
        }
        const data = new model(result);
        commit(MUTATIONS.SET, data);
        return data;
      },
      fetchAll: async ({ commit }, query = {}): Promise<T[]> => {
        this.fetchAllQuery = query;
        const result: T[] = await bff.fetchAll<T>(path, query);
        const data = result.map((json) => {
          return new model(json);
        });
        commit(MUTATIONS.SET_ALL, data);
        return data;
      },
      fetchWithSelectParameters: async (
        _,
        query: SearchDto<T, never>
      ): Promise<SearchResponseDto<T>> => {
        this.fetchAllQuery = query;
        const result: SearchResponseDto<T> = await bff.fetch<
          SearchResponseDto<T>
        >(`${path}/${path}/find`, query);
        result.data = result.data.map((json) => {
          return new model(json);
        });
        return result;
      },
      create: async ({ dispatch }, payload: T): Promise<T> => {
        const data: T = await bff.create<T, any>(path, payload);
        await dispatch('fetchAll', this.fetchAllQuery);
        return data;
      },
      update: async (
        { commit },
        updatePayload: IUpdatePayloadPartial<T>
      ): Promise<T> => {
        if (!updatePayload.payload || !updatePayload.id) {
          return updatePayload as any;
        }
        // TODO: handle error handling once students can save in the flyout and does commit here go away?
        const data: T = await bff.patch<T>(
          `${path}/${updatePayload.id}/${updatePayload.path ?? ''}`,
          updatePayload.payload,
          updatePayload.query
        );
        commit(MUTATIONS.SET_SINGLE, {
          id: updatePayload.id,
          ...updatePayload.payload
        });
        return data;
      },
      updateSingle: async (
        _,
        updatePayload: IUpdatePayloadPartial<T>
      ): Promise<T> => {
        const data: T = await bff.patch<T>(
          `${path}/${updatePayload.id}`,
          updatePayload.payload
        );
        return data;
      },
      delete: async ({ dispatch }, id: number): Promise<T> => {
        const data: T = await bff.delete<T>(`${path}/${id}`);
        await dispatch('fetchAll', this.fetchAllQuery);
        return data;
      },
      restore: async ({ dispatch }, id: number) => {
        const data: T = await bff.fetch<T>(`${path}/${id}/restore`);
        await dispatch('fetchAll', this.fetchAllQuery);
        return data;
      }
    };
  }
}
