import { render, staticRenderFns } from "./SearchComponent.vue?vue&type=template&id=083d757a&"
import script from "./SearchComponent.vue?vue&type=script&lang=ts&"
export * from "./SearchComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/src/services/webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('083d757a')) {
      api.createRecord('083d757a', component.options)
    } else {
      api.reload('083d757a', component.options)
    }
    module.hot.accept("./SearchComponent.vue?vue&type=template&id=083d757a&", function () {
      api.rerender('083d757a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Header/SearchComponent.vue"
export default component.exports