/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DebounceMixin extends Vue {
  public debounce(fn: any, timeMs: number): () => void {
    let timeout = 0;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = Number(
        setTimeout(() => {
          fn.apply(this, args);
        }, timeMs)
      );
    };
  }
}
