
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Board } from '@/models/Entities/Board';

@Component<CreateBoardModalComponent>({
  components: {
    ButtonComponent,
    DateSelector,
    Modal,
    TextInput
  }
})
export default class CreateBoardModalComponent extends Vue {
  @Prop({ default: '' })
  size!: string;

  board: Board = new Board();
  formState: IFormState = {};
  show = false;
  resolve: (value: Board | PromiseLike<Board>) => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  close(): void {
    this.show = false;
    this.board = new Board();
  }

  public open(): Promise<Board> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  confirm(): void {
    this.resolve(this.board);
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1
    );
  }
}
