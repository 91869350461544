
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { DEFAULT_LOADING_MESSAGE } from '@/store/loading/constants';
import { Component, Vue } from 'vue-property-decorator';

@Component<LoadingComponent>({
  components: {
    IconComponent,
    ButtonComponent
  }
})
export default class LoadingComponent extends Vue {
  get message() {
    return this.$store.getters['loadingModule/message'];
  }

  get toRoute() {
    return this.$store.getters['routerModule/toRoute'];
  }

  get showOtherOptions() {
    return this.message !== DEFAULT_LOADING_MESSAGE;
  }

  private goBack() {
    this.$store.dispatch('loadingModule/stopLoading');
  }
}
