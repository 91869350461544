import { GetterTree } from 'vuex';
import { SideNavigationState } from './type';
import { RootState } from '../types';
import { SectionStatusData } from '@/components/types';

export const getters: GetterTree<SideNavigationState, RootState> = {
  sectionStatusState(state): SectionStatusData[] {
    return state.sectionStatus;
  }
};
