var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center bg-black bg-opacity-50 z-50",
      on: {
        mousedown: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.closeHandler.apply(null, arguments)
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeHandler.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "relative z-50 min-w-full bg-white rounded shadow max-w-screen-lg sm:min-w-0",
          class: _vm.width,
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }