import Bff from '@/api/bff';
import { BadgeAndMwsCourseMap } from '@/models/Entities/Student/BadgeAndMwsCourseMap';
import CrudModule from '../CrudModule';
import { ICrudModule } from '../ICrudModule';

export class FlightTrainingCourseRequirementMapModule
  extends CrudModule<BadgeAndMwsCourseMap>
  implements ICrudModule<BadgeAndMwsCourseMap>
{
  constructor(bff: Bff) {
    const path = 'flightTrainingCourse/required/map';
    super(BadgeAndMwsCourseMap, bff, path);
  }
}
