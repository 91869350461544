export enum BadgeType {
  ABM = 'ABM',
  CSO = 'CSO',
  PILOT = 'Pilot',
  RPA = 'RPA'
}

export enum ETrainingTypes {
  ABM = 'ABM',
  ABM_ALT = 'ABM-Alt',
  CSO = 'CSO',
  CSO_ALT = 'CSO-Alt',
  NON_SELECT = 'Non-Select',
  PILOT = 'Pilot',
  PILOT_ALT = 'Pilot-Alt',
  RPA = 'RPA',
  RPA_ALT = 'RPA-Alt'
}
