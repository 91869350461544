var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("create-applicant-modal", {
        ref: "createApplicantModal",
        attrs: { size: "small" },
      }),
      _c("create-board-modal", {
        ref: "createBoardModal",
        attrs: { size: "small" },
      }),
      _c("edit-board-modal", {
        ref: "editBoardModal",
        attrs: { size: "small" },
      }),
      _c("yes-no-modal", { ref: "yesNoModal" }),
      _c("sponsoring-unit-modal-component", { ref: "sponsoringUnitModal" }),
      _c("officer-training-school-class-modal-component", {
        ref: "officerTrainingSchoolClassModal",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }