
import { Component, Vue } from 'vue-property-decorator';
import SearchComponent from '@/components/Header/SearchComponent.vue';
import Routes from '@/router/Routes';
import IconComponent from '@/components/IconComponent.vue';
import config from '@/config';

@Component<HeaderComponent>({
  components: {
    SearchComponent,
    IconComponent
  }
})
export default class HeaderComponent extends Vue {
  routes = Routes;

  toggleMenu(): void {
    this.$store.dispatch('menuModule/toggleMenu');
  }

  get isSignedIn(): boolean {
    return !!this.$store.getters['userModule/currentUser'];
  }

  get showLoginButton(): boolean {
    return config.environment === 'firebase';
  }

  get isQAAuthorized(): boolean {
    return (
      config.environment === 'firebase' &&
      config.buildStage !== 'production' &&
      this.isSignedIn &&
      this.$route.path != '/qa'
    );
  }

  get isDisabled(): boolean {
    return this.$route.path === '/qa';
  }

  goToQA(): void {
    this.$router.push({ name: Routes.QA });
  }
}
