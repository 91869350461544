import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { StorageState } from './types';
import { RootState } from '../types';
import Bff from '@/api/bff';

export const state: StorageState = {
  file: '',
  files: [],
  progress: 0
};

export function create(bff: Bff): Module<StorageState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}

export const name = 'storageModule';
