import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';
import Bff from '@/api/bff';
import { Student } from '@/models/Entities/Student/Student';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '../../types';
import { DataImports } from '@/models/Entities/DataImports';

export const STUDENT_MUTATIONS = {
  UPDATE_IMPORT_DATE: 'UPDATE_IMPORT_DATE'
};

export class StudentModule
  extends CrudModule<Student>
  implements ICrudModule<Student>
{
  constructor(bff: Bff) {
    const path = 'student';

    super(Student, bff, path);

    const customState: IModuleState<Student> = {
      collection: [],
      details: {} as Student,
      importDates: {} as DataImports
    };

    const customActions: ActionTree<IModuleState<Student>, RootState> = {
      async fetchAllWithSections({ commit }): Promise<Student[]> {
        const students: Student[] = await bff.fetchAll<Student>(
          `${path}/sections/`
        );
        commit(MUTATIONS.SET_ALL, students);
        return students;
      },
      async importTlnData(_, payload: File) {
        return bff.ingestFile(payload, 'tln');
      },
      async importDcrData(_, payload: File) {
        return bff.ingestFile(payload, 'dcr');
      },
      async setStudents({ commit }, { students }) {
        commit(MUTATIONS.SET_ALL, students);
      }
    };

    const customMutations: MutationTree<IModuleState<Student>> = {
      [STUDENT_MUTATIONS.UPDATE_IMPORT_DATE](state, dateArray) {
        if (!dateArray) {
          state.importDates = [];
        } else {
          state.importDates = dateArray;
        }
      }
    };

    this.applyMutations(customMutations);
    this.applyState(customState);
    this.applyActions(customActions);
  }
}
