
import 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import Routes from '../../router/Routes';
import IconComponent from '@/components/IconComponent.vue';

@Component<LoginSelectionPage>({
  components: {
    LoginFormErrorMessageComponent,
    SignUpFormComponent,
    IconComponent
  }
})
export default class LoginSelectionPage extends Vue {
  routes = Routes;
}
