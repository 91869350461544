
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

@Component<LoginFormErrorMessageComponent>({ components: { IconComponent } })
export default class LoginFormErrorMessageComponent extends Vue {
  @Prop({
    default: 'there is an error in the form'
  })
  readonly message!: string;
}
