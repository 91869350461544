var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _c(
      "label",
      { staticClass: "block text-label-gray mb-0.5", attrs: { for: _vm._uid } },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.textInputValue,
          expression: "textInputValue",
        },
      ],
      staticClass:
        "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline",
      class: { "border-red-500": _vm.showError },
      attrs: {
        disabled: _vm.readonly,
        "data-cy": _vm.dataCy,
        id: _vm._uid,
        type: "number",
        min: _vm.min,
        step: _vm.step,
        max: _vm.max,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.textInputValue },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.$emit("enter")
        },
        blur: _vm.onBlur,
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.textInputValue = $event.target.value
          },
          function ($event) {
            return _vm.handleChangeEvent("input", $event.target.value)
          },
        ],
        change: function ($event) {
          return _vm.handleChangeEvent("change", $event.target.value)
        },
      },
    }),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }