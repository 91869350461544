export const documents = {
  document: {
    body: ` <svg xmlns="http://www.w3.org/2000/svg"> <path d="M4 18h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z"/><svg>`,
    fill: 'red',
    viewBox: '0 0 20 20'
  },
  documentCheck: {
    body: `<svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9165 6.88525L6.46875 9.3374L5.28662 8.15088L5.68213 7.75537L6.46875 8.5376L8.521 6.48975L8.9165 6.88525ZM5.0625 3.3125V1.0625H1.125V8.9375H5.0625V9.5H0.5625V0.5H5.4624L7.875 2.9126V6.125L7.3125 6.6875V3.3125H5.0625ZM5.625 2.75H6.9126L5.625 1.4624V2.75Z" fill="#9F9FA8"/>
              </svg>`,
    viewBox: '0 0 9 10'
  },
  documentCopy: {
    body: `<path
              d="M14 6.28906V16H4V13H0V0H6.71094L9.71094 3H10.7109L14 6.28906ZM11 6H12.2891L11 4.71094V6ZM4 3H8.28906L6.28906 1H1V12H4V3ZM13 7H10V4H5V15H13V7Z"
              fill="currentColor"
            />`,
    viewBox: '0 0 14 16',
    fill: 'blue'
  },
  documentPlus: {
    body: `<svg width="12" height="13" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 0.5C10.6055 0.5 10.7031 0.519531 10.793 0.558594C10.8828 0.597656 10.9609 0.652344 11.0273 0.722656C11.0977 0.789062 11.1523 0.867188 11.1914 0.957031C11.2305 1.04687 11.25 1.14453 11.25 1.25V7.0625C11.25 7.18359 11.2676 7.29102 11.3027 7.38477C11.3418 7.47461 11.3887 7.56055 11.4434 7.64258C11.498 7.7207 11.5586 7.79883 11.625 7.87695C11.6914 7.95508 11.752 8.04492 11.8066 8.14648C11.8613 8.24414 11.9062 8.35742 11.9414 8.48633C11.9805 8.61133 12 8.76172 12 8.9375V11.75C12 11.8555 11.9805 11.9531 11.9414 12.043C11.9023 12.1328 11.8477 12.2129 11.7773 12.2832C11.7109 12.3496 11.6328 12.4023 11.543 12.4414C11.4531 12.4805 11.3555 12.5 11.25 12.5H4.5V11.75H9.75V8.9375C9.75 8.76172 9.76758 8.61133 9.80273 8.48633C9.8418 8.35742 9.88867 8.24414 9.94336 8.14648C9.99805 8.04492 10.0586 7.95508 10.125 7.87695C10.1914 7.79883 10.252 7.7207 10.3066 7.64258C10.3613 7.56055 10.4062 7.47461 10.4414 7.38477C10.4805 7.29102 10.5 7.18359 10.5 7.0625V1.25H3V5.75H2.25V0.5H10.5ZM11.25 8.9375C11.25 8.84375 11.2402 8.76172 11.2207 8.69141C11.2051 8.62109 11.1797 8.55664 11.1445 8.49805C11.1133 8.43945 11.0742 8.38281 11.0273 8.32812C10.9844 8.26953 10.9336 8.20703 10.875 8.14062C10.8164 8.20703 10.7637 8.26953 10.7168 8.32812C10.6738 8.38281 10.6348 8.43945 10.5996 8.49805C10.5684 8.55664 10.543 8.62109 10.5234 8.69141C10.5078 8.76172 10.5 8.84375 10.5 8.9375V11.75H11.25V8.9375ZM5.25 10.25H3V12.5H2.25V10.25H0V9.5H2.25V7.25H3V9.5H5.25V10.25Z"/>
    </svg>
    `,
    fill: 'currentColor',
    viewBox: '0 0 12 13'
  },
  documentsInFolder: {
    body: `<svg mlns="http://www.w3.org/2000/svg">
    <path d="M10.5 8H11.25V12.5H0.75V4.25H1.5V2H2.25V0.5H10.5V8ZM3 1.25V2H9V8H9.75V1.25H3ZM2.25 2.75V4.25H4.2832L8.0332 8H8.25V2.75H2.25ZM10.5 11.75V8.75H7.7168L3.9668 5H1.5V11.75H10.5Z"/>
    </svg>
    `,
    fill: 'blue',
    viewBox: '0 0 12 13'
  },
  documentText: {
    body: `<path
                  d="M8.49707 0L12.25 3.75293V14H0.875V0H8.49707ZM8.75 1.49707V3.5H10.7529L8.75 1.49707ZM1.75 13.125H11.375V4.375H7.875V0.875H1.75V13.125ZM3.5 7V6.125H9.625V7H3.5ZM3.5 8.75V7.875H9.625V8.75H3.5ZM3.5 10.5V9.625H9.625V10.5H3.5Z"
    
                />`,
    viewBox: '0 0 13 14',
    fill: 'blue'
  }
};
