var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: _vm.IconMetaData.viewBox,
          height: _vm.height,
          width: _vm.width,
          fill: _vm.fill ? _vm.fill : _vm.IconMetaData.fill,
          fillRule: _vm.IconMetaData.fillRule,
          stroke: _vm.stroke ? _vm.stroke : _vm.IconMetaData.stroke,
          strokeWidth: _vm.IconMetaData.strokeWidth,
        },
        domProps: { innerHTML: _vm._s(_vm.IconMetaData.body) },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }