var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-full h-full" },
    [
      _c("header-component"),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isLoading
            ? _c("loading-component", { staticClass: "mx-auto" })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "relative w-full lg:flex" },
        [
          _c("menu-component", { staticClass: "flex-shrink-0" }),
          _c("router-view", { staticClass: "w-full min-h-screen" }),
        ],
        1
      ),
      _c("modal-mounter-component"),
      _c("snackbar-component"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }