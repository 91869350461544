import { MutationTree } from 'vuex';
import { MenuState } from './types';
import { MenuItem } from '@/models/MenuItem';

export const mutations: MutationTree<MenuState> = {
  SET_IS_OPEN(state, isOpen: boolean): void {
    state.isOpen = isOpen;
  },
  SET_MENU_ITEMS(state, menuItems: MenuItem[]) {
    state.menuItems = menuItems;
  }
};
