var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass:
        "h-8 px-4 text-sm rounded focus:outline-none whitespace-nowrap",
      class: _vm.classes,
      attrs: {
        "data-cy": "buttonComponent",
        type: "button",
        disabled: _vm.disabled,
      },
      on: { click: _vm.clickHandler },
    },
    [_vm._t("default", null, { dataCy: "buttonComponentText" })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }