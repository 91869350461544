import { ELogActionType } from '@/enums/EActionTypes';
import { plainToClass, Type } from 'class-transformer';
import { Application } from './Application/Application';
import { BaseCandidate } from './BaseCandidate';
import { BaseEntity } from './BaseEntity';

export class Log extends BaseEntity {
  @Type(() => Date)
  date!: Date | null;
  text!: string;
  subject!: string | null;
  @Type(() => Boolean)
  actionRequired!: boolean;
  senderUid!: string | null;
  logCreatorDisplayName!: string | null;
  @Type(() => Boolean)
  completed!: boolean;
  @Type(() => Date)
  completedDateTime!: Date | null;
  completedBy!: string | null;
  actionType!: ELogActionType | null;
  @Type(() => Application)
  candidate!: BaseCandidate;

  constructor(partial?: Partial<Log>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Log, partial));
    }
  }
}
