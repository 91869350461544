import { plainToClass, Transform, Type } from 'class-transformer';

import { ESectionStatus } from '@/enums/ESectionStatus';
import {
  RequiredApplicationFieldItem,
  RequiredApplicationFields,
  RequiredSection,
  SectionLabels
} from '@/models/Entities/Application/RequiredFields/RequiredApplicationFields';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { LabelItem } from '@/models/Entities/types';
import {
  BadgeType,
  EApplicationTypes,
  EArb,
  ECivilianFlightCFITypes,
  ECivilianFlightRatingCategory,
  ECivilianFlightRatingClass,
  ECivilianPilotCertificate,
  EFlightPhysicalTypes,
  EPreferredUptBaseTypes,
  EPriorServiceComponent,
  EScrollStatusTypes,
  ETrainingTypes
} from '../../../enums';
import { Board } from '../Board';
import { ApplicationCompletionStatus } from '../Constants';
import { ApplicationProgress } from './Constants';
import { BaseEntity } from '../BaseEntity';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';

export class Application extends BaseEntity {
  @Type(() => Boolean)
  isAccepted!: boolean;

  applicationType!: EApplicationTypes | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  uftBoardDateSelected!: Date;
  @Type(() => String)
  applicantRemarks!: string | null;
  @Type(() => Number)
  numberOfTimesAppliedToUftBoard!: number | null;

  flightPhysicalStatusAtBoard!: EFlightPhysicalTypes | null;

  scrollStatus!: EScrollStatusTypes | null;

  selectedTraining!: ETrainingTypes | null;
  @Type(() => Number)
  patchScore!: number;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  availableTrainingDate!: Date | null;
  @Type(() => String)
  cpwDataSheetLocation!: string | null;
  @Type(() => String)
  unitStrat!: string | null;
  @Type(() => BaseCandidate)
  candidate: BaseCandidate = new BaseCandidate();
  @Type(() => Board)
  board: Board = new Board();

  @Type(() => String)
  priorServiceGrade!: string | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  priorServiceDateofrank!: Date | null;
  @Type(() => Number)
  priorServiceFlighthours!: number | null;
  @Type(() => String)
  priorServiceAirframe!: string | null;
  @Type(() => String)
  priorServiceDutytitle!: string | null;
  @Type(() => Number)
  priorServiceDutyphone!: number | null;
  @Type(() => String)
  priorServiceDutyphonetype!: string | null;
  @Type(() => String)
  priorServiceDutystation!: string | null;
  @Type(() => String)
  priorServiceComponent!: string | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  priorServiceCommissionservicedate!: Date | null;
  @Type(() => Boolean)
  priorServiceIsenjjptvolunteer!: boolean | null;
  priorServiceArb!: EArb | null;

  @Type(() => String)
  academicInformationInstitution!: string | null;
  @Type(() => String)
  academicInformationMajor!: string | null;
  @Type(() => String)
  academicInformationDegree!: string | null;
  @Type(() => Boolean)
  academicInformationAviationprogramgraduate!: boolean | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  academicInformationGraduationdate!: Date | null;
  @Type(() => Number)
  academicInformationGpa!: number | null;
  @Type(() => Boolean)
  academicInformationStemmajor!: boolean | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  academicInformationExpectedrotcgraduation!: Date | null;
  @Type(() => String)
  academicInformationRotcfieldtrainingreportlocation!: string | null;

  @Type(() => String)
  commanderRemarksComments!: string | null;
  @Type(() => Boolean)
  commanderRemarksDoesrecommend!: boolean | null;
  @Type(() => Number)
  commanderRemarksRankscore!: number | null;
  @Type(() => Number)
  commanderRemarksTotalrank!: number | null;
  @Type(() => String)
  commanderRemarksGrade!: string | null;
  @Type(() => String)
  commanderRemarksName!: string | null;

  @Type(() => String)
  exceptionToPolicyReasons!: string[] | null;
  @Type(() => String)
  exceptionToPolicyCustomreason!: string | null;
  @Type(() => String)
  exceptionToPolicyMemolocation!: string | null;

  @Type(() => Boolean)
  flyExperienceHasprivatelicense!: boolean | null;
  @Type(() => Boolean)
  flyExperienceDroppedonrequest!: boolean | null;
  @Type(() => String)
  flyExperienceDroppedonrequestexplanation!: string | null;
  flyExperienceCivilianpilotcertificate!: ECivilianPilotCertificate | null;
  flyExperienceCivilianflightratingcategory!: ECivilianFlightRatingCategory | null;
  flyExperienceCivilianflightratingclass!: ECivilianFlightRatingClass | null;
  flyExperienceCivilianflightcfi!: ECivilianFlightCFITypes | null;
  @Type(() => Number)
  flyExperienceCiviliantotalflighthours!: number | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flyExperienceLastdateflown!: Date | null;
  @Type(() => String)
  flyExperiencePilotslicenselocation!: string | null;
  @Type(() => String)
  flyExperienceMilitaryaeronauticalrating!: string | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  flyExperienceMilitaryaeronauticaldateawarded!: Date | null;
  @Type(() => Number)
  flyExperienceTotalmilitaryflighthours!: number | null;
  @Type(() => String)
  flyExperienceLastpageofpilotlogbooklocation!: string | null;

  @Type(() => String)
  boardChairRemarksQualitativenotes!: string | null;
  boardChairRemarksQualitativenotesscore!: QualitativeNotesScore | null;
  @Type(() => String)
  boardChairRemarksBoardnotes!: string | null;

  @Type(() => Number)
  physicalAssessmentsScore!: number | null;
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  physicalAssessmentsDate!: Date | null;
  @Type(() => String)
  physicalAssessmentsPhysicalfitnessdocumentlocation!: string | null;

  @Type(() => String)
  pointOfContactFirstname!: string | null;
  @Type(() => String)
  pointOfContactLastname!: string | null;
  @Type(() => String)
  pointOfContactEmail!: string | null;
  @Type(() => Number)
  pointOfContactPhonenumber!: number | null;

  selectionPreferencesBasepreference1!: EPreferredUptBaseTypes;
  selectionPreferencesBasepreference2!: EPreferredUptBaseTypes;
  selectionPreferencesBasepreference3!: EPreferredUptBaseTypes;
  selectionPreferencesTrainingpreference1!: BadgeType;
  selectionPreferencesTrainingpreference2!: BadgeType;
  selectionPreferencesTrainingpreference3!: BadgeType;
  selectionPreferencesTrainingpreference4!: BadgeType;

  @Type(() => Boolean)
  sponsorshipIssponsored!: boolean | null;
  @Type(() => String)
  sponsorshipSponsorshipletterlocation!: string | null;
  @Type(() => String)
  sponsorshipSponsorunit!: string | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  testingInformationAfoqtdate!: Date | null;
  @Type(() => Number)
  testingInformationPilotscore!: number | null;
  @Type(() => Number)
  testingInformationCsoscore!: number | null;
  @Type(() => Number)
  testingInformationAbmscore!: number | null;
  @Type(() => Number)
  testingInformationAascore!: number | null;
  @Type(() => Number)
  testingInformationVerbalscore!: number | null;
  @Type(() => Number)
  testingInformationPcsmscore!: number | null;
  @Type(() => Number)
  testingInformationQuantitativescore!: number | null;

  @Type(() => Boolean)
  demographicsIsdualcitizen!: boolean | null;

  boardId!: number | null;

  constructor(partial?: Partial<Application>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Application, partial));
    }
    if (partial?.boardId) {
      this.board.id = partial.boardId;
    }
  }

  get isComplete(): boolean {
    return (
      this.progress.applicationStatus === ApplicationCompletionStatus.COMPLETED
    );
  }

  get requiredFields(): RequiredApplicationFieldItem[] {
    return RequiredApplicationFields.getApplicationRequiredFields(this).filter(
      (field: RequiredApplicationFieldItem) => field.required
    );
  }

  get requiredSections(): RequiredSection[] {
    return this.filterSections(this.requiredFields);
  }

  get incompleteFields(): RequiredApplicationFieldItem[] {
    return RequiredApplicationFields.getApplicationRequiredFields(this).filter(
      (field: RequiredApplicationFieldItem) =>
        !Application.fieldIsComplete(field) && field.required
    );
  }

  get incompleteSections(): RequiredSection[] {
    return this.filterSections(this.incompleteFields);
  }

  sectionStatus(sectionLabel: string): ESectionStatus {
    const requiredFields = this.requiredFields.filter(
      (field) => field.section.label === sectionLabel
    );
    const incompleteFields = this.incompleteFields.filter(
      (field) => field.section.label === sectionLabel
    );

    //untouched
    if (requiredFields.length === new Set(incompleteFields).size) {
      return ESectionStatus.UNTOUCHED;
    }
    //completed
    else if (incompleteFields.length === 0) {
      return ESectionStatus.COMPLETE;
    }
    //partially done
    return ESectionStatus.PARTIAL;
  }

  filterSections(
    fieldItems: RequiredApplicationFieldItem[]
  ): RequiredSection[] {
    return Object.values(SectionLabels)
      .map((labelItem: LabelItem) => {
        //logic to change status
        const items = fieldItems.filter(
          (fieldItem: RequiredApplicationFieldItem) =>
            fieldItem.section.key === labelItem.key
        );

        return {
          section: labelItem,
          items,
          status: this.sectionStatus(labelItem.label)
        };
      })
      .filter(
        (section: RequiredSection) => Object.keys(section.items).length !== 0
      );
  }

  get progress(): ApplicationProgress {
    const completed = this.requiredFields.length - this.incompleteFields.length;
    const total = this.requiredFields.length;
    let applicationStatus = ApplicationCompletionStatus.NOT_TOUCHED;
    if (completed === 0) {
      return { applicationStatus, completed, total };
    } else if (completed < total) {
      applicationStatus = ApplicationCompletionStatus.PARTIAL;
      return { applicationStatus, completed, total };
    }
    applicationStatus = ApplicationCompletionStatus.COMPLETED;
    return { applicationStatus, completed, total };
  }

  static fieldIsComplete(
    requiredApplicationFieldItem: RequiredApplicationFieldItem
  ): boolean {
    return (
      requiredApplicationFieldItem.value !== null &&
      requiredApplicationFieldItem.value !== '' &&
      requiredApplicationFieldItem.value !== undefined
    );
  }

  get currentDesiredTrainings(): BadgeType[] {
    return [
      this.selectionPreferencesTrainingpreference1,
      this.selectionPreferencesTrainingpreference2,
      this.selectionPreferencesTrainingpreference3,
      this.selectionPreferencesTrainingpreference4
    ];
  }

  get currentDesiredUptBases(): EPreferredUptBaseTypes[] {
    return [
      this.selectionPreferencesBasepreference1,
      this.selectionPreferencesBasepreference2,
      this.selectionPreferencesBasepreference3
    ];
  }

  get isActiveDuty(): boolean {
    return this.applicationType === EApplicationTypes.ACTIVE_DUTY;
  }

  get isRotc(): boolean {
    return this.applicationType === EApplicationTypes.ROTC;
  }

  get isSponsored(): boolean {
    return !!this.sponsorshipIssponsored;
  }

  get isOfficer(): boolean {
    if (!this.priorServiceGrade) {
      return false;
    }

    return this.priorServiceGrade.includes('O-') ?? false;
  }

  get requirePreferredUptBases(): boolean {
    return this.currentDesiredTrainings?.includes(BadgeType.PILOT);
  }

  get isPriorService(): boolean {
    const isNotPriorService = [
      EApplicationTypes.NO_PRIOR_SERVICE,
      EApplicationTypes.ROTC
    ];
    return this.applicationType &&
      !isNotPriorService.includes(this.applicationType)
      ? true
      : false;
  }

  get requirePhysicalFitness(): boolean {
    return (
      this.priorServiceComponent === EPriorServiceComponent.REG_AF ||
      this.priorServiceComponent === EPriorServiceComponent.AFR
    );
  }
}

export type QualitativeNotesScore = 0 | 1 | 2 | 3 | 4 | 5;
