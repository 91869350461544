/* tslint:disable */
/* eslint-disable */
/**
 * Skypatch Api
 * Skypatch main database service
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError
} from './base';

/**
 *
 * @export
 * @interface CreateApplicationDto
 */
export interface CreateApplicationDto {
  /**
   *
   * @type {string}
   * @memberof CreateApplicationDto
   */
  sponsorshipSponsorunit: CreateApplicationDtoSponsorshipSponsorunitEnum;
}

export const CreateApplicationDtoSponsorshipSponsorunitEnum = {
  Unsponsored: 'UNSPONSORED',
  _2Sos: '2 SOS',
  _5Sos: '5 SOS',
  _13Rs: '13 RS',
  _18Ars: '18 ARS',
  _39Rqs: '39 RQS',
  _47Fs: '47 FS',
  _53Wrs: '53 WRS',
  _63Ars: '63 ARS',
  _68As: '68 AS',
  _70Ars: '70 ARS',
  _72Ars: '72 ARS',
  _73As: '73 AS',
  _74Ars: '74 ARS',
  _76Ars: '76 ARS',
  _76Fs: '76 FS',
  _77Ars: '77 ARS',
  _78Ars: '78 ARS',
  _78Atks: '78 ATKS',
  _79Ars: '79 ARS',
  _89As: '89 AS',
  _91Atks: '91 ATKS',
  _93Fs: '93 FS',
  _96As: '96 AS',
  _97As: '97 AS',
  _300As: '300 AS',
  _301As: '301 AS',
  _301Fs: '301 FS',
  _301Rqs: '301 RQS',
  _302Fs: '302 FS',
  _303Fs: '303 FS',
  _305Rqs: '305 RQS',
  _312As: '312 AS',
  _313As: '313 AS',
  _314Ars: '314 ARS',
  _317As: '317 AS',
  _326As: '326 AS',
  _327As: '327 AS',
  _328Ars: '328 ARS',
  _336Ars: '336 ARS',
  _337As: '337 AS',
  _343Bs: '343 BS',
  _345Bs: '345 BS',
  _356As: '356 AS',
  _357As: '357 AS',
  _457Fs: '457 FS',
  _465Ars: '465 ARS',
  _466Fs: '466 FS',
  _700As: '700 AS',
  _701As: '701 AS',
  _709As: '709 AS',
  _711Sos: '711 SOS',
  _728As: '728 AS',
  _729As: '729 AS',
  _731As: '731 AS',
  _732As: '732 AS',
  _756Ars: '756 ARS',
  _757As: '757 AS',
  _758As: '758 AS',
  _815As: '815 AS',
  _859Sos: '859 SOS',
  _905Ars: '905 ARS',
  _924Ars: '924 ARS',
  _970Aacs: '970 AACS'
} as const;

export type CreateApplicationDtoSponsorshipSponsorunitEnum =
  (typeof CreateApplicationDtoSponsorshipSponsorunitEnum)[keyof typeof CreateApplicationDtoSponsorshipSponsorunitEnum];

/**
 *
 * @export
 * @interface CreateLogDto
 */
export interface CreateLogDto {
  /**
   *
   * @type {string}
   * @memberof CreateLogDto
   */
  completedDateTime: string;
}
/**
 *
 * @export
 * @interface GetApplicationDto
 */
export interface GetApplicationDto {
  /**
   *
   * @type {boolean}
   * @memberof GetApplicationDto
   */
  isAccepted: boolean;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  applicationType: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  uftBoardDateSelected: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  applicantRemarks: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  numberOfTimesAppliedToUftBoard: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flightPhysicalStatusAtBoard: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  scrollStatus: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  selectedTraining: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  patchScore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  availableTrainingDate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  unitStrat: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  candidate: object;
  /**
   *
   * @type {number}
   * @memberof GetApplicationDto
   */
  candidateId: number;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  board: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  boardId: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  demographicsRace: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  demographicsGender: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  demographicsEthnicity: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  demographicsIsdualcitizen: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceGrade: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceDateofrank: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceFlighthours: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceAirframe: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceDutytitle: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceDutyphone: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceDutyphonetype: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceDutystation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceComponent: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceCommissionservicedate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceIsenjjptvolunteer: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  priorServiceArb: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationInstitution: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationMajor: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationDegree: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationAviationprogramgraduate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationGraduationdate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationGpa: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationStemmajor: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationExpectedrotcgraduation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  academicInformationRotcfieldtrainingreportlocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  exceptionToPolicyReasons: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  exceptionToPolicyCustomreason: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  exceptionToPolicyMemolocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceHasprivatelicense: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceDroppedonrequest: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceDroppedonrequestexplanation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceCivilianpilotcertificate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceCivilianflightratingcategory: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceCivilianflightratingclass: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceCivilianflightcfi: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceCiviliantotalflighthours: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceLastdateflown: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperiencePilotslicenselocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceMilitaryaeronauticalrating: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceMilitaryaeronauticaldateawarded: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceTotalmilitaryflighthours: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  flyExperienceLastpageofpilotlogbooklocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  physicalAssessmentsScore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  physicalAssessmentsDate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  physicalAssessmentsPhysicalfitnessdocumentlocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  pointOfContactFirstname: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  pointOfContactLastname: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  pointOfContactEmail: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  pointOfContactPhonenumber: object;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesBasepreference1: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesBasepreference2: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesBasepreference3: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesTrainingpreference1: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesTrainingpreference2: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesTrainingpreference3: string;
  /**
   *
   * @type {string}
   * @memberof GetApplicationDto
   */
  selectionPreferencesTrainingpreference4: string;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  boardChairRemarksQualitativenotes: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  boardChairRemarksQualitativenotesscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  boardChairRemarksBoardnotes: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksComments: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksDoesrecommend: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksRankscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksTotalrank: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksGrade: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  commanderRemarksName: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  seniorRaterRemarksComments: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  seniorRaterRemarksRankscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  seniorRaterRemarksTotalrank: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  seniorRaterRemarksGrade: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  seniorRaterRemarksName: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  sponsorshipIssponsored: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  sponsorshipSponsorunit: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  sponsorshipSponsorshipletterlocation: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationAfoqtdate: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationPilotscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationCsoscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationAbmscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationAascore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationVerbalscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationPcsmscore: object;
  /**
   *
   * @type {object}
   * @memberof GetApplicationDto
   */
  testingInformationQuantitativescore: object;
}
/**
 *
 * @export
 * @interface UpdateApplicationDto
 */
export interface UpdateApplicationDto {
  /**
   *
   * @type {string}
   * @memberof UpdateApplicationDto
   */
  sponsorshipSponsorunit: UpdateApplicationDtoSponsorshipSponsorunitEnum;
}

export const UpdateApplicationDtoSponsorshipSponsorunitEnum = {
  Unsponsored: 'UNSPONSORED',
  _2Sos: '2 SOS',
  _5Sos: '5 SOS',
  _13Rs: '13 RS',
  _18Ars: '18 ARS',
  _39Rqs: '39 RQS',
  _47Fs: '47 FS',
  _53Wrs: '53 WRS',
  _63Ars: '63 ARS',
  _68As: '68 AS',
  _70Ars: '70 ARS',
  _72Ars: '72 ARS',
  _73As: '73 AS',
  _74Ars: '74 ARS',
  _76Ars: '76 ARS',
  _76Fs: '76 FS',
  _77Ars: '77 ARS',
  _78Ars: '78 ARS',
  _78Atks: '78 ATKS',
  _79Ars: '79 ARS',
  _89As: '89 AS',
  _91Atks: '91 ATKS',
  _93Fs: '93 FS',
  _96As: '96 AS',
  _97As: '97 AS',
  _300As: '300 AS',
  _301As: '301 AS',
  _301Fs: '301 FS',
  _301Rqs: '301 RQS',
  _302Fs: '302 FS',
  _303Fs: '303 FS',
  _305Rqs: '305 RQS',
  _312As: '312 AS',
  _313As: '313 AS',
  _314Ars: '314 ARS',
  _317As: '317 AS',
  _326As: '326 AS',
  _327As: '327 AS',
  _328Ars: '328 ARS',
  _336Ars: '336 ARS',
  _337As: '337 AS',
  _343Bs: '343 BS',
  _345Bs: '345 BS',
  _356As: '356 AS',
  _357As: '357 AS',
  _457Fs: '457 FS',
  _465Ars: '465 ARS',
  _466Fs: '466 FS',
  _700As: '700 AS',
  _701As: '701 AS',
  _709As: '709 AS',
  _711Sos: '711 SOS',
  _728As: '728 AS',
  _729As: '729 AS',
  _731As: '731 AS',
  _732As: '732 AS',
  _756Ars: '756 ARS',
  _757As: '757 AS',
  _758As: '758 AS',
  _815As: '815 AS',
  _859Sos: '859 SOS',
  _905Ars: '905 ARS',
  _924Ars: '924 ARS',
  _970Aacs: '970 AACS'
} as const;

export type UpdateApplicationDtoSponsorshipSponsorunitEnum =
  (typeof UpdateApplicationDtoSponsorshipSponsorunitEnum)[keyof typeof UpdateApplicationDtoSponsorshipSponsorunitEnum];

/**
 *
 * @export
 * @interface UpdateLogDto
 */
export interface UpdateLogDto {
  /**
   *
   * @type {string}
   * @memberof UpdateLogDto
   */
  completedDateTime?: string;
}

/**
 * ApplicationV2Api - axios parameter creator
 * @export
 */
export const ApplicationV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateApplicationDto} createApplicationDto Create application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplication: async (
      createApplicationDto: CreateApplicationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createApplicationDto' is not null or undefined
      assertParamExists(
        'createApplication',
        'createApplicationDto',
        createApplicationDto
      );
      const localVarPath = `/v2/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplicationDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplication: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findApplication', 'id', id);
      const localVarPath = `/v2/application/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<string>} candidateIds
     * @param {boolean} archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplicationsByCandidate: async (
      candidateIds: Array<string>,
      archived: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'candidateIds' is not null or undefined
      assertParamExists(
        'findApplicationsByCandidate',
        'candidateIds',
        candidateIds
      );
      // verify required parameter 'archived' is not null or undefined
      assertParamExists('findApplicationsByCandidate', 'archived', archived);
      const localVarPath = `/v2/application/application-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (candidateIds) {
        localVarQueryParameter['candidateIds'] = candidateIds;
      }

      if (archived !== undefined) {
        localVarQueryParameter['archived'] = archived;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplicationsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/application/application/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeApplication: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeApplication', 'id', id);
      const localVarPath = `/v2/application/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateApplicationDto} updateApplicationDto Update application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplication: async (
      id: string,
      updateApplicationDto: UpdateApplicationDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateApplication', 'id', id);
      // verify required parameter 'updateApplicationDto' is not null or undefined
      assertParamExists(
        'updateApplication',
        'updateApplicationDto',
        updateApplicationDto
      );
      const localVarPath = `/v2/application/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateApplicationDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ApplicationV2Api - functional programming interface
 * @export
 */
export const ApplicationV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ApplicationV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateApplicationDto} createApplicationDto Create application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApplication(
      createApplicationDto: CreateApplicationDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createApplication(
          createApplicationDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findApplication(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetApplicationDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findApplication(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {Array<string>} candidateIds
     * @param {boolean} archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findApplicationsByCandidate(
      candidateIds: Array<string>,
      archived: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findApplicationsByCandidate(
          candidateIds,
          archived,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findApplicationsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findApplicationsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeApplication(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeApplication(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateApplicationDto} updateApplicationDto Update application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateApplication(
      id: string,
      updateApplicationDto: UpdateApplicationDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateApplication(
          id,
          updateApplicationDto,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * ApplicationV2Api - factory interface
 * @export
 */
export const ApplicationV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ApplicationV2ApiFp(configuration);
  return {
    /**
     *
     * @param {CreateApplicationDto} createApplicationDto Create application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplication(
      createApplicationDto: CreateApplicationDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .createApplication(createApplicationDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplication(
      id: string,
      options?: any
    ): AxiosPromise<GetApplicationDto> {
      return localVarFp
        .findApplication(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} candidateIds
     * @param {boolean} archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplicationsByCandidate(
      candidateIds: Array<string>,
      archived: boolean,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .findApplicationsByCandidate(candidateIds, archived, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findApplicationsWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findApplicationsWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeApplication(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeApplication(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateApplicationDto} updateApplicationDto Update application dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplication(
      id: string,
      updateApplicationDto: UpdateApplicationDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateApplication(id, updateApplicationDto, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ApplicationV2Api - object-oriented interface
 * @export
 * @class ApplicationV2Api
 * @extends {BaseAPI}
 */
export class ApplicationV2Api extends BaseAPI {
  /**
   *
   * @param {CreateApplicationDto} createApplicationDto Create application dto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public createApplication(
    createApplicationDto: CreateApplicationDto,
    options?: AxiosRequestConfig
  ) {
    return ApplicationV2ApiFp(this.configuration)
      .createApplication(createApplicationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public findApplication(id: string, options?: AxiosRequestConfig) {
    return ApplicationV2ApiFp(this.configuration)
      .findApplication(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} candidateIds
   * @param {boolean} archived
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public findApplicationsByCandidate(
    candidateIds: Array<string>,
    archived: boolean,
    options?: AxiosRequestConfig
  ) {
    return ApplicationV2ApiFp(this.configuration)
      .findApplicationsByCandidate(candidateIds, archived, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public findApplicationsWithParams(options?: AxiosRequestConfig) {
    return ApplicationV2ApiFp(this.configuration)
      .findApplicationsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public removeApplication(id: string, options?: AxiosRequestConfig) {
    return ApplicationV2ApiFp(this.configuration)
      .removeApplication(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateApplicationDto} updateApplicationDto Update application dto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationV2Api
   */
  public updateApplication(
    id: string,
    updateApplicationDto: UpdateApplicationDto,
    options?: AxiosRequestConfig
  ) {
    return ApplicationV2ApiFp(this.configuration)
      .updateApplication(id, updateApplicationDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BoardV2Api - axios parameter creator
 * @export
 */
export const BoardV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBoard: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createBoard', 'body', body);
      const localVarPath = `/v2/board`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoard: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findBoard', 'id', id);
      const localVarPath = `/v2/board/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoards: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/board`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoardsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/board/board/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCurrentBoard: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/board/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeBoard: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeBoard', 'id', id);
      const localVarPath = `/v2/board/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreBoard: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('restoreBoard', 'id', id);
      const localVarPath = `/v2/board/{id}/restore`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBoard: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateBoard', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateBoard', 'body', body);
      const localVarPath = `/v2/board/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BoardV2Api - functional programming interface
 * @export
 */
export const BoardV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BoardV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBoard(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBoard(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findBoard(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findBoard(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findBoards(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findBoards(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findBoardsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findBoardsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCurrentBoard(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findCurrentBoard(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeBoard(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeBoard(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreBoard(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restoreBoard(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBoard(
      id: string,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBoard(
        id,
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * BoardV2Api - factory interface
 * @export
 */
export const BoardV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BoardV2ApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBoard(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createBoard(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoard(id: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .findBoard(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoards(options?: any): AxiosPromise<Array<object>> {
      return localVarFp
        .findBoards(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findBoardsWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findBoardsWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCurrentBoard(options?: any): AxiosPromise<object> {
      return localVarFp
        .findCurrentBoard(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeBoard(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeBoard(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreBoard(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .restoreBoard(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBoard(id: string, body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .updateBoard(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * BoardV2Api - object-oriented interface
 * @export
 * @class BoardV2Api
 * @extends {BaseAPI}
 */
export class BoardV2Api extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public createBoard(body: object, options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .createBoard(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public findBoard(id: string, options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .findBoard(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public findBoards(options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .findBoards(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public findBoardsWithParams(options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .findBoardsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public findCurrentBoard(options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .findCurrentBoard(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public removeBoard(id: string, options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .removeBoard(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public restoreBoard(id: string, options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .restoreBoard(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BoardV2Api
   */
  public updateBoard(id: string, body: object, options?: AxiosRequestConfig) {
    return BoardV2ApiFp(this.configuration)
      .updateBoard(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CandidateApi - axios parameter creator
 * @export
 */
export const CandidateApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Create candidate
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCandidate: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createCandidate', 'body', body);
      const localVarPath = `/v2/candidate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidate: async (
      id: string,
      relations: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findCandidate', 'id', id);
      // verify required parameter 'relations' is not null or undefined
      assertParamExists('findCandidate', 'relations', relations);
      const localVarPath = `/v2/candidate/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (relations) {
        localVarQueryParameter['relations'] = relations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [relations]
     * @param {'Applicant' | 'Student'} [recruitmentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidates: async (
      relations?: string,
      recruitmentStatus?: 'Applicant' | 'Student',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/candidate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (relations !== undefined) {
        localVarQueryParameter['relations'] = relations;
      }

      if (recruitmentStatus !== undefined) {
        localVarQueryParameter['recruitmentStatus'] = recruitmentStatus;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidatesWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/candidate/candidate/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeCandidate: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeCandidate', 'id', id);
      const localVarPath = `/v2/candidate/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreCandidate: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('restoreCandidate', 'id', id);
      const localVarPath = `/v2/candidate/{id}/restore`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCandidate: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCandidate', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateCandidate', 'body', body);
      const localVarPath = `/v2/candidate/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CandidateApi - functional programming interface
 * @export
 */
export const CandidateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CandidateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create candidate
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCandidate(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidate(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCandidate(
      id: string,
      relations: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCandidate(
        id,
        relations,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} [relations]
     * @param {'Applicant' | 'Student'} [recruitmentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCandidates(
      relations?: string,
      recruitmentStatus?: 'Applicant' | 'Student',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCandidates(
        relations,
        recruitmentStatus,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCandidatesWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findCandidatesWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeCandidate(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeCandidate(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreCandidate(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.restoreCandidate(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCandidate(
      id: string,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCandidate(
        id,
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * CandidateApi - factory interface
 * @export
 */
export const CandidateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CandidateApiFp(configuration);
  return {
    /**
     *
     * @summary Create candidate
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCandidate(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createCandidate(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidate(
      id: string,
      relations: Array<string>,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .findCandidate(id, relations, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [relations]
     * @param {'Applicant' | 'Student'} [recruitmentStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidates(
      relations?: string,
      recruitmentStatus?: 'Applicant' | 'Student',
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .findCandidates(relations, recruitmentStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCandidatesWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findCandidatesWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeCandidate(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeCandidate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreCandidate(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .restoreCandidate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCandidate(
      id: string,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateCandidate(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CandidateApi - object-oriented interface
 * @export
 * @class CandidateApi
 * @extends {BaseAPI}
 */
export class CandidateApi extends BaseAPI {
  /**
   *
   * @summary Create candidate
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public createCandidate(body: object, options?: AxiosRequestConfig) {
    return CandidateApiFp(this.configuration)
      .createCandidate(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {Array<string>} relations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public findCandidate(
    id: string,
    relations: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return CandidateApiFp(this.configuration)
      .findCandidate(id, relations, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [relations]
   * @param {'Applicant' | 'Student'} [recruitmentStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public findCandidates(
    relations?: string,
    recruitmentStatus?: 'Applicant' | 'Student',
    options?: AxiosRequestConfig
  ) {
    return CandidateApiFp(this.configuration)
      .findCandidates(relations, recruitmentStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public findCandidatesWithParams(options?: AxiosRequestConfig) {
    return CandidateApiFp(this.configuration)
      .findCandidatesWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public removeCandidate(id: string, options?: AxiosRequestConfig) {
    return CandidateApiFp(this.configuration)
      .removeCandidate(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public restoreCandidate(id: string, options?: AxiosRequestConfig) {
    return CandidateApiFp(this.configuration)
      .restoreCandidate(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CandidateApi
   */
  public updateCandidate(
    id: string,
    body: object,
    options?: AxiosRequestConfig
  ) {
    return CandidateApiFp(this.configuration)
      .updateCandidate(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CensusApi - axios parameter creator
 * @export
 */
export const CensusApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCensuses: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/census/census/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CensusApi - functional programming interface
 * @export
 */
export const CensusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CensusApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCensuses(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCensuses(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * CensusApi - factory interface
 * @export
 */
export const CensusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CensusApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCensuses(options?: any): AxiosPromise<object> {
      return localVarFp
        .findCensuses(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CensusApi - object-oriented interface
 * @export
 * @class CensusApi
 * @extends {BaseAPI}
 */
export class CensusApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CensusApi
   */
  public findCensuses(options?: AxiosRequestConfig) {
    return CensusApiFp(this.configuration)
      .findCensuses(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DcrImportLogV2Api - axios parameter creator
 * @export
 */
export const DcrImportLogV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDcrImportLogsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/dcr-import-log/dcr-import-log/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DcrImportLogV2Api - functional programming interface
 * @export
 */
export const DcrImportLogV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DcrImportLogV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findDcrImportLogsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findDcrImportLogsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * DcrImportLogV2Api - factory interface
 * @export
 */
export const DcrImportLogV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DcrImportLogV2ApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDcrImportLogsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findDcrImportLogsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DcrImportLogV2Api - object-oriented interface
 * @export
 * @class DcrImportLogV2Api
 * @extends {BaseAPI}
 */
export class DcrImportLogV2Api extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DcrImportLogV2Api
   */
  public findDcrImportLogsWithParams(options?: AxiosRequestConfig) {
    return DcrImportLogV2ApiFp(this.configuration)
      .findDcrImportLogsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DcrRowLogV2Api - axios parameter creator
 * @export
 */
export const DcrRowLogV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDcrRowLogsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/dcr-row-log/dcr-row-log/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DcrRowLogV2Api - functional programming interface
 * @export
 */
export const DcrRowLogV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DcrRowLogV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findDcrRowLogsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findDcrRowLogsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * DcrRowLogV2Api - factory interface
 * @export
 */
export const DcrRowLogV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DcrRowLogV2ApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDcrRowLogsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findDcrRowLogsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DcrRowLogV2Api - object-oriented interface
 * @export
 * @class DcrRowLogV2Api
 * @extends {BaseAPI}
 */
export class DcrRowLogV2Api extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DcrRowLogV2Api
   */
  public findDcrRowLogsWithParams(options?: AxiosRequestConfig) {
    return DcrRowLogV2ApiFp(this.configuration)
      .findDcrRowLogsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSeedData: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('generateSeedData', 'body', body);
      const localVarPath = `/v2/qa`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHello: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateSeedData(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateSeedData(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHello(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHello(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateSeedData(body: object, options?: any): AxiosPromise<void> {
      return localVarFp
        .generateSeedData(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHello(options?: any): AxiosPromise<string> {
      return localVarFp
        .getHello(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public generateSeedData(body: object, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .generateSeedData(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getHello(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getHello(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FlightTrainingCourseApi - axios parameter creator
 * @export
 */
export const FlightTrainingCourseApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFlightTrainingCourse: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createFlightTrainingCourse', 'body', body);
      const localVarPath = `/v2/flightTrainingCourse`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFlightTrainingCourse: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFlightTrainingCourse', 'id', id);
      const localVarPath = `/v2/flightTrainingCourse/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {'Pilot' | 'CSO' | 'ABM' | 'RPA'} badge
     * @param {'A-10' | 'B-1' | 'B-52' | 'C-5' | 'C-17' | 'C-40' | 'C-130' | 'C-130J' | 'C-130H' | 'C-145' | 'C-146' | 'E-3' | 'F-16' | 'F-22' | 'F-35' | 'HH-60' | 'HC-130' | 'KC-10' | 'KC-46' | 'KC-135' | 'MQ-9' | 'WC-130J'} mws
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAllRequiredFlightTrainingCourses: async (
      badge: 'Pilot' | 'CSO' | 'ABM' | 'RPA',
      mws:
        | 'A-10'
        | 'B-1'
        | 'B-52'
        | 'C-5'
        | 'C-17'
        | 'C-40'
        | 'C-130'
        | 'C-130J'
        | 'C-130H'
        | 'C-145'
        | 'C-146'
        | 'E-3'
        | 'F-16'
        | 'F-22'
        | 'F-35'
        | 'HH-60'
        | 'HC-130'
        | 'KC-10'
        | 'KC-46'
        | 'KC-135'
        | 'MQ-9'
        | 'WC-130J',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'badge' is not null or undefined
      assertParamExists(
        'fetchAllRequiredFlightTrainingCourses',
        'badge',
        badge
      );
      // verify required parameter 'mws' is not null or undefined
      assertParamExists('fetchAllRequiredFlightTrainingCourses', 'mws', mws);
      const localVarPath = `/v2/flightTrainingCourse/required`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (badge !== undefined) {
        localVarQueryParameter['badge'] = badge;
      }

      if (mws !== undefined) {
        localVarQueryParameter['mws'] = mws;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchFlightTrainingCourses: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/flightTrainingCourse`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchRequirementMaps: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/flightTrainingCourse/required/map`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingCourse: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findFlightTrainingCourse', 'id', id);
      const localVarPath = `/v2/flightTrainingCourse/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingCourses: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/flightTrainingCourse/flightTrainingCourse/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchFlightTrainingCourse: async (
      id: number,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchFlightTrainingCourse', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('patchFlightTrainingCourse', 'body', body);
      const localVarPath = `/v2/flightTrainingCourse/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchRequiredFlightTrainingCourses: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('patchRequiredFlightTrainingCourses', 'body', body);
      const localVarPath = `/v2/flightTrainingCourse/required`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FlightTrainingCourseApi - functional programming interface
 * @export
 */
export const FlightTrainingCourseApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    FlightTrainingCourseApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFlightTrainingCourse(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createFlightTrainingCourse(
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFlightTrainingCourse(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteFlightTrainingCourse(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {'Pilot' | 'CSO' | 'ABM' | 'RPA'} badge
     * @param {'A-10' | 'B-1' | 'B-52' | 'C-5' | 'C-17' | 'C-40' | 'C-130' | 'C-130J' | 'C-130H' | 'C-145' | 'C-146' | 'E-3' | 'F-16' | 'F-22' | 'F-35' | 'HH-60' | 'HC-130' | 'KC-10' | 'KC-46' | 'KC-135' | 'MQ-9' | 'WC-130J'} mws
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchAllRequiredFlightTrainingCourses(
      badge: 'Pilot' | 'CSO' | 'ABM' | 'RPA',
      mws:
        | 'A-10'
        | 'B-1'
        | 'B-52'
        | 'C-5'
        | 'C-17'
        | 'C-40'
        | 'C-130'
        | 'C-130J'
        | 'C-130H'
        | 'C-145'
        | 'C-146'
        | 'E-3'
        | 'F-16'
        | 'F-22'
        | 'F-35'
        | 'HH-60'
        | 'HC-130'
        | 'KC-10'
        | 'KC-46'
        | 'KC-135'
        | 'MQ-9'
        | 'WC-130J',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchAllRequiredFlightTrainingCourses(
          badge,
          mws,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchFlightTrainingCourses(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchFlightTrainingCourses(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchRequirementMaps(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchRequirementMaps(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findFlightTrainingCourse(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findFlightTrainingCourse(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findFlightTrainingCourses(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findFlightTrainingCourses(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchFlightTrainingCourse(
      id: number,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchFlightTrainingCourse(
          id,
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchRequiredFlightTrainingCourses(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchRequiredFlightTrainingCourses(
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * FlightTrainingCourseApi - factory interface
 * @export
 */
export const FlightTrainingCourseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FlightTrainingCourseApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFlightTrainingCourse(
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .createFlightTrainingCourse(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFlightTrainingCourse(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteFlightTrainingCourse(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'Pilot' | 'CSO' | 'ABM' | 'RPA'} badge
     * @param {'A-10' | 'B-1' | 'B-52' | 'C-5' | 'C-17' | 'C-40' | 'C-130' | 'C-130J' | 'C-130H' | 'C-145' | 'C-146' | 'E-3' | 'F-16' | 'F-22' | 'F-35' | 'HH-60' | 'HC-130' | 'KC-10' | 'KC-46' | 'KC-135' | 'MQ-9' | 'WC-130J'} mws
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAllRequiredFlightTrainingCourses(
      badge: 'Pilot' | 'CSO' | 'ABM' | 'RPA',
      mws:
        | 'A-10'
        | 'B-1'
        | 'B-52'
        | 'C-5'
        | 'C-17'
        | 'C-40'
        | 'C-130'
        | 'C-130J'
        | 'C-130H'
        | 'C-145'
        | 'C-146'
        | 'E-3'
        | 'F-16'
        | 'F-22'
        | 'F-35'
        | 'HH-60'
        | 'HC-130'
        | 'KC-10'
        | 'KC-46'
        | 'KC-135'
        | 'MQ-9'
        | 'WC-130J',
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .fetchAllRequiredFlightTrainingCourses(badge, mws, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchFlightTrainingCourses(options?: any): AxiosPromise<Array<object>> {
      return localVarFp
        .fetchFlightTrainingCourses(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchRequirementMaps(options?: any): AxiosPromise<Array<object>> {
      return localVarFp
        .fetchRequirementMaps(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingCourse(id: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .findFlightTrainingCourse(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingCourses(options?: any): AxiosPromise<object> {
      return localVarFp
        .findFlightTrainingCourses(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchFlightTrainingCourse(
      id: number,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .patchFlightTrainingCourse(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchRequiredFlightTrainingCourses(
      body: object,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .patchRequiredFlightTrainingCourses(body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * FlightTrainingCourseApi - object-oriented interface
 * @export
 * @class FlightTrainingCourseApi
 * @extends {BaseAPI}
 */
export class FlightTrainingCourseApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public createFlightTrainingCourse(
    body: object,
    options?: AxiosRequestConfig
  ) {
    return FlightTrainingCourseApiFp(this.configuration)
      .createFlightTrainingCourse(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public deleteFlightTrainingCourse(id: number, options?: AxiosRequestConfig) {
    return FlightTrainingCourseApiFp(this.configuration)
      .deleteFlightTrainingCourse(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'Pilot' | 'CSO' | 'ABM' | 'RPA'} badge
   * @param {'A-10' | 'B-1' | 'B-52' | 'C-5' | 'C-17' | 'C-40' | 'C-130' | 'C-130J' | 'C-130H' | 'C-145' | 'C-146' | 'E-3' | 'F-16' | 'F-22' | 'F-35' | 'HH-60' | 'HC-130' | 'KC-10' | 'KC-46' | 'KC-135' | 'MQ-9' | 'WC-130J'} mws
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public fetchAllRequiredFlightTrainingCourses(
    badge: 'Pilot' | 'CSO' | 'ABM' | 'RPA',
    mws:
      | 'A-10'
      | 'B-1'
      | 'B-52'
      | 'C-5'
      | 'C-17'
      | 'C-40'
      | 'C-130'
      | 'C-130J'
      | 'C-130H'
      | 'C-145'
      | 'C-146'
      | 'E-3'
      | 'F-16'
      | 'F-22'
      | 'F-35'
      | 'HH-60'
      | 'HC-130'
      | 'KC-10'
      | 'KC-46'
      | 'KC-135'
      | 'MQ-9'
      | 'WC-130J',
    options?: AxiosRequestConfig
  ) {
    return FlightTrainingCourseApiFp(this.configuration)
      .fetchAllRequiredFlightTrainingCourses(badge, mws, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public fetchFlightTrainingCourses(options?: AxiosRequestConfig) {
    return FlightTrainingCourseApiFp(this.configuration)
      .fetchFlightTrainingCourses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public fetchRequirementMaps(options?: AxiosRequestConfig) {
    return FlightTrainingCourseApiFp(this.configuration)
      .fetchRequirementMaps(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public findFlightTrainingCourse(id: number, options?: AxiosRequestConfig) {
    return FlightTrainingCourseApiFp(this.configuration)
      .findFlightTrainingCourse(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public findFlightTrainingCourses(options?: AxiosRequestConfig) {
    return FlightTrainingCourseApiFp(this.configuration)
      .findFlightTrainingCourses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public patchFlightTrainingCourse(
    id: number,
    body: object,
    options?: AxiosRequestConfig
  ) {
    return FlightTrainingCourseApiFp(this.configuration)
      .patchFlightTrainingCourse(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingCourseApi
   */
  public patchRequiredFlightTrainingCourses(
    body: object,
    options?: AxiosRequestConfig
  ) {
    return FlightTrainingCourseApiFp(this.configuration)
      .patchRequiredFlightTrainingCourses(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FlightTrainingProgressApi - axios parameter creator
 * @export
 */
export const FlightTrainingProgressApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingProgresses: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/flight-training-progress/flight-training-progress/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FlightTrainingProgressApi - functional programming interface
 * @export
 */
export const FlightTrainingProgressApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    FlightTrainingProgressApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findFlightTrainingProgresses(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findFlightTrainingProgresses(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * FlightTrainingProgressApi - factory interface
 * @export
 */
export const FlightTrainingProgressApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FlightTrainingProgressApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFlightTrainingProgresses(options?: any): AxiosPromise<object> {
      return localVarFp
        .findFlightTrainingProgresses(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * FlightTrainingProgressApi - object-oriented interface
 * @export
 * @class FlightTrainingProgressApi
 * @extends {BaseAPI}
 */
export class FlightTrainingProgressApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightTrainingProgressApi
   */
  public findFlightTrainingProgresses(options?: AxiosRequestConfig) {
    return FlightTrainingProgressApiFp(this.configuration)
      .findFlightTrainingProgresses(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GainsApi - axios parameter creator
 * @export
 */
export const GainsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGains: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createGains', 'body', body);
      const localVarPath = `/v2/gains/gains`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGainsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/gains/gains/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGains: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('generateGains', 'body', body);
      const localVarPath = `/v2/gains/gains/generate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    truncateGains: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/gains/gains/truncate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GainsApi - functional programming interface
 * @export
 */
export const GainsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GainsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGains(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGains(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findGainsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findGainsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateGains(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateGains(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async truncateGains(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.truncateGains(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * GainsApi - factory interface
 * @export
 */
export const GainsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GainsApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGains(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createGains(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findGainsWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findGainsWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateGains(body: object, options?: any): AxiosPromise<void> {
      return localVarFp
        .generateGains(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    truncateGains(options?: any): AxiosPromise<void> {
      return localVarFp
        .truncateGains(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * GainsApi - object-oriented interface
 * @export
 * @class GainsApi
 * @extends {BaseAPI}
 */
export class GainsApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GainsApi
   */
  public createGains(body: object, options?: AxiosRequestConfig) {
    return GainsApiFp(this.configuration)
      .createGains(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GainsApi
   */
  public findGainsWithParams(options?: AxiosRequestConfig) {
    return GainsApiFp(this.configuration)
      .findGainsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GainsApi
   */
  public generateGains(body: object, options?: AxiosRequestConfig) {
    return GainsApiFp(this.configuration)
      .generateGains(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GainsApi
   */
  public truncateGains(options?: AxiosRequestConfig) {
    return GainsApiFp(this.configuration)
      .truncateGains(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HoldApi - axios parameter creator
 * @export
 */
export const HoldApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findHolds: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/hold/hold/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * HoldApi - functional programming interface
 * @export
 */
export const HoldApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HoldApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findHolds(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findHolds(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * HoldApi - factory interface
 * @export
 */
export const HoldApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HoldApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findHolds(options?: any): AxiosPromise<void> {
      return localVarFp
        .findHolds(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * HoldApi - object-oriented interface
 * @export
 * @class HoldApi
 * @extends {BaseAPI}
 */
export class HoldApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HoldApi
   */
  public findHolds(options?: AxiosRequestConfig) {
    return HoldApiFp(this.configuration)
      .findHolds(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ImmediateActionApi - axios parameter creator
 * @export
 */
export const ImmediateActionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findImmediateActionsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/ImmediateAction/ImmediateAction/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ImmediateActionApi - functional programming interface
 * @export
 */
export const ImmediateActionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ImmediateActionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findImmediateActionsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findImmediateActionsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * ImmediateActionApi - factory interface
 * @export
 */
export const ImmediateActionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ImmediateActionApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findImmediateActionsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findImmediateActionsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ImmediateActionApi - object-oriented interface
 * @export
 * @class ImmediateActionApi
 * @extends {BaseAPI}
 */
export class ImmediateActionApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImmediateActionApi
   */
  public findImmediateActionsWithParams(options?: AxiosRequestConfig) {
    return ImmediateActionApiFp(this.configuration)
      .findImmediateActionsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InProcessingClassApi - axios parameter creator
 * @export
 */
export const InProcessingClassApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body Create inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInProcessingClass: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createInProcessingClass', 'body', body);
      const localVarPath = `/v2/inProcessingClass`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInProcessingClass: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteInProcessingClass', 'id', id);
      const localVarPath = `/v2/inProcessingClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProcessingClass: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fetchInProcessingClass', 'id', id);
      const localVarPath = `/v2/inProcessingClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {boolean} [archived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProcessingClasses: async (
      archived?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/inProcessingClass`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (archived !== undefined) {
        localVarQueryParameter['archived'] = archived;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body Update inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchInProcessingClass: async (
      id: number,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchInProcessingClass', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('patchInProcessingClass', 'body', body);
      const localVarPath = `/v2/inProcessingClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * InProcessingClassApi - functional programming interface
 * @export
 */
export const InProcessingClassApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InProcessingClassApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body Create inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInProcessingClass(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createInProcessingClass(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInProcessingClass(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteInProcessingClass(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchInProcessingClass(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchInProcessingClass(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {boolean} [archived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchInProcessingClasses(
      archived?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchInProcessingClasses(
          archived,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {object} body Update inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchInProcessingClass(
      id: number,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchInProcessingClass(
          id,
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * InProcessingClassApi - factory interface
 * @export
 */
export const InProcessingClassApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InProcessingClassApiFp(configuration);
  return {
    /**
     *
     * @param {object} body Create inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInProcessingClass(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createInProcessingClass(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInProcessingClass(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteInProcessingClass(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProcessingClass(id: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .fetchInProcessingClass(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [archived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProcessingClasses(
      archived?: boolean,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .fetchInProcessingClasses(archived, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body Update inProcessingClass dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchInProcessingClass(
      id: number,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .patchInProcessingClass(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * InProcessingClassApi - object-oriented interface
 * @export
 * @class InProcessingClassApi
 * @extends {BaseAPI}
 */
export class InProcessingClassApi extends BaseAPI {
  /**
   *
   * @param {object} body Create inProcessingClass dto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InProcessingClassApi
   */
  public createInProcessingClass(body: object, options?: AxiosRequestConfig) {
    return InProcessingClassApiFp(this.configuration)
      .createInProcessingClass(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InProcessingClassApi
   */
  public deleteInProcessingClass(id: number, options?: AxiosRequestConfig) {
    return InProcessingClassApiFp(this.configuration)
      .deleteInProcessingClass(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InProcessingClassApi
   */
  public fetchInProcessingClass(id: number, options?: AxiosRequestConfig) {
    return InProcessingClassApiFp(this.configuration)
      .fetchInProcessingClass(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [archived]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InProcessingClassApi
   */
  public fetchInProcessingClasses(
    archived?: boolean,
    options?: AxiosRequestConfig
  ) {
    return InProcessingClassApiFp(this.configuration)
      .fetchInProcessingClasses(archived, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {object} body Update inProcessingClass dto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InProcessingClassApi
   */
  public patchInProcessingClass(
    id: number,
    body: object,
    options?: AxiosRequestConfig
  ) {
    return InProcessingClassApiFp(this.configuration)
      .patchInProcessingClass(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * IngestionApi - axios parameter creator
 * @export
 */
export const IngestionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestApplicants: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/ingestion/parse/applicants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestDcrCsvData: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/ingestion/parse/dcr`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestTlnCsvData: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/ingestion/parse/tln`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * IngestionApi - functional programming interface
 * @export
 */
export const IngestionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    IngestionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ingestApplicants(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ingestApplicants(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ingestDcrCsvData(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ingestDcrCsvData(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ingestTlnCsvData(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ingestTlnCsvData(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * IngestionApi - factory interface
 * @export
 */
export const IngestionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = IngestionApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestApplicants(options?: any): AxiosPromise<Array<object>> {
      return localVarFp
        .ingestApplicants(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestDcrCsvData(options?: any): AxiosPromise<void> {
      return localVarFp
        .ingestDcrCsvData(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestTlnCsvData(options?: any): AxiosPromise<void> {
      return localVarFp
        .ingestTlnCsvData(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * IngestionApi - object-oriented interface
 * @export
 * @class IngestionApi
 * @extends {BaseAPI}
 */
export class IngestionApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IngestionApi
   */
  public ingestApplicants(options?: AxiosRequestConfig) {
    return IngestionApiFp(this.configuration)
      .ingestApplicants(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IngestionApi
   */
  public ingestDcrCsvData(options?: AxiosRequestConfig) {
    return IngestionApiFp(this.configuration)
      .ingestDcrCsvData(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IngestionApi
   */
  public ingestTlnCsvData(options?: AxiosRequestConfig) {
    return IngestionApiFp(this.configuration)
      .ingestTlnCsvData(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InproApi - axios parameter creator
 * @export
 */
export const InproApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findInPros: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/inpro/inpro/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * InproApi - functional programming interface
 * @export
 */
export const InproApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InproApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findInPros(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findInPros(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * InproApi - factory interface
 * @export
 */
export const InproApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InproApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findInPros(options?: any): AxiosPromise<object> {
      return localVarFp
        .findInPros(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * InproApi - object-oriented interface
 * @export
 * @class InproApi
 * @extends {BaseAPI}
 */
export class InproApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InproApi
   */
  public findInPros(options?: AxiosRequestConfig) {
    return InproApiFp(this.configuration)
      .findInPros(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateLogDto} createLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createLogDto: CreateLogDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createLogDto' is not null or undefined
      assertParamExists('create', 'createLogDto', createLogDto);
      const localVarPath = `/v2/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLogDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} candidateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByCandidate: async (
      candidateId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'candidateId' is not null or undefined
      assertParamExists('findByCandidate', 'candidateId', candidateId);
      const localVarPath = `/v2/log/candidate/{candidateId}`.replace(
        `{${'candidateId'}}`,
        encodeURIComponent(String(candidateId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findLogs: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findLogsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/log/log/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeLog: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeLog', 'id', id);
      const localVarPath = `/v2/log/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateLogDto} updateLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: string,
      updateLogDto: UpdateLogDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('update', 'id', id);
      // verify required parameter 'updateLogDto' is not null or undefined
      assertParamExists('update', 'updateLogDto', updateLogDto);
      const localVarPath = `/v2/log/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLogDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateLogDto} createLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createLogDto: CreateLogDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createLogDto,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} candidateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findByCandidate(
      candidateId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findByCandidate(
        candidateId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findLogs(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findLogs(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findLogsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findLogsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeLog(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeLog(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {UpdateLogDto} updateLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: string,
      updateLogDto: UpdateLogDto,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(
        id,
        updateLogDto,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LogApiFp(configuration);
  return {
    /**
     *
     * @param {CreateLogDto} createLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createLogDto: CreateLogDto, options?: any): AxiosPromise<object> {
      return localVarFp
        .create(createLogDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} candidateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByCandidate(candidateId: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .findByCandidate(candidateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findLogs(options?: any): AxiosPromise<Array<object>> {
      return localVarFp
        .findLogs(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findLogsWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findLogsWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeLog(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeLog(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateLogDto} updateLogDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      id: string,
      updateLogDto: UpdateLogDto,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .update(id, updateLogDto, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
  /**
   *
   * @param {CreateLogDto} createLogDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public create(createLogDto: CreateLogDto, options?: AxiosRequestConfig) {
    return LogApiFp(this.configuration)
      .create(createLogDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} candidateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public findByCandidate(candidateId: string, options?: AxiosRequestConfig) {
    return LogApiFp(this.configuration)
      .findByCandidate(candidateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public findLogs(options?: AxiosRequestConfig) {
    return LogApiFp(this.configuration)
      .findLogs(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public findLogsWithParams(options?: AxiosRequestConfig) {
    return LogApiFp(this.configuration)
      .findLogsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public removeLog(id: string, options?: AxiosRequestConfig) {
    return LogApiFp(this.configuration)
      .removeLog(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateLogDto} updateLogDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogApi
   */
  public update(
    id: string,
    updateLogDto: UpdateLogDto,
    options?: AxiosRequestConfig
  ) {
    return LogApiFp(this.configuration)
      .update(id, updateLogDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OTSApi - axios parameter creator
 * @export
 */
export const OTSApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOtsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/OTS/OTS/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OTSApi - functional programming interface
 * @export
 */
export const OTSApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OTSApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOtsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findOtsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * OTSApi - factory interface
 * @export
 */
export const OTSApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OTSApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOtsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findOtsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OTSApi - object-oriented interface
 * @export
 * @class OTSApi
 * @extends {BaseAPI}
 */
export class OTSApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OTSApi
   */
  public findOtsWithParams(options?: AxiosRequestConfig) {
    return OTSApiFp(this.configuration)
      .findOtsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OfficerTrainingSchoolClassApi - axios parameter creator
 * @export
 */
export const OfficerTrainingSchoolClassApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOfficerTrainingSchoolClass: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createOfficerTrainingSchoolClass', 'body', body);
      const localVarPath = `/v2/officerTrainingSchoolClass`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOfficerTrainingSchoolClass: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteOfficerTrainingSchoolClass', 'id', id);
      const localVarPath = `/v2/officerTrainingSchoolClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClass: async (
      id: number,
      withCandidates?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findOfficerTrainingSchoolClass', 'id', id);
      const localVarPath = `/v2/officerTrainingSchoolClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withCandidates !== undefined) {
        localVarQueryParameter['withCandidates'] = withCandidates;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClasses: async (
      withCandidates?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/officerTrainingSchoolClass`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withCandidates !== undefined) {
        localVarQueryParameter['withCandidates'] = withCandidates;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClassesWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/officerTrainingSchoolClass/officerTrainingSchoolClass/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOfficerTrainingSchoolClass: async (
      id: number,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('patchOfficerTrainingSchoolClass', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('patchOfficerTrainingSchoolClass', 'body', body);
      const localVarPath = `/v2/officerTrainingSchoolClass/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OfficerTrainingSchoolClassApi - functional programming interface
 * @export
 */
export const OfficerTrainingSchoolClassApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    OfficerTrainingSchoolClassApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOfficerTrainingSchoolClass(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOfficerTrainingSchoolClass(
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOfficerTrainingSchoolClass(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOfficerTrainingSchoolClass(
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOfficerTrainingSchoolClass(
      id: number,
      withCandidates?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findOfficerTrainingSchoolClass(
          id,
          withCandidates,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOfficerTrainingSchoolClasses(
      withCandidates?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findOfficerTrainingSchoolClasses(
          withCandidates,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOfficerTrainingSchoolClassesWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findOfficerTrainingSchoolClassesWithParams(
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOfficerTrainingSchoolClass(
      id: number,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOfficerTrainingSchoolClass(
          id,
          body,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * OfficerTrainingSchoolClassApi - factory interface
 * @export
 */
export const OfficerTrainingSchoolClassApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OfficerTrainingSchoolClassApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOfficerTrainingSchoolClass(
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .createOfficerTrainingSchoolClass(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOfficerTrainingSchoolClass(
      id: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOfficerTrainingSchoolClass(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClass(
      id: number,
      withCandidates?: boolean,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .findOfficerTrainingSchoolClass(id, withCandidates, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClasses(
      withCandidates?: boolean,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .findOfficerTrainingSchoolClasses(withCandidates, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOfficerTrainingSchoolClassesWithParams(
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .findOfficerTrainingSchoolClassesWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOfficerTrainingSchoolClass(
      id: number,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .patchOfficerTrainingSchoolClass(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OfficerTrainingSchoolClassApi - object-oriented interface
 * @export
 * @class OfficerTrainingSchoolClassApi
 * @extends {BaseAPI}
 */
export class OfficerTrainingSchoolClassApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public createOfficerTrainingSchoolClass(
    body: object,
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .createOfficerTrainingSchoolClass(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public deleteOfficerTrainingSchoolClass(
    id: number,
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .deleteOfficerTrainingSchoolClass(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {boolean} [withCandidates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public findOfficerTrainingSchoolClass(
    id: number,
    withCandidates?: boolean,
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .findOfficerTrainingSchoolClass(id, withCandidates, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [withCandidates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public findOfficerTrainingSchoolClasses(
    withCandidates?: boolean,
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .findOfficerTrainingSchoolClasses(withCandidates, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public findOfficerTrainingSchoolClassesWithParams(
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .findOfficerTrainingSchoolClassesWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficerTrainingSchoolClassApi
   */
  public patchOfficerTrainingSchoolClass(
    id: number,
    body: object,
    options?: AxiosRequestConfig
  ) {
    return OfficerTrainingSchoolClassApiFp(this.configuration)
      .patchOfficerTrainingSchoolClass(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrdersEndApi - axios parameter creator
 * @export
 */
export const OrdersEndApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOrdersEndsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/ordersEnd/ordersEnd/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OrdersEndApi - functional programming interface
 * @export
 */
export const OrdersEndApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrdersEndApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOrdersEndsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findOrdersEndsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * OrdersEndApi - factory interface
 * @export
 */
export const OrdersEndApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrdersEndApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOrdersEndsWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findOrdersEndsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OrdersEndApi - object-oriented interface
 * @export
 * @class OrdersEndApi
 * @extends {BaseAPI}
 */
export class OrdersEndApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersEndApi
   */
  public findOrdersEndsWithParams(options?: AxiosRequestConfig) {
    return OrdersEndApiFp(this.configuration)
      .findOrdersEndsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OutproApi - axios parameter creator
 * @export
 */
export const OutproApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOutPros: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/outpro/outpro/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OutproApi - functional programming interface
 * @export
 */
export const OutproApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OutproApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOutPros(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findOutPros(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * OutproApi - factory interface
 * @export
 */
export const OutproApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OutproApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOutPros(options?: any): AxiosPromise<object> {
      return localVarFp
        .findOutPros(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OutproApi - object-oriented interface
 * @export
 * @class OutproApi
 * @extends {BaseAPI}
 */
export class OutproApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OutproApi
   */
  public findOutPros(options?: AxiosRequestConfig) {
    return OutproApiFp(this.configuration)
      .findOutPros(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SECApi - axios parameter creator
 * @export
 */
export const SECApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSECsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/SEC/SEC/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SECApi - functional programming interface
 * @export
 */
export const SECApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SECApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSECsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findSECsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * SECApi - factory interface
 * @export
 */
export const SECApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SECApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSECsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findSECsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * SECApi - object-oriented interface
 * @export
 * @class SECApi
 * @extends {BaseAPI}
 */
export class SECApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SECApi
   */
  public findSECsWithParams(options?: AxiosRequestConfig) {
    return SECApiFp(this.configuration)
      .findSECsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SectionApi - axios parameter creator
 * @export
 */
export const SectionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSection: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createSection', 'body', body);
      const localVarPath = `/v2/section`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSection: async (
      id: string,
      relations: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findSection', 'id', id);
      // verify required parameter 'relations' is not null or undefined
      assertParamExists('findSection', 'relations', relations);
      const localVarPath = `/v2/section/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (relations) {
        localVarQueryParameter['relations'] = relations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSections: async (
      relations: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'relations' is not null or undefined
      assertParamExists('findSections', 'relations', relations);
      const localVarPath = `/v2/section`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (relations) {
        localVarQueryParameter['relations'] = relations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSection: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeSection', 'id', id);
      const localVarPath = `/v2/section/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSection: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSection', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateSection', 'body', body);
      const localVarPath = `/v2/section/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SectionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSection(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSection(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSection(
      id: string,
      relations: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSection(
        id,
        relations,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSections(
      relations: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSections(
        relations,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeSection(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeSection(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSection(
      id: string,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSection(
        id,
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SectionApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSection(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createSection(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSection(
      id: string,
      relations: Array<string>,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .findSection(id, relations, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} relations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSections(
      relations: Array<string>,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .findSections(relations, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSection(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeSection(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSection(
      id: string,
      body: object,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateSection(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public createSection(body: object, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .createSection(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {Array<string>} relations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public findSection(
    id: string,
    relations: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return SectionApiFp(this.configuration)
      .findSection(id, relations, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} relations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public findSections(relations: Array<string>, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .findSections(relations, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public removeSection(id: string, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .removeSection(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectionApi
   */
  public updateSection(id: string, body: object, options?: AxiosRequestConfig) {
    return SectionApiFp(this.configuration)
      .updateSection(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SponsoringUnitApi - axios parameter creator
 * @export
 */
export const SponsoringUnitApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id);
      const localVarPath = `/v2/sponsoringUnit/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSponsoringUnit: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createSponsoringUnit', 'body', body);
      const localVarPath = `/v2/sponsoringUnit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAllSponsoringUnits: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/sponsoringUnit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSponsoringUnit: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fetchSponsoringUnit', 'id', id);
      const localVarPath = `/v2/sponsoringUnit/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSponsoringUnits: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/sponsoringUnit/sponsoringUnit/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('restore', 'id', id);
      const localVarPath = `/v2/sponsoringUnit/{id}/restore`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSponsoringUnit: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSponsoringUnit', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateSponsoringUnit', 'body', body);
      const localVarPath = `/v2/sponsoringUnit/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SponsoringUnitApi - functional programming interface
 * @export
 */
export const SponsoringUnitApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SponsoringUnitApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSponsoringUnit(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSponsoringUnit(body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchAllSponsoringUnits(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchAllSponsoringUnits(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchSponsoringUnit(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchSponsoringUnit(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSponsoringUnits(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findSponsoringUnits(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restore(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restore(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSponsoringUnit(
      id: string,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSponsoringUnit(id, body, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * SponsoringUnitApi - factory interface
 * @export
 */
export const SponsoringUnitApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SponsoringUnitApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        ._delete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSponsoringUnit(body: object, options?: any): AxiosPromise<void> {
      return localVarFp
        .createSponsoringUnit(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAllSponsoringUnits(options?: any): AxiosPromise<void> {
      return localVarFp
        .fetchAllSponsoringUnits(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchSponsoringUnit(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .fetchSponsoringUnit(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSponsoringUnits(options?: any): AxiosPromise<object> {
      return localVarFp
        .findSponsoringUnits(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .restore(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSponsoringUnit(
      id: string,
      body: object,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateSponsoringUnit(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * SponsoringUnitApi - object-oriented interface
 * @export
 * @class SponsoringUnitApi
 * @extends {BaseAPI}
 */
export class SponsoringUnitApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      ._delete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public createSponsoringUnit(body: object, options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      .createSponsoringUnit(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public fetchAllSponsoringUnits(options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      .fetchAllSponsoringUnits(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public fetchSponsoringUnit(id: number, options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      .fetchSponsoringUnit(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public findSponsoringUnits(options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      .findSponsoringUnits(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public restore(id: string, options?: AxiosRequestConfig) {
    return SponsoringUnitApiFp(this.configuration)
      .restore(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SponsoringUnitApi
   */
  public updateSponsoringUnit(
    id: string,
    body: object,
    options?: AxiosRequestConfig
  ) {
    return SponsoringUnitApiFp(this.configuration)
      .updateSponsoringUnit(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaff: async (
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createStaff', 'body', body);
      const localVarPath = `/v2/staff`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaff: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findStaff', 'id', id);
      const localVarPath = `/v2/staff/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaffWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/staff/staff/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {'recruiter' | 'unit-representative'} [staffType]
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaffs: async (
      staffType?: 'recruiter' | 'unit-representative',
      withCandidates?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/staff`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (staffType !== undefined) {
        localVarQueryParameter['staffType'] = staffType;
      }

      if (withCandidates !== undefined) {
        localVarQueryParameter['withCandidates'] = withCandidates;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStaff: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeStaff', 'id', id);
      const localVarPath = `/v2/staff/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStaff: async (
      id: string,
      body: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateStaff', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateStaff', 'body', body);
      const localVarPath = `/v2/staff/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStaff(
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createStaff(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findStaff(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findStaff(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findStaffWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findStaffWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {'recruiter' | 'unit-representative'} [staffType]
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findStaffs(
      staffType?: 'recruiter' | 'unit-representative',
      withCandidates?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findStaffs(
        staffType,
        withCandidates,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeStaff(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeStaff(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStaff(
      id: string,
      body: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStaff(
        id,
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StaffApiFp(configuration);
  return {
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaff(body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .createStaff(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaff(id: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .findStaff(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaffWithParams(options?: any): AxiosPromise<object> {
      return localVarFp
        .findStaffWithParams(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'recruiter' | 'unit-representative'} [staffType]
     * @param {boolean} [withCandidates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStaffs(
      staffType?: 'recruiter' | 'unit-representative',
      withCandidates?: boolean,
      options?: any
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .findStaffs(staffType, withCandidates, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeStaff(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeStaff(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStaff(id: string, body: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .updateStaff(id, body, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public createStaff(body: object, options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .createStaff(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public findStaff(id: string, options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .findStaff(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public findStaffWithParams(options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .findStaffWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'recruiter' | 'unit-representative'} [staffType]
   * @param {boolean} [withCandidates]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public findStaffs(
    staffType?: 'recruiter' | 'unit-representative',
    withCandidates?: boolean,
    options?: AxiosRequestConfig
  ) {
    return StaffApiFp(this.configuration)
      .findStaffs(staffType, withCandidates, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public removeStaff(id: string, options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .removeStaff(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaffApi
   */
  public updateStaff(id: string, body: object, options?: AxiosRequestConfig) {
    return StaffApiFp(this.configuration)
      .updateStaff(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StartpayApi - axios parameter creator
 * @export
 */
export const StartpayApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStartPays: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/startpay/startpay/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * StartpayApi - functional programming interface
 * @export
 */
export const StartpayApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StartpayApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findStartPays(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findStartPays(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * StartpayApi - factory interface
 * @export
 */
export const StartpayApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StartpayApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStartPays(options?: any): AxiosPromise<object> {
      return localVarFp
        .findStartPays(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * StartpayApi - object-oriented interface
 * @export
 * @class StartpayApi
 * @extends {BaseAPI}
 */
export class StartpayApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StartpayApi
   */
  public findStartPays(options?: AxiosRequestConfig) {
    return StartpayApiFp(this.configuration)
      .findStartPays(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TlnImportLogV2Api - axios parameter creator
 * @export
 */
export const TlnImportLogV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    find: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/tln-import-log/tln-import-log/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * TlnImportLogV2Api - functional programming interface
 * @export
 */
export const TlnImportLogV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TlnImportLogV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async find(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.find(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * TlnImportLogV2Api - factory interface
 * @export
 */
export const TlnImportLogV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TlnImportLogV2ApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    find(options?: any): AxiosPromise<void> {
      return localVarFp
        .find(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * TlnImportLogV2Api - object-oriented interface
 * @export
 * @class TlnImportLogV2Api
 * @extends {BaseAPI}
 */
export class TlnImportLogV2Api extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TlnImportLogV2Api
   */
  public find(options?: AxiosRequestConfig) {
    return TlnImportLogV2ApiFp(this.configuration)
      .find(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TlnRowLogV2Api - axios parameter creator
 * @export
 */
export const TlnRowLogV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTlnRowLogs: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/tln-row-log/tln-row-log/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * TlnRowLogV2Api - functional programming interface
 * @export
 */
export const TlnRowLogV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TlnRowLogV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findTlnRowLogs(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findTlnRowLogs(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * TlnRowLogV2Api - factory interface
 * @export
 */
export const TlnRowLogV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TlnRowLogV2ApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTlnRowLogs(options?: any): AxiosPromise<object> {
      return localVarFp
        .findTlnRowLogs(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * TlnRowLogV2Api - object-oriented interface
 * @export
 * @class TlnRowLogV2Api
 * @extends {BaseAPI}
 */
export class TlnRowLogV2Api extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TlnRowLogV2Api
   */
  public findTlnRowLogs(options?: AxiosRequestConfig) {
    return TlnRowLogV2ApiFp(this.configuration)
      .findTlnRowLogs(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransitionApi - axios parameter creator
 * @export
 */
export const TransitionApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTransitionsWithParams: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/transition/transition/find`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * TransitionApi - functional programming interface
 * @export
 */
export const TransitionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TransitionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findTransitionsWithParams(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findTransitionsWithParams(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    }
  };
};

/**
 * TransitionApi - factory interface
 * @export
 */
export const TransitionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TransitionApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findTransitionsWithParams(options?: any): AxiosPromise<void> {
      return localVarFp
        .findTransitionsWithParams(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * TransitionApi - object-oriented interface
 * @export
 * @class TransitionApi
 * @extends {BaseAPI}
 */
export class TransitionApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransitionApi
   */
  public findTransitionsWithParams(options?: AxiosRequestConfig) {
    return TransitionApiFp(this.configuration)
      .findTransitionsWithParams(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
