export enum EPriorServiceComponent {
  REG_AF = 'Reg AF',
  AFR = 'AFR',
  ANG = 'ANG',
  ARMY_AD = 'Army AD',
  ARMY_RES = 'Army Res',
  ARMY_NG = 'Army NG',
  NAVY_AD = 'Navy AD',
  NAVY_RES = 'Navy Res',
  USMC_AD = 'USMC AD',
  USMC_RES = 'USMC Res',
  COAST_GUARD = 'Coast Guard',
  SPACE_FORCE = 'Space Force'
}
