var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _vm.label
        ? _c("label-component", { staticClass: "flex field-labels items-end" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "relative flex items-center w-full leading-tight bg-white border rounded focus:outline-none focus:shadow-outline h-9",
          class: {
            "border-red-500": _vm.showError,
          },
          attrs: { "data-cy": "dropDownComponent" },
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dropdownValue,
                  expression: "dropdownValue",
                },
              ],
              staticClass:
                "block w-full pl-3 pr-8 text-sm appearance-none focus:outline-none",
              class: {
                "text-gray-500": _vm.dropdownValue === "",
                "text-gray-700": _vm.dropdownValue,
              },
              attrs: {
                "data-cy": "dropDownComponentSelector",
                name: _vm.value,
                disabled: _vm.readonly,
              },
              on: {
                blur: _vm.onBlur,
                click: function ($event) {
                  $event.stopPropagation()
                },
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.dropdownValue = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c(
                "option",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFirstOption,
                      expression: "showFirstOption",
                    },
                  ],
                  attrs: { disabled: _vm.dropdownValue === "", selected: "" },
                  domProps: { value: _vm.defaultOption },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.defaultOption || _vm.dynamicPlaceholder) +
                      " "
                  ),
                ]
              ),
              _vm._l(_vm.values, function (item, index) {
                return _c(
                  "option",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.disabled || !_vm.hideDisabledOptions,
                        expression: "!item.disabled || !hideDisabledOptions",
                      },
                    ],
                    key: index,
                    attrs: { disabled: item.disabled },
                    domProps: { value: _vm.getValue(item) },
                  },
                  [_vm._v(" " + _vm._s(item.label || item) + " ")]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "absolute inset-y-0 right-0 flex items-center justify-center px-2 text-gray-600 pointer-events-none",
            },
            [
              _c("icon-component", {
                attrs: {
                  name: "chevronDown",
                  fill: "black",
                  height: 24,
                  width: 24,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.showError
        ? _c(
            "div",
            { staticClass: "mt-2 text-xs leading-tight text-red-500" },
            [_vm._v(" " + _vm._s(_vm.errorText) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }