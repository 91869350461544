import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../types';
import firebase from 'firebase';
import { User } from '@/models/Entities/User';

export const getters: GetterTree<UserState, RootState> = {
  currentUser(state): User | null {
    return state.currentUser;
  },
  firebaseUser(state): firebase.User | null {
    return state.firebaseUser;
  },
  isLoggedIn(state): boolean {
    return state.isLoggedIn;
  },
  isEmailVerified(state): boolean {
    return state.isEmailVerified;
  },
  users(state): User[] {
    return state.users;
  }
};
