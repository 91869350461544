/**
 * @param  {string} value
 * @returns Date | null
 * Will take in a date string and convert it to a local date
 *
 * If a date uses dashes instead of forward slashes, they will be converted
 * to forward slashes.
 *
 * NOTE: This function exists because javascript dates will convert the date using timezones
 * when the date contains dashes. This has lead to instances where the date is off
 * by a day.
 */
export function convertDateStringWithDashesToDateStringWithForwardSlashes(
  value: string
): Date | null {
  if (value && typeof value === 'string') {
    return new Date(value.replace(/-/g, '/'));
  } else if (value) {
    return new Date(value);
  } else {
    return null;
  }
}
