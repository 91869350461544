// comprehensive grades from different service branches
export enum EServiceGrades {
  E_1 = 'E-1',
  E_2 = 'E-2',
  E_3 = 'E-3',
  E_4 = 'E-4',
  E_5 = 'E-5',
  E_6 = 'E-6',
  E_7 = 'E-7',
  E_8 = 'E-8',
  E_9 = 'E-9',
  W_1 = 'W-1',
  W_2 = 'W-2',
  W_3 = 'W-3',
  W_4 = 'W-4',
  W_5 = 'W-5',
  O_1 = 'O-1',
  O_2 = 'O-2',
  O_3 = 'O-3',
  O_4 = 'O-4',
  O_5 = 'O-5',
  O_10 = 'O-10', // General, client expressed it can be removed
  ROTC = 'ROTC'
}
