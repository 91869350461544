var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { showArrow: false, title: "Create an email account" } },
        [
          _c(
            "div",
            { staticClass: "flex-col justify-center" },
            [
              _c("icon-component", {
                staticClass: "mx-auto mb-14",
                attrs: { name: "emailSent", width: 67, height: 50 },
              }),
              _c(
                "div",
                { staticClass: "mx-8 mt-8 text-center" },
                [
                  _c("p", [
                    _vm._v("To ensure account security, we sent you a"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "confirmation email. Click the link in the email to"
                    ),
                  ]),
                  _c("p", [_vm._v("complete your account.")]),
                  _vm.customErrorMessage != ""
                    ? _c("login-form-error-message-component", {
                        attrs: { message: _vm.customErrorMessage },
                      })
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-10 py-2 my-8 font-bold text-white bg-black rounded",
                      attrs: { "data-cy": "backToHomeButton" },
                      on: { click: _vm.handleOkSubmit },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }