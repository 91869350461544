import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleQuestion,
  faClipboard,
  faTrashCan
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowUp,
  faCheck,
  faCircleInfo,
  faEnvelope,
  faEye,
  faEyeSlash,
  faRotate,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VueConstructor } from 'vue/types/umd';

//this is where you will add more icons in the future
const icons = [
  faEye,
  faClipboard,
  faCheck,
  faRotate,
  faEnvelope,
  faEyeSlash,
  faTriangleExclamation,
  faTrashCan,
  faCircleQuestion,
  faCircleInfo,
  faArrowUp
];

export const init = (vue: VueConstructor): void => {
  library.add(...icons);
  vue.component('font-awesome-icon', FontAwesomeIcon);
};
