
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Mixins
} from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import wait from '@/util/wait';
import SnackbarMixin from '@/mixins/snackbarMixin';
import Regex from '@/models/Forms/Regex';

@Component<SocialSecurityComponent>({
  components: { FontAwesomeIcon }
})
export default class SocialSecurityComponent extends Mixins(
  Vue,
  SnackbarMixin
) {
  @Prop()
  id?: string;

  @VModel({ required: true, default: '' })
  ssn!: string;

  @Prop({
    default: 'Social'
  })
  label!: string;

  @Prop({ default: 'Please enter a valid SSN' })
  errorText!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  showCheckMark = false;

  data() {
    return {
      field1: '',
      field2: ''
    };
  }

  isNumber(evt: KeyboardEvent): void {
    if (/[a-zA-Z]/.test(evt.key)) {
      evt.preventDefault();
    }
  }

  handleJumpingCursor(evt: HTMLInputElement, keyType: string): void {
    const sel = evt.selectionStart || 0;
    this.$nextTick(() => {
      const cursorPosition =
        (sel === 4 || sel === 7) && keyType !== 'deleteContentBackward'
          ? sel + 1
          : sel;
      evt.setSelectionRange(cursorPosition, cursorPosition);
    });
  }

  get model(): string {
    if (!this.ssn) return '';
    const length = this.ssn.length;
    const val = this.ssn.trim();
    if (length < 4) return val;
    const first = val.slice(0, 3);
    const second = val.slice(3, 5);
    if (length < 6) return first + '-' + second;
    const third = val.slice(5, 9);
    return first + '-' + second + '-' + third;
  }

  set model(input: string) {
    const newValue = input
      .replaceAll('-', '')
      .replace(/[^0-9]/gi, '')
      .substring(0, 9);

    this.ssn = newValue;
  }

  //OLD: SSN Regex Source: https://regex101.com/r/kdXrYe/1
  //SSN Regex Source: https://ihateregex.io/expr/ssn/
  regex = Regex.socialSecurityNumber;
  isPristine = true;
  visible = false;

  get visibility(): 'text' | 'password' {
    return this.visible ? 'text' : 'password';
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid && this.required;
  }

  get isValid(): boolean {
    return (
      (!this.required && this.ssn.length === 0) || this.regex.test(this.ssn)
    );
  }

  toggleVisibility(): void {
    this.visible = !this.visible;
  }

  @Emit('blur')
  onBlur(): void {
    this.isPristine = false;
  }
  async copyToClipboard(): Promise<void> {
    navigator.clipboard.writeText('' + this.ssn);
    this.showCheckMark = true;
    await wait(1000);
    this.showCheckMark = false;
  }
}
