import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { ApplicantState } from './types';
import Bff from '@/api/bff';
import { Application } from '@/models/Entities/Application/Application';
import { Applicant } from '@/models/Entities/Personas/Applicant';

export const state: ApplicantState = {
  applicants: [],
  applicant: {} as Applicant,
  applicantLogs: [],
  applications: [],
  application: {} as Application,
  isCreatingApplication: false,
  isUpdatingApplication: false,
  isUpdating: false
};

export function create(bff: Bff): Module<ApplicantState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}
