var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleLogin.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "sign-up-form-component",
        { attrs: { title: "Sign in with personal email account" } },
        [
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-1 text-xs text-gray-600",
                attrs: { for: "email" },
              },
              [_vm._v(" Email address ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass:
                "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
              attrs: {
                "data-cy": "emailInput",
                id: "email",
                type: "text",
                placeholder: "Enter your email",
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" Enter password ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pwInput,
                    expression: "pwInput",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: {
                  "data-cy": "passwordInput",
                  id: "password",
                  type: "password",
                  placeholder: "Enter your password",
                },
                domProps: { value: _vm.pwInput },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pwInput = $event.target.value
                  },
                },
              }),
              _vm.signInFailed
                ? _c("login-form-error-message-component", {
                    attrs: { message: "Email or password is incorrect" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex items-center mt-6 justify-evenly" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    "data-cy": "signUpLink",
                    to: { name: _vm.routes.AUTH_SIGNUP },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "ml-4 mr-6 underline",
                      attrs: { "data-cy": "createAccountButton" },
                    },
                    [_vm._v(" Create an account ")]
                  ),
                ]
              ),
              _c(
                "router-link",
                {
                  attrs: {
                    "data-cy": "forgotPasswordLink",
                    to: { name: _vm.routes.AUTH_PASSWORD_FORGOT },
                  },
                },
                [
                  _c("a", { staticClass: "mr-16 underline" }, [
                    _vm._v(" I can't sign in "),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-8 py-2 font-bold text-white bg-black rounded disabled:opacity-50",
                  attrs: {
                    "data-cy": "signInButton",
                    type: "button",
                    disabled: !_vm.validForm || _vm.isProcessing,
                  },
                  on: { click: _vm.handleLogin },
                },
                [_vm._v(" Log in ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }