import { EUserRoles } from '../enums';

export type MenuItem = {
  to: string;
  label: string;
};

type EUserRolesToMenuItemsPair = {
  role: EUserRoles;
  sections: section[];
};

type section = {
  name: string;
  items: MenuItem[];
};

const menuItems = {
  admin: {
    dashboard: { label: 'Dashboard', to: '/admin/dashboard' }
  },
  recruiter: {
    applicants: { label: 'Applicants', to: '/applicant' },
    myApplicants: {
      label: 'My Applicants',
      to: '/recruiter/current'
    }
  },
  flightChief: {
    recruiter: {
      label: 'Flight Chief',
      to: '/flight-chief/recruiter'
    }
  },
  unitRepresentative: {
    current: {
      label: 'Unit Hiring Poc',
      to: '/unitRepresentative/current'
    },
    label: 'Unit Hiring PoC',
    to: '/unitRepresentative'
  },
  board: {
    current: {
      label: 'Current Board',
      to: '/A3/boards/current'
    },
    archived: {
      label: 'Archived Boards',
      to: '/A3/boards/archived'
    },
    closed: {
      label: 'Closed Boards',
      to: '/A3/boards/closed'
    }
  },
  student: {
    dashboard: { label: '340th FTG', to: '/340th/student' },
    reports: { label: 'Reports', to: '/340th/reports' }
  }
};

const headers = {
  admin: 'Admin',
  recruiting: 'Recruiting',
  selection: 'Selection',
  training: 'Training'
};

export const MenuItemsByRole: EUserRolesToMenuItemsPair[] = [
  {
    role: EUserRoles.ADMIN,
    sections: [
      {
        name: headers.admin,
        items: [menuItems.admin.dashboard]
      },
      {
        name: headers.recruiting,
        items: [
          menuItems.recruiter.applicants,
          menuItems.flightChief.recruiter,
          menuItems.unitRepresentative
        ]
      },
      {
        name: headers.selection,
        items: [
          menuItems.board.current,
          menuItems.board.archived,
          menuItems.board.closed
        ]
      },
      {
        name: headers.training,
        items: [menuItems.student.dashboard, menuItems.student.reports]
      }
    ]
  },
  {
    role: EUserRoles.FLIGHT_CHIEF,
    sections: [
      {
        name: headers.recruiting,
        items: [menuItems.recruiter.applicants, menuItems.flightChief.recruiter]
      }
    ]
  },
  {
    role: EUserRoles.MEMBER,
    sections: []
  },
  {
    role: EUserRoles.RECRUITER,
    sections: [
      {
        name: headers.recruiting,
        items: [menuItems.recruiter.myApplicants]
      }
    ]
  },
  {
    role: EUserRoles.UNIT_REPRESENTATIVE,
    sections: [
      {
        name: headers.recruiting,
        items: [menuItems.unitRepresentative.current]
      }
    ]
  },
  {
    role: EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
    sections: [
      {
        name: headers.training,
        items: [menuItems.student.dashboard]
      }
    ]
  },
  {
    role: EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
    sections: [
      {
        name: headers.training,
        items: [menuItems.student.dashboard]
      }
    ]
  },
  {
    role: EUserRoles.A3_RB,
    sections: [
      {
        name: headers.selection,
        items: [
          menuItems.board.current,
          menuItems.board.archived,
          menuItems.board.closed
        ]
      }
    ]
  },
  {
    role: EUserRoles.A3_SR_LEADER,
    sections: [
      {
        name: headers.selection,
        items: [menuItems.admin.dashboard, menuItems.board.current]
      }
    ]
  },
  {
    role: EUserRoles.READ_ONLY,
    sections: [
      {
        name: headers.admin,
        items: [menuItems.admin.dashboard]
      },
      {
        name: headers.recruiting,
        items: [
          menuItems.recruiter.applicants,
          menuItems.flightChief.recruiter,
          menuItems.unitRepresentative
        ]
      },
      {
        name: headers.selection,
        items: [menuItems.board.current, menuItems.board.archived]
      },
      {
        name: headers.training,
        items: [menuItems.student.dashboard, menuItems.student.reports]
      }
    ]
  }
];
