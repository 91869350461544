import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { MenuState } from './types';
import { RootState } from '../types';

export const state: MenuState = {
  isOpen: false,
  menuItems: []
};

export function create(): Module<MenuState, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}

export const name = 'menuModule';
