export enum EUserRoles {
  A3_RB = 'A3RB',
  A3_SR_LEADER = 'A3SrLeader',
  ADMIN = 'Admin',
  NONE = 'None',
  MEMBER = 'Member',
  RECRUITER = 'Recruiter',
  FLIGHT_CHIEF = 'FlightChief',
  THREE_40TH_FTG_SENIOR_MEMBER = '340FTGSrMem',
  THREE_40TH_FTG_JUNIOR_MEMBER = '340FTGJrMem',
  UNIT_REPRESENTATIVE = 'UnitRepresentative',
  READ_ONLY = 'ReadOnly'
}

export const DEFAULT_USER_ROLE: EUserRoles = EUserRoles.NONE;
