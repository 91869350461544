import { convertDateStringWithDashesToDateStringWithForwardSlashes } from '@/util/class-transformer/convertDateStringWithDashesToDateStringWithForwardSlashes';
import { dateDifference } from '@/util/date';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';
import { OrderStatus, OrderType } from './Constants';

export class Order {
  orderType!: OrderType;

  @Type(() => String)
  trackingNumber!: string;

  @Type(() => String)
  orderNumber!: string;

  orderStatus!: OrderStatus;

  @Transform(({ value }) =>
    convertDateStringWithDashesToDateStringWithForwardSlashes(value)
  )
  orderStartDate!: Date;

  @Transform(({ value }) =>
    convertDateStringWithDashesToDateStringWithForwardSlashes(value)
  )
  orderEndDate!: Date;

  @Type(() => BaseCandidate)
  candidate: BaseCandidate = new BaseCandidate();

  /**
   * sort orders by OrderEndDate in ascending order,meant to be used
   * with Array.sort()
   *
   * @static
   * @param {Order} a
   * @param {Order} b
   * @returns  {number}
   * @memberof Order
   */
  static sortAscendingByEndDate(a: Order, b: Order): number {
    return a.orderEndDate.getTime() - b.orderEndDate.getTime();
  }

  /**
   * sorts orders by orderEndDate in descending order,meant to be used
   * with Array.sort()
   *
   * @static
   * @param {Order} a
   * @param {Order} b
   * @returns  {number}
   * @memberof Order
   */
  static sortDescendingByEndDate(a: Order, b: Order): number {
    return b.orderEndDate.getTime() - a.orderEndDate.getTime();
  }

  get daysLeft(): number {
    const date = new Date();
    const magicNumber = 60000;
    const timezonelessDate =
      date.getTime() - date.getTimezoneOffset() * magicNumber;
    return dateDifference(new Date(timezonelessDate), this.orderEndDate);
  }

  constructor(partial?: Partial<Order>) {
    if (partial) {
      Object.assign(this, plainToClass(Order, partial));
    }
  }
}
