var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { title: "Enter a new password" } },
        [
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" New password ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pwInput,
                    expression: "pwInput",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: { type: "password", placeholder: "Create a password" },
                domProps: { value: _vm.pwInput },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pwInput = $event.target.value
                  },
                },
              }),
              _vm.passwordError
                ? _c("login-form-error-message-component", {
                    attrs: {
                      message: "Password must be at least 6 characters",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" Confirm password ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pwConfirmInput,
                    expression: "pwConfirmInput",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: { type: "password", placeholder: "Confirm password" },
                domProps: { value: _vm.pwConfirmInput },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pwConfirmInput = $event.target.value
                  },
                },
              }),
              _vm.confirmPasswordError
                ? _c("login-form-error-message-component", {
                    attrs: { message: "Passwords do not match" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex items-center justify-end mt-6 pl-36" },
            [
              _c("router-link", { attrs: { to: { name: "home" } } }, [
                _c("a", { staticClass: "ml-4 mr-6 underline" }, [
                  _vm._v(" Cancel "),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass:
                    "px-8 py-2 font-bold text-white bg-black rounded disabled:opacity-50",
                  attrs: {
                    type: "button",
                    disabled: !_vm.validForm || _vm.isProcessing,
                  },
                  on: { click: _vm.handleFormSubmit },
                },
                [_vm._v(" Reset password ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _vm.customErrorMessage != ""
                ? _c("login-form-error-message-component", {
                    staticClass: "block",
                    attrs: { message: _vm.customErrorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }