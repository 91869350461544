import { GetterTree } from 'vuex';
import { StorageState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<StorageState, RootState> = {
  file(state): string | null {
    return state.file;
  },
  files(state): string[] | null {
    return state.files;
  },
  progress(state): number {
    return state.progress;
  }
};
