var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { showArrow: false, title: _vm.title } },
        [
          _c("p", { staticClass: "pb-10 text-center" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
          _c("icon-component", {
            staticClass: "mx-auto -mb-2",
            attrs: { name: "checkmarkGlow", width: 67, height: 47 },
          }),
          _c(
            "div",
            { staticClass: "mx-8 mt-8 text-center" },
            [
              _c("router-link", { attrs: { to: { name: "home" } } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "px-10 py-2 mx-20 my-8 font-bold text-white bg-black rounded",
                  },
                  [_vm._v(" Done ")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }