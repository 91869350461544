/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from 'vuex';
import { RootState } from '../types';
import { createActions } from './actions';

export function create(): Module<any, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    actions
  };
}

export const name = 'localStorageModule';
