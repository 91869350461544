var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.canSearch
    ? _c(
        "div",
        {
          directives: [
            {
              name: "clickOutside",
              rawName: "v-clickOutside",
              value: _vm.clearInput,
              expression: "clearInput",
            },
          ],
          staticClass: "relative",
        },
        [
          _c("div", { staticClass: "relative flex items-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "absolute left-0 flex items-center pl-3 pointer-events-none",
              },
              [
                _c("icon-component", {
                  attrs: { name: "search", width: 22, height: 22 },
                }),
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInput,
                  expression: "searchInput",
                },
              ],
              staticClass:
                "w-full px-8 py-2 pl-12 border border-white rounded appearance-none bg-primary-dark md:w-80 lg:w-450 focus:outline-none focus:shadow-outline",
              attrs: { type: "text", placeholder: "Search..." },
              domProps: { value: _vm.searchInput },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchInput = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchInput !== "",
                    expression: "searchInput !== ''",
                  },
                ],
                staticClass:
                  "absolute right-0 ml-2 mr-4 font-semibold cursor-pointer",
                on: { click: _vm.clearInput },
              },
              [_vm._v(" X ")]
            ),
          ]),
          _vm.menuOpen
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute z-50 w-full mt-2 overflow-hidden text-black bg-white border rounded ext-dark",
                },
                _vm._l(_vm.results, function (result, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      staticClass:
                        "self-start block w-full px-4 py-2 text-left cursor-pointer whitespace-nowrap search-result-item hover:bg-gray-100 hover:underline",
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(result)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(result.name) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }