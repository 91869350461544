/* eslint-disable @typescript-eslint/no-explicit-any */
export function merge(obj1: any, obj2: any) {
  for (const property in obj2) {
    try {
      if (obj2[property].constructor == Object) {
        obj1[property] = merge(obj1[property], obj2[property]);
      } else {
        obj1[property] = obj2[property];
      }
    } catch (e) {
      obj1[property] = obj2[property];
    }
  }
  return obj1;
}
