import { OpenProps } from '@/components/types';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { snackBarState } from './types';

export const getters: GetterTree<snackBarState, RootState> = {
  queue(state): OpenProps[] {
    return state.queue;
  }
};
