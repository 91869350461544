import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const unitRepresentativeDetailProps = () => {
    return {
      unitRepresentative: store.getters['unitRepresentativeModule/details']
    };
  };
  const unitRepresentativeDetailComponent = () =>
    import('@/pages/UnitRepresentative/UnitRepresentativeDetailsPage.vue');

  const meta = {
    roles: [
      EUserRoles.UNIT_REPRESENTATIVE,
      EUserRoles.ADMIN,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/unitRepresentative',
      meta,
      component: () =>
        import('@/pages/UnitRepresentative/UnitRepresentativePage.vue'),
      children: [
        {
          path: '',
          name: Routes.UNIT_REPRESENTATIVE_COLLECTION,
          meta,
          component: () =>
            import(
              '@/pages/UnitRepresentative/UnitRepresentativeCollectionPage.vue'
            ),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('unitRepresentativeModule/fetchAll');
          }),
          props: () => {
            return {
              unitRepresentatives:
                store.getters['unitRepresentativeModule/collection']
            };
          }
        },
        {
          path: 'current',
          name: Routes.UNIT_REPRESENTATIVE_CURRENT,
          meta,
          component: unitRepresentativeDetailComponent,
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('unitRepresentativeModule/fetchCurrent');
          }),
          props: unitRepresentativeDetailProps
        },
        {
          path: ':id',
          name: Routes.UNIT_REPRESENTATIVE_DETAILS,
          meta,
          component: unitRepresentativeDetailComponent,
          beforeEnter: beforeEnterWrapper(async (to) => {
            await store.dispatch(
              'unitRepresentativeModule/fetch',
              to.params.id
            );
          }),
          props: unitRepresentativeDetailProps
        }
      ]
    }
  ];
};
