import Bff from '@/api/bff';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import CrudModule from '@/store/crudModule/CrudModule';
import { ICrudModule, IModuleState } from '@/store/crudModule/ICrudModule';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

export class OfficerTrainingSchoolClassModule
  extends CrudModule<OfficerTrainingSchoolClass>
  implements ICrudModule<OfficerTrainingSchoolClass>
{
  constructor(bff: Bff) {
    const path = 'officerTrainingSchoolClass';
    super(OfficerTrainingSchoolClass, bff, path);

    const customActions: ActionTree<
      IModuleState<OfficerTrainingSchoolClass>,
      RootState
    > = {};

    // Apply custom actions
    this.applyActions(customActions);
  }
}
