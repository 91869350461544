import { Board } from '@/models/Entities/Board';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { SponsoringUnit } from '../models/Entities/Student/SponsoringUnit';
import { IModal, IEditModal } from './Modals/IModal';

export class ModalMounter {
  public static instance = new ModalMounter();
  public refs!: ModalRefs;

  public init(refs: ModalRefs): void {
    ModalMounter.instance.refs = refs;
  }
}

export interface ModalRefs {
  createApplicantModal: IModal<Applicant>;
  yesNoModal: IModal<boolean>;
  createBoardModal: IModal<Board>;
  editBoardModal: IEditModal<Board>;
  sponsoringUnitModal: IEditModal<SponsoringUnit>;
  officerTrainingSchoolClassModal: IEditModal<OfficerTrainingSchoolClass>;
}
