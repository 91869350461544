import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { LoadingState } from './types';
import { DEFAULT_LOADING_MESSAGE } from '@/store/loading/constants';

export const state: LoadingState = {
  isLoading: false,
  message: DEFAULT_LOADING_MESSAGE
};

export function create(): Module<LoadingState, RootState> {
  const actions = createActions();
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}

export const name = 'loadingModule';
