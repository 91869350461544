/* eslint-disable @typescript-eslint/no-explicit-any */
import buildConfig from '@/config';
import wait from '@/util/wait';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const RETRY_STATUS_CODES = [408, 429, 502, 503, 504];

interface IApi {
  get<T = any>(url: string): Promise<T>;
  delete<T = any>(url: string): Promise<T>;
  patch<T = any>(url: string, payload: any): Promise<T>;
  post<T = any>(url: string, payload: any): Promise<T>;
  putObject(
    file: File,
    useMultipart: boolean,
    url: string,
    progressHandler?: (progressEvent: ProgressEvent) => void
  ): Promise<any>;
}

type axiosInstanceRequest =
  | AxiosInstance['get']
  | AxiosInstance['post']
  | AxiosInstance['patch']
  | AxiosInstance['put']
  | AxiosInstance['delete'];

const MAX_TIMEOUT = buildConfig.skypatch.timeout;

class Api implements IApi {
  protected instance: AxiosInstance;
  protected max_retry = 5;

  private isPayloadLessRequest(request: axiosInstanceRequest) {
    return request == this.instance.get || request == this.instance.delete;
  }

  public constructor(axiosInstance: AxiosInstance) {
    this.instance = axiosInstance;
  }

  private validError(axiosError: any) {
    return (
      RETRY_STATUS_CODES.includes(axiosError.response.status) ||
      axiosError.message === 'Network Error' ||
      axiosError.message.includes('timeout')
    );
  }

  public async attemptRequest<T = any>(
    request: axiosInstanceRequest,
    url: string,
    payload?: any,
    params?: AxiosRequestConfig,
    maxRetry = 3,
    delayMs = MAX_TIMEOUT
  ): Promise<T> {
    let retries = maxRetry;
    while (retries > 0) {
      if (!this.isPayloadLessRequest(request)) {
        retries = 1;
      }
      try {
        if (this.isPayloadLessRequest(request)) {
          const { data } = await request(url, { params });
          return data as unknown as T;
        } else {
          const { data } = await request(url, payload, { params });
          return data as unknown as T;
        }
      } catch (err: unknown) {
        const axiosErr = err as any;
        if (axiosErr.response && this.validError(axiosErr)) {
          await wait(delayMs);
          delayMs *= 2;
          --retries;
        } else if (
          axiosErr.response &&
          axiosErr.response.data &&
          axiosErr.response.data.message
        ) {
          throw Error(axiosErr.response.data.message);
        } else {
          throw err;
        }
      }
    }
    throw Error('exhausted number of retries');
  }

  public get<T = any>(url = '', params: any = {}, maxRetry?: number) {
    return this.attemptRequest<T>(
      this.instance.get,
      url,
      undefined,
      params,
      maxRetry
    );
  }

  public delete<T = any>(url = '', params: any = {}, maxRetry?: number) {
    return this.attemptRequest<T>(
      this.instance.delete,
      url,
      undefined,
      params,
      maxRetry
    );
  }

  public post<T = any>(
    url = '',
    payload: any = {},
    params: any = {},
    maxRetry?: number
  ) {
    return this.attemptRequest<T>(
      this.instance.post,
      url,
      payload,
      params,
      maxRetry
    );
  }

  public patch<T = any>(
    url = '',
    payload: any = {},
    params: any = {},
    maxRetry?: number
  ) {
    return this.attemptRequest<T>(
      this.instance.patch,
      url,
      payload,
      params,
      maxRetry
    );
  }

  public putObject(
    file: File,
    useMultipart: boolean,
    url = '',
    progressHandler?: (progressEvent: ProgressEvent) => void
  ): Promise<AxiosResponse<File>> {
    if (useMultipart) {
      const data = new FormData();
      data.append('file', file);
      const args: AxiosRequestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressHandler
      };
      return axios.put(url, data, args);
    } else {
      const args: AxiosRequestConfig = {
        headers: { 'Content-Type': file.type },
        onUploadProgress: progressHandler
      };
      return axios.put(url, file, args);
    }
  }
}

export { Api, IApi };
