import Bff from '@/api/bff';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { Application } from '@/models/Entities/Application/Application';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { ApplicationState } from './types';

export function createActions(
  bff: Bff
): ActionTree<ApplicationState, RootState> {
  return {
    async fetchAllByCandidateId(
      _,
      { candidateIds, archived = false }
    ): Promise<Application[]> {
      return bff.fetchApplications({ candidateIds, archived });
    },
    async fetchWithSelectParameters(
      _,
      query: SearchDto<Application, never>
    ): Promise<SearchResponseDto<Application>> {
      const result: SearchResponseDto<Application> = await bff.fetch<
        SearchResponseDto<Application>
      >(`applicant/application/application/find`, query);
      result.data = result.data.map((json) => {
        return new Application(json);
      });
      return result;
    }
  };
}
