/* eslint-disable @typescript-eslint/no-explicit-any */
import { LabelItems } from '@/models/Entities/types';

/**
 *
  convert a const like below. This is because we need the keys for ID scroll fields (no spaces)

  {
    MY_KEY: 'my label'
  }

  to

  {
    MY_KEY: {
      key: 'MY_KEY';
      label: 'my label'
    }
  }
 *
 * @param {*} obj
 * @returns  {LabelItems}
 */
export function convertToKeyValueObj(obj: any): LabelItems {
  const newObject: LabelItems = {};
  Object.keys(obj).forEach((key) => {
    newObject[key] = {
      key,
      label: obj[key]
    };
  });
  return newObject;
}
