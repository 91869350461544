export enum ESponsorUnits {
  UNSPONSORED = 'UNSPONSORED',
  SOS_2 = '2 SOS',
  SOS_5 = '5 SOS',
  RS_13 = '13 RS',
  ARS_18 = '18 ARS',
  RQS_39 = '39 RQS',
  FS_47 = '47 FS',
  WRS_53 = '53 WRS',
  ARS_63 = '63 ARS',
  AS_68 = '68 AS',
  ARS_70 = '70 ARS',
  ARS_72 = '72 ARS',
  AS_73 = '73 AS',
  ARS_74 = '74 ARS',
  ARS_76 = '76 ARS',
  FS_76 = '76 FS',
  ARS_77 = '77 ARS',
  ARS_78 = '78 ARS',
  AS_78 = '78 ATKS',
  ARS_79 = '79 ARS',
  AS_89 = '89 AS',
  ATKS_91 = '91 ATKS',
  FS_93 = '93 FS',
  AS_96 = '96 AS',
  AS_97 = '97 AS',
  AS_300 = '300 AS',
  AS_301 = '301 AS',
  FS_301 = '301 FS',
  RQS_301 = '301 RQS',
  FS_302 = '302 FS',
  FS_303 = '303 FS',
  RQS_305 = '305 RQS',
  AS_312 = '312 AS',
  AS_313 = '313 AS',
  ARS_314 = '314 ARS',
  AS_317 = '317 AS',
  AS_326 = '326 AS',
  AS_327 = '327 AS',
  ARS_328 = '328 ARS',
  ARS_336 = '336 ARS',
  AS_337 = '337 AS',
  BS_343 = '343 BS',
  BS_345 = '345 BS',
  AS_356 = '356 AS',
  AS_357 = '357 AS',
  FS_457 = '457 FS',
  ARS_465 = '465 ARS',
  FS_466 = '466 FS',
  AS_700 = '700 AS',
  AS_701 = '701 AS',
  AS_709 = '709 AS',
  SOS_711 = '711 SOS',
  AS_728 = '728 AS',
  AS_729 = '729 AS',
  AS_731 = '731 AS',
  AS_732 = '732 AS',
  ARS_756 = '756 ARS',
  AS_757 = '757 AS',
  AS_758 = '758 AS',
  AS_815 = '815 AS',
  SOS_859 = '859 SOS',
  ARS_905 = '905 ARS',
  ARS_924 = '924 ARS',
  AACS_970 = '970 AACS'
}
