var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.helpText !== ""
    ? _c(
        "button",
        {
          attrs: { "data-cy": "helpBubble" },
          on: {
            click: function ($event) {
              _vm.showText = !_vm.showText
            },
          },
        },
        [
          _c("font-awesome-icon", {
            directives: [
              {
                name: "clickOutside",
                rawName: "v-clickOutside",
                value: _vm.clickOutside,
                expression: "clickOutside",
              },
            ],
            staticClass: "h-3",
            attrs: { icon: ["far", "fa-circle-question"] },
          }),
          _vm.showText
            ? _c(
                "p",
                {
                  staticClass:
                    "absolute z-10 w-1/6 p-4 overflow-y-scroll text-xs text-left bg-blue-100 border-2 rounded max-h-1/2",
                  attrs: {
                    "data-cy": "floatingTextBox",
                    text: _vm.helpText,
                    readonly: true,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.helpText) + " ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }