import { DropdownOptions, RadioOptions } from '@/models/Forms/FormOption';

const createDropDownsCallback = (value: string): DropdownOptions => {
  return { label: value, value };
};

/**
 * generates a list of dropdown options from an enumerated value
 *
 * @export
 * @param {values}
 * @returns  DropDownOption[] | RadioOptions[]
 */
export const getInputOptions = (
  values: Record<string, string>
): DropdownOptions[] | RadioOptions[] => {
  return Object.values(values).map(createDropDownsCallback);
};
