
import firebase from 'firebase/app';
import 'firebase/auth';
import { Component, Mixins } from 'vue-property-decorator';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import DebounceMixin from '@/mixins/DebounceMixin';
import Routes from '@/router/Routes';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import Config from '../../config';

@Component<AccountVerificationPage>({
  components: {
    LoginFormErrorMessageComponent,
    SignUpFormComponent,
    IconComponent
  }
})
export default class AccountVerificationPage extends Mixins(DebounceMixin) {
  emailVerificationSent = false;
  handleOkSubmit = this.debounce(this.okClicked, 750);
  handleEmailResend = this.debounce(this.sendEmailConfirmation, 750);
  customErrorMessage = '';
  firebaseUser?: firebase.User | null = null;

  // left this function here in case we want to move the resend logic to a button
  async sendEmailConfirmation() {
    if (this.firebaseUser) {
      try {
        this.customErrorMessage = '';
        await this.firebaseUser.sendEmailVerification({
          url: Config.firebase.emailVerificationURL
        });
        this.emailVerificationSent = true;
      } catch (err: any) {
        this.customErrorMessage = err.message;
      }
    }
  }

  mounted() {
    // save the users info before signing them out
    const auth = firebase.auth();
    if (auth && auth.currentUser) {
      this.firebaseUser = auth.currentUser;
    }
    this.handleEmailResend();

    firebase.auth().signOut(); //force logout
  }

  private async okClicked() {
    this.$router.push({ name: Routes.AUTH_LOGIN });
  }
}
