<template lang="html">
  <section></section>
</template>
<script>
import firebase from 'firebase/app';
export default {
  name: 'logout',
  async mounted() {
    await firebase.auth().signOut();
    this.$router.push('home');
  }
};
</script>
