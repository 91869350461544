/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '@/config';
import { AuthClient } from '@/services/auth';
import { DEFAULT_LOADING_MESSAGE } from '@/store/loading/constants';
import { RootState } from '@/store/types';
import Vue from 'vue';
import VueRouter, {
  NavigationGuard,
  NavigationGuardNext,
  Route
} from 'vue-router';
import { Store } from 'vuex';
import { generateRoutes as generateA3Routes } from './a3';
import { generateRoutes as generateAdminRoutes } from './admin';
import { generateRoutes as generateApplicantRoutes } from './applicant';
import { generateRoutes as generateAuthRoutes } from './auth';
import { generateRoutes as generateFlightChiefRoutes } from './flightChief';
import { generateRoutes as generateInvitationRoutes } from './invitation';
import { generateRoutes as generateRecruiterRoutes } from './recruiter';
import { generateRoutes as generateReportRoutes } from './report';
import Routes from './Routes';
import { generateRoutes as generateStudentRoutes } from './student';
import { generateRoutes as generateUnitRepresentativeRoutes } from './unitRepresentative';
import { generateRoutes as generateLetterRoutes } from './letter';
import { generateRoutes as generateQARoutes } from './qa';

Vue.use(VueRouter);

export function beforeEnterWrapper(
  beforeEnterCallback: (to: Route, from: Route) => Promise<void>
): NavigationGuard {
  return async (to, from, next: NavigationGuardNext) => {
    try {
      await beforeEnterCallback(to, from);
      next();
    } catch (err: any) {
      next(err);
    }
  };
}

export function createRouter(store: Store<RootState>): VueRouter {
  const routes = [
    ...generateStudentRoutes(store),
    ...generateReportRoutes(store),
    ...generateA3Routes(store),
    ...generateAdminRoutes(store),
    ...generateRecruiterRoutes(store),
    ...generateFlightChiefRoutes(store),
    ...generateApplicantRoutes(store),
    ...generateUnitRepresentativeRoutes(store),
    ...generateInvitationRoutes(store),
    ...generateLetterRoutes(store),
    ...generateQARoutes(store),
    {
      path: '/home',
      name: Routes.HOME,
      component: () => import('@/pages/HomePage.vue')
    },
    {
      path: '/connect-test',
      name: Routes.CONNECT_TEST,
      component: () => import('@/pages/APITestPage.vue')
    },
    {
      path: '/newUser',
      name: Routes.NEW_USER,
      component: () => import('@/pages/NewUserPage.vue')
    },
    {
      path: '*',
      redirect: Routes.HOME
    }
  ];

  if (config.environment === 'firebase') {
    routes.push(...generateAuthRoutes(store));
  }
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });

  router.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (
        !store.getters['userModule/isLoggedIn'] &&
        config.environment === 'platform_one'
      ) {
        try {
          await AuthClient.getInstance().signIn();
        } catch (err: unknown) {
          console.log(err);
        }
      }

      if (!to.meta!.noLoading || !from.meta!.noLoading) {
        store.dispatch('loadingModule/setMessage', DEFAULT_LOADING_MESSAGE);
        store.dispatch('loadingModule/startLoading');
        store.dispatch('routerModule/setTo', to);
        store.dispatch('routerModule/setFrom', from);
        await store.dispatch('loadingModule/startLoading');
      }
      next();
    }
  );

  router.afterEach(() => {
    store.dispatch('loadingModule/stopLoading');
  });

  router.onError(() => {
    store.dispatch(
      'loadingModule/setMessage',
      'there was an error loading this page'
    );
  });
  return router;
}
