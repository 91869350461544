import { ActionTree } from 'vuex';
import { MenuState } from './types';
import { RootState } from '../types';
import { MenuItem } from '@/models/MenuItem';

export function createActions(): ActionTree<MenuState, RootState> {
  return {
    openMenu({ commit }) {
      commit('SET_IS_OPEN', true);
    },
    closeMenu({ commit }) {
      commit('SET_IS_OPEN', false);
    },
    toggleMenu({ commit, state }) {
      commit('SET_IS_OPEN', !state.isOpen);
    },
    setMenuItems({ commit }, menuItems: MenuItem[]) {
      commit('SET_MENU_ITEMS', menuItems);
    }
  };
}
