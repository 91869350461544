export const email =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const fitnessAssessment = /^(7[5-9]|[89][0-9]|100)$/;
export const gpa = /^[0-4]\.\d{1,2}$/;
export const pcsmTest = /^([0-9]|[1-8][0-9]|9[0-9])$/;
export const pftTest = /^([0-9]|[1-8][0-9]|9[0-9]|100)$/;
export const number = /^[0-9]+$/;
export const socialSecurityNumber = new RegExp(
  /^(?!0{3})(?!6{3})[0-8]\d{2}(?!0{2})\d{2}(?!0{4})\d{4}$/
);
export const noSpaceAlphaOrSymbols = /[a-zA-Z\s$&+,:;=?@#|'<>.^*()%!/-]/;

const Regex = {
  email,
  fitnessAssessment,
  gpa,
  pcsmTest,
  pftTest,
  number,
  socialSecurityNumber,
  noSpaceAlphaOrSymbols
};

export default Regex;
