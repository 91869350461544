
import clickOutside from '@/util/directives/clickOutside';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component<HelpBubbleComponent>({
  directives: {
    clickOutside
  }
})
export default class HelpBubbleComponent extends Vue {
  @Inject({ default: '' })
  helpText!: string;

  showText = false;

  clickOutside(): void {
    if (this.showText) {
      this.showText = false;
    }
  }
}
