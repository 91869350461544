var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _c("label", { staticClass: "field-labels", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "div",
      {
        staticClass:
          "flex items-center w-full px-3 text-sm leading-tight text-gray-700 bg-white border rounded appearance-none focus:outline-none focus:shadow-outline h-9",
        class: { "bg-gray-100": _vm.readonly },
      },
      [
        (_vm.canHide && !_vm.visible ? "password" : "text") === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.textInputValue,
                  expression: "textInputValue",
                },
              ],
              staticClass: "w-full outline-none",
              class: { "border-red-500": _vm.showError },
              attrs: {
                disabled: _vm.readonly,
                "data-cy": _vm.dataCy,
                id: _vm.id,
                maxlength: _vm.maxlength,
                placeholder: _vm.placeholder,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.textInputValue)
                  ? _vm._i(_vm.textInputValue, null) > -1
                  : _vm.textInputValue,
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.$emit("enter")
                },
                blur: _vm.onBlur,
                input: function ($event) {
                  return _vm.handleInputEvent($event.target.value)
                },
                change: [
                  function ($event) {
                    var $$a = _vm.textInputValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.textInputValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.textInputValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.textInputValue = $$c
                    }
                  },
                  function ($event) {
                    return _vm.handleChangeEvent($event.target.value)
                  },
                ],
              },
            })
          : (_vm.canHide && !_vm.visible ? "password" : "text") === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.textInputValue,
                  expression: "textInputValue",
                },
              ],
              staticClass: "w-full outline-none",
              class: { "border-red-500": _vm.showError },
              attrs: {
                disabled: _vm.readonly,
                "data-cy": _vm.dataCy,
                id: _vm.id,
                maxlength: _vm.maxlength,
                placeholder: _vm.placeholder,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.textInputValue, null) },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.$emit("enter")
                },
                blur: _vm.onBlur,
                input: function ($event) {
                  return _vm.handleInputEvent($event.target.value)
                },
                change: [
                  function ($event) {
                    _vm.textInputValue = null
                  },
                  function ($event) {
                    return _vm.handleChangeEvent($event.target.value)
                  },
                ],
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.textInputValue,
                  expression: "textInputValue",
                },
              ],
              staticClass: "w-full outline-none",
              class: { "border-red-500": _vm.showError },
              attrs: {
                disabled: _vm.readonly,
                "data-cy": _vm.dataCy,
                id: _vm.id,
                maxlength: _vm.maxlength,
                placeholder: _vm.placeholder,
                type: _vm.canHide && !_vm.visible ? "password" : "text",
              },
              domProps: { value: _vm.textInputValue },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.$emit("enter")
                },
                blur: _vm.onBlur,
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.textInputValue = $event.target.value
                  },
                  function ($event) {
                    return _vm.handleInputEvent($event.target.value)
                  },
                ],
                change: function ($event) {
                  return _vm.handleChangeEvent($event.target.value)
                },
              },
            }),
        _vm.canHide
          ? _c("icon-component", {
              staticClass: "cursor-pointer",
              attrs: { name: "eye", fill: "black", width: 18, height: 18 },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.visible = !_vm.visible
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }