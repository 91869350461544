import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '../../types';
import Bff from '@/api/bff';
import { Board } from '@/models/Entities/Board';

export const BOARD_MUTATIONS = {
  UPDATE_BOARD_APPLICATION_SELECTED_TRAINING:
    'UPDATE_BOARD_APPLICATION_SELECTED_TRAINING'
};

export class BoardModule
  extends CrudModule<Board>
  implements ICrudModule<Board>
{
  constructor(bff: Bff) {
    const path = 'board';

    super(Board, bff, path);

    const customActions: ActionTree<IModuleState<Board>, RootState> = {
      async fetchCurrent({ commit }) {
        const data = new Board(await bff.fetch<Board>(`${path}/current`));
        commit(MUTATIONS.SET, data);
      },
      async fetchBoardWithApplicants({ commit }, id: number) {
        const data = new Board(
          await bff.fetch<Board>(`${path}/${id}/withApplicants`)
        );
        commit(MUTATIONS.SET, data);
      },
      async updateBoardApplicationSelectedTraining(
        { commit },
        { applicationId, selectedTraining }
      ) {
        commit(BOARD_MUTATIONS.UPDATE_BOARD_APPLICATION_SELECTED_TRAINING, {
          applicationId,
          selectedTraining
        });
      }
    };

    // Apply custom actions
    this.applyActions(customActions);

    const customMutations: MutationTree<IModuleState<Board>> = {
      [BOARD_MUTATIONS.UPDATE_BOARD_APPLICATION_SELECTED_TRAINING](
        state: IModuleState<Board>,
        { applicationId, selectedTraining }
      ) {
        const applicationIndex = state.details.applications.findIndex(
          (application) => application.id === applicationId
        );
        if (applicationIndex > -1) {
          const application = state.details.applications[applicationIndex];
          application.selectedTraining = selectedTraining;
          state.details.applications.splice(applicationIndex, 1, application);
        }
      }
    };

    this.applyMutations(customMutations);
  }
}
