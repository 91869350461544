import CrudModule from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import Bff from '@/api/bff';
import { ActionTree, MutationTree } from 'vuex';
import { Log } from '@/models/Entities/Log';
import { RootState } from '@/store/types';
import { Application } from '@/models/Entities/Application/Application';

export const CANDIDATE_MUTATIONS = {
  SET_APPLICATION: 'SET_APPLICATION'
};
export class CandidateModule
  extends CrudModule<BaseCandidate>
  implements ICrudModule<BaseCandidate>
{
  constructor(bff: Bff) {
    const path = 'candidate';

    super(BaseCandidate, bff, path);

    const customState: IModuleState<BaseCandidate> = {
      collection: [],
      details: new BaseCandidate(),
      application: new Application(),
      isUpdatingApplication: Boolean,
      isCreatingApplication: Boolean
    };

    const customActions: ActionTree<IModuleState<BaseCandidate>, RootState> = {
      async archiveLog(_, { logId }) {
        await bff.delete<Log>(`log/${logId}`);
      },
      async patchApplication({ commit }, { applicationId, application }) {
        let result;
        try {
          result = await bff.patch(
            `applicant/application/${applicationId}`,
            application
          );
        } catch (err) {
          result = err;
        }
        commit('SET_APPLICATION', result);
        return result;
      },
      async deleteApplication(_, { applicationId }): Promise<void> {
        await bff.delete(`applicant/application/${applicationId}`);
      }
    };

    const customMutations: MutationTree<IModuleState<BaseCandidate>> = {
      [CANDIDATE_MUTATIONS.SET_APPLICATION](state, application) {
        state.application = application;
      }
    };

    this.applyMutations(customMutations);
    this.applyState(customState);
    this.applyActions(customActions);
  }
}
