/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { RootState } from '../types';

export function createActions(): ActionTree<any, RootState> {
  return {
    setItem(_, input: { key: string; value: string }) {
      window.localStorage.setItem(input.key, JSON.stringify(input.value));
    },
    getItem(_, key: string) {
      const result = window.localStorage.getItem(key) ?? null;

      if (result) {
        return JSON.parse(result);
      } else {
        return null;
      }
    },
    removeItem(_, key: string) {
      window.localStorage.removeItem(key);
    }
  };
}
