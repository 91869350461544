import config from '@/config';
import store from '@/store';
import { name as userModuleName } from '@/store/user';
import * as GeneratedClient from '../../generated-client/typescript-axios/src';

export class ApiClient {
  public readonly defaultClient: GeneratedClient.DefaultApi;
  public readonly applicationClient: GeneratedClient.ApplicationV2Api;
  constructor(basePath: string) {
    const isFirebase = config.environment === 'firebase';
    const configuration = new GeneratedClient.Configuration({
      basePath,
      accessToken: isFirebase ? () => this.addTokenToHeader() : undefined
    });
    this.applicationClient = new GeneratedClient.ApplicationV2Api(
      configuration
    );
    this.defaultClient = new GeneratedClient.DefaultApi(configuration);
  }

  async addTokenToHeader(): Promise<string> {
    const isLoggedIn = store.getters[`${userModuleName}/isLoggedIn`];
    if (isLoggedIn) {
      const user = store.getters[`${userModuleName}/firebaseUser`];
      const idToken = await user.getIdToken();
      return idToken;
    }
    return '';
  }
}
