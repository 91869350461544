const LOCATION_URL = `${location.protocol}//${location.hostname}${
  location.port ? ':' + location.port : ''
}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function buildConfig(env: any) {
  return {
    buildStage: env.VUE_APP_STAGE,
    buildType: env.NODE_ENV,
    environment: env.VUE_APP_ENVIRONMENT || 'firebase',
    firebase: {
      apiKey: env.VUE_APP_FIRESTORE_API_KEY,
      authDomain: env.VUE_APP_FIRESTORE_AUTH_DOMAIN,
      databaseURL: env.VUE_APP_FIRESTORE_DATABASE_URL,
      projectId: env.VUE_APP_FIRESTORE_PROJECT_ID,
      storageBucket: env.VUE_APP_FIRESTORE_STORAGE_BUCKET,
      messagingSenderId: env.VUE_APP_FIRESTORE_MESSAGING_SENDER_ID,
      appId: env.VUE_APP_FIRESTORE_APP_ID,
      measurementId: env.VUE_APP_FIRESTORE_MEASUREMENT_ID,
      emailVerificationURL: `${LOCATION_URL}/login?refresh=true`,
      firestore: {
        local: env.VUE_APP_FIREBASE_FIRESTORE_LOCAL == 'true' ? true : false,
        host: env.VUE_APP_FIREBASE_FIRESTORE_HOST
      },
      functions: {
        local: env.VUE_APP_FIREBASE_FUNCTIONS_LOCAL == 'true' ? true : false,
        host: env.VUE_APP_FIREBASE_FUNCTIONS_HOST
      },
      auth: {
        local: env.VUE_APP_FIREBASE_AUTH_USE_EMULATOR == 'true' ? true : false,
        host: env.VUE_APP_FIREBASE_AUTH_HOST
      }
    },
    skypatch: {
      apiUrl: env.VUE_APP_SKYPATCH_API ?? LOCATION_URL,
      clientApiUrl:
        env.VUE_APP_CLIENT_API_URL ?? `${LOCATION_URL}/skypatch-api`,
      timeout: env.VUE_APP_SKYPATCH_API_TIMEOUT || 15000
    }
  };
}

export default buildConfig(process.env);

export { buildConfig };
