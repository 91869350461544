
import 'firebase/auth';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import IconComponent from '@/components/IconComponent.vue';

@Component<LoginActionSuccessPage>({
  components: {
    IconComponent,
    LoginFormErrorMessageComponent,
    SignUpFormComponent
  }
})
export default class LoginActionSuccessPage extends Vue {
  @Prop({ default: '' })
  readonly title!: string;
  @Prop({ default: '' })
  readonly message!: string;
}
