import Bff from '@/api/bff';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { ICrudModule, IModel, IModuleState } from '../crudModule/ICrudModule';
import { RootState } from '../types';

export default class MaterializedModule implements ICrudModule<unknown> {
  constructor(private readonly bff: Bff) {}
  mutations: MutationTree<IModuleState<unknown>> = {};
  getters: GetterTree<IModuleState<unknown>, RootState> = {};
  state: IModuleState<unknown> = {
    collection: [],
    details: {} as never
  };
  public namespaced = true;

  public actions: ActionTree<IModuleState<unknown>, RootState> = {
    fetchWithSelectParameters: async <T>(
      _: ActionContext<IModuleState<unknown>, RootState>,
      payload: { path: string; query: SearchDto<T, never>; model: IModel<T> }
    ): Promise<SearchResponseDto<T>> => {
      const result: SearchResponseDto<T> = await this.bff.fetch<
        SearchResponseDto<T>
      >(`${payload.path}/${payload.path}/find`, payload.query);
      result.data = result.data.map((json) => {
        return new payload.model(json);
      });
      return result;
    },
    create: async <DtoType, ReturnType>(
      _: ActionContext<IModuleState<unknown>, RootState>,
      payload: { path: string; dto: DtoType }
    ): Promise<ReturnType> => {
      const result: ReturnType = await this.bff.create<DtoType, ReturnType>(
        `${payload.path}`,
        payload.dto
      );
      return result;
    }
  };
}
