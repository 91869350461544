import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';

export class SponsoringUnit extends BaseEntity {
  @Type(() => String)
  unit!: string;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classStartDate!: Date;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classEndDate!: Date;

  @Type(() => String)
  wing!: string;

  @Type(() => String)
  base!: string;

  @Type(() => String)
  aircraft!: string;

  @Type(() => String)
  pas!: string;

  @Type(() => String)
  state!: string;

  @Type(() => String)
  zipcode!: string;

  @Type(() => String)
  city!: string;

  @Type(() => String)
  email!: string;

  @Type(() => String)
  email2?: string;

  @Type(() => String)
  cc!: string;

  @Type(() => Number)
  ccPhone!: number;

  @Type(() => String)
  do!: string;

  @Type(() => Number)
  doPhone!: number;

  @Type(() => String)
  fss!: string;

  @Type(() => Number)
  fssPhone!: number;

  @Type(() => String)
  manning!: string;

  @Type(() => String)
  oban!: string;

  @Type(() => String)
  address!: string;

  constructor(partial?: Partial<SponsoringUnit>) {
    super();
    if (partial) {
      Object.assign(this, plainToClass(SponsoringUnit, partial));
    }
  }
}
