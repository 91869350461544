export const checks = {
  checkCircle: {
    body: `<path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"
              />`,
    fill: 'currentColor',
    viewBox: '0 0 24 24'
  },
  checkCircleFilled: {
    body: `<circle cx="6" cy="6" r="6"/>
              <path d="M2.5 5.99996L4.5 7.99996L9 3.49996" stroke="white" />`,
    viewBox: '0 0 12 12',
    fill: '#3E66FB'
  },
  checkGreen: {
    body: `<path clip-rule="evenodd"
    d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z"
    fill-rule="evenodd" />`,
    fill: 'green',
    viewBox: '0 0 24 24'
  },
  checkList: {
    body: `<path d="M9.84936 10.3122V8.3213H31.8571V10.3122H9.84936ZM9.84936 2.34852H31.8571V4.33944H9.84936V2.34852ZM9.84936 16.285V14.2941H31.8571V16.285H9.84936ZM9.84936 22.2578V20.2669H31.8571V22.2578H9.84936ZM2.8469 3.67062L6.14493 0.404251L7.55168 1.80412L2.8469 6.48591L0.142822 3.79505L1.54957 2.39518L2.8469 3.67062ZM2.8469 9.6434L6.14493 6.37703L7.55168 7.7769L2.8469 12.4587L0.142822 9.76783L1.54957 8.36796L2.8469 9.6434ZM2.8469 15.6162L6.14493 12.3498L7.55168 13.7497L2.8469 18.4315L0.142822 15.7406L1.54957 14.3407L2.8469 15.6162ZM2.8469 21.589L6.14493 18.3226L7.55168 19.7225L2.8469 24.4043L0.142822 21.7134L1.54957 20.3135L2.8469 21.589Z"/>`,
    fill: 'none',
    viewBox: '0 0 32 25'
  },
  checkmark: {
    body: `<path d="M2 9.5L11 18.5L27.5 2" stroke-width="5" />`,
    stroke: 'black',
    viewBox: '0 0 30 23'
  },
  checkmarkGlow: {
    body: `<path d="M23 33.5L32 42.5L48.5 26" stroke="black" stroke-width="5" />
            <line x1="36.5" y1="8" x2="36.5" y2="2.18556e-08" stroke="black" />
            <line
              x1="55.6464"
              y1="13.6464"
              x2="59.6464"
              y2="9.64645"
              stroke="black"
            />
            <line
              x1="12.3536"
              y1="9.64645"
              x2="16.3536"
              y2="13.6464"
              stroke="black"
            />
            <line x1="60" y1="33.5" x2="67" y2="33.5" stroke="black" />
            <line y1="33.5" x2="7" y2="33.5" stroke="black" />`,
    viewBox: '0 0 67 47',
    fill: 'none'
  }
};
