var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "flex items-center justify-center w-full px-6 py-2 text-white print:hidden bg-primary-dark",
    },
    [
      _c(
        "div",
        { staticClass: "flex items-center mr-auto" },
        [
          _c(
            "button",
            {
              attrs: { "data-cy": "menuButton" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("icon-component", {
                staticClass: "flex-shrink-0 mr-6 fill-current",
                attrs: { name: "menu", height: 24, width: 24 },
              }),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "flex items-center font-semibold",
              attrs: { to: "/" },
            },
            [
              _c("img", {
                staticClass: "w-auto h-12 py-1",
                attrs: {
                  src: require("@/assets/logo.png"),
                  alt: "Air Force Reserve",
                },
              }),
              _c("h3", { staticClass: "ml-6" }, [_vm._v("Skypatch")]),
            ]
          ),
        ],
        1
      ),
      _c("search-component", { staticClass: "my-4 mr-4 sm:my-0" }),
      _c(
        "div",
        {
          staticClass:
            "flex items-center ml-auto mr-2 text-sm font-semibold cursor-pointer",
          attrs: { "data-cy": "loginButton" },
        },
        [
          _vm.isQAAuthorized
            ? _c(
                "button",
                {
                  staticClass:
                    "mr-6 h-10 bg-yellow-500 px-3 rounded hover:bg-yellow-600 transition ease-out duration-500 disabled:bg-gray-600",
                  class: { "cursor-not-allowed": _vm.isDisabled },
                  attrs: { type: "button", disabled: _vm.isDisabled },
                  on: { click: _vm.goToQA },
                },
                [_vm._v(" Go To QA ")]
              )
            : _vm._e(),
          _vm.showLoginButton
            ? _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _vm.isSignedIn
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: _vm.routes.AUTH_LOGOUT } } },
                        [_vm._v("Logout")]
                      )
                    : _vm._e(),
                  !_vm.isSignedIn
                    ? _c(
                        "router-link",
                        { attrs: { to: { name: _vm.routes.AUTH_LOGIN } } },
                        [_vm._v("Sign In")]
                      )
                    : _vm._e(),
                  _c("icon-component", {
                    staticClass: "fill-current",
                    attrs: { name: "chevronDown", height: 24, width: 24 },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }