
import FooterComponent from '@/components/Header/FooterComponent.vue';
import HeaderComponent from '@/components/Header/HeaderComponent.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator';
import LoadingComponent from './components/LoadingComponent.vue';
import ModalMounterComponent from './components/ModalMounterComponent.vue';
import SnackbarComponent from './components/SnackbarComponent.vue';

@Component<App>({
  components: {
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    LoadingComponent,
    SnackbarComponent,
    ModalMounterComponent
  }
})
export default class App extends Vue {
  get isLoading(): boolean {
    return this.$store.getters['loadingModule/isLoading'];
  }
}
