import AccountVerificationPage from '@/pages/Auth/AccountVerificationPage.vue';
import ForgotPasswordPage from '@/pages/Auth/ForgotPasswordPage.vue';
import LoginActionSuccessPage from '@/pages/Auth/LoginActionSuccessPage.vue';
import LoginPage from '@/pages/Auth/LoginPage.vue';
import LoginSelectionPage from '@/pages/Auth/LoginSelectionPage.vue';
import LogoutPage from '@/pages/Auth/LogoutPage.vue';
import PasswordResetConfirmationPage from '@/pages/Auth/PasswordResetConfirmationPage.vue';
import PasswordResetPage from '@/pages/Auth/PasswordResetPage.vue';
import SignupPage from '@/pages/Auth/SignupPage.vue';
import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';

export const generateRoutes: (
  store: Store<RootState>
) => RouteConfig[] = () => [
  {
    path: '/auth',
    name: Routes.AUTH,
    component: () => import('@/pages/Auth/AuthPage.vue'),
    children: [
      {
        path: 'login',
        name: Routes.AUTH_LOGIN,
        component: LoginPage
      },
      {
        path: 'login/select',
        name: Routes.AUTH_LOGIN_SELECT,
        component: LoginSelectionPage
      },
      {
        path: 'logout',
        name: Routes.AUTH_LOGOUT,
        component: LogoutPage
      },
      {
        path: 'signup',
        name: Routes.AUTH_SIGNUP,
        component: SignupPage
      },
      {
        path: 'signup/verification',
        name: Routes.AUTH_SIGNUP_VERIFICATION,
        component: AccountVerificationPage
      },
      {
        path: 'signup/verification/success',
        name: Routes.AUTH_SIGNUP_VERIFICATION_SUCCESS,
        component: LoginActionSuccessPage,
        props: () => {
          return {
            title: 'Email verification successful',
            message: 'Your email has been verified, you are now logged in'
          };
        }
      },
      {
        path: 'password/forgot',
        name: Routes.AUTH_PASSWORD_FORGOT,
        component: ForgotPasswordPage
      },
      {
        path: 'password/reset',
        name: Routes.AUTH_PASSWORD_RESET,
        component: PasswordResetPage
      },
      {
        path: 'password/reset/confirmation',
        name: Routes.AUTH_PASSWORD_RESET_CONFIRMATION,
        component: PasswordResetConfirmationPage
      },
      {
        path: 'password/reset/success',
        name: Routes.AUTH_PASSWORD_RESET_SUCCESS,
        component: LoginActionSuccessPage,
        props: () => {
          return {
            title: 'Password reset successful',
            message: 'Your password has been reset'
          };
        }
      }
    ]
  }
];
