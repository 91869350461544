var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.cancel } }, [
        _c("div", { staticClass: "p-4 px-8 text-xs" }, [
          _c("h2", { staticClass: "mb-4 text-xl" }, [_vm._v("New Applicant")]),
          _c("div", { staticClass: "flex flex-col mb-6 md:flex-row" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "ApplicantModalFirstName",
                    errorText: "Please enter a first name",
                    required: true,
                    placeholder: "Enter a Value",
                    label: "First name*",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("firstName", $event)
                    },
                  },
                  model: {
                    value: _vm.applicant.identificationInformationFirstname,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.applicant,
                        "identificationInformationFirstname",
                        $$v
                      )
                    },
                    expression: "applicant.identificationInformationFirstname",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full mx-0 mt-6 md:w-1/2 md:ml-8 md:my-0" },
              [
                _c("text-input", {
                  attrs: {
                    "data-cy": "ApplicantModalMiddleName",
                    placeholder: "Middle name",
                    label: "Middle name",
                    errorText: "Please enter a middle name",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("middleName", $event)
                    },
                  },
                  model: {
                    value: _vm.applicant.identificationInformationMiddlename,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.applicant,
                        "identificationInformationMiddlename",
                        $$v
                      )
                    },
                    expression: "applicant.identificationInformationMiddlename",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full mb-6" },
            [
              _c("text-input", {
                attrs: {
                  "data-cy": "ApplicantModalLastName",
                  errorText: "Please enter a last name",
                  required: true,
                  placeholder: "Last name",
                  label: "Last name*",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("lastName", $event)
                  },
                },
                model: {
                  value: _vm.applicant.identificationInformationLastname,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.applicant,
                      "identificationInformationLastname",
                      $$v
                    )
                  },
                  expression: "applicant.identificationInformationLastname",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full mb-6" },
            [
              _c("text-input", {
                attrs: {
                  "data-cy": "ApplicantModalEmail",
                  placeholder: "Email address",
                  label: "Email*",
                  regex: _vm.Regex.email,
                  required: true,
                  errorText: "Please enter a valid email.",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("email", $event)
                  },
                },
                model: {
                  value: _vm.applicant.contactInformationEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.applicant, "contactInformationEmail", $$v)
                  },
                  expression: "applicant.contactInformationEmail",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex flex-col mb-6 md:flex-row" }, [
            _c(
              "div",
              { staticClass: "w-full md:w-1/2" },
              [
                _c("date-selector", {
                  attrs: {
                    "data-cy": "ApplicantModalDateOfBirth",
                    label: "Date of birth*",
                    yearsForward: 0,
                    initialDate: _vm.initialDate,
                    required: true,
                    errorText: "Please enter date of birth",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("dob", $event)
                    },
                  },
                  model: {
                    value: _vm.applicant.identificationInformationDateofbirth,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.applicant,
                        "identificationInformationDateofbirth",
                        $$v
                      )
                    },
                    expression:
                      "applicant.identificationInformationDateofbirth",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full mx-0 mt-6 md:w-1/2 md:ml-8 md:my-0" },
              [
                _c("phone-input", {
                  attrs: {
                    "data-cy": "ApplicantModalPhone",
                    required: true,
                    label: "Phone number*",
                  },
                  on: {
                    validationState: function ($event) {
                      return _vm.updateFormState("homePhone", $event)
                    },
                  },
                  model: {
                    value: _vm.applicant.contactInformationHomephone,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.applicant,
                        "contactInformationHomephone",
                        $$v
                      )
                    },
                    expression: "applicant.contactInformationHomephone",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full mb-6" },
            [
              _c("social-security", {
                attrs: {
                  "data-cy": "ApplicantModalSocialSecurity",
                  required: true,
                  label: "SSN",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState("ssn", $event)
                  },
                },
                model: {
                  value:
                    _vm.applicant.identificationInformationSocialsecuritynumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.applicant,
                      "identificationInformationSocialsecuritynumber",
                      $$v
                    )
                  },
                  expression:
                    "applicant.identificationInformationSocialsecuritynumber",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: Array.isArray(_vm.manager),
                  expression: "Array.isArray(manager)",
                },
              ],
              staticClass: "w-full mb-6",
            },
            [
              _c("dropdown-component", {
                attrs: { values: _vm.recuiterOptions, label: "Recruiter" },
                model: {
                  value: _vm.applicant.manager.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.applicant.manager, "id", $$v)
                  },
                  expression: "applicant.manager.id",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "flex items-center justify-end" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-4 mr-4 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                  attrs: {
                    "data-cy": "ApplicantModalCancelButton",
                    type: "button",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 text-white rounded focus:outline-none focus:shadow-outline",
                  class: {
                    "opacity-50": !_vm.valid,
                    "bg-gray-500": !_vm.valid,
                    "bg-val-button-blue": _vm.valid,
                  },
                  attrs: {
                    "data-cy": "ApplicantModalSaveAndContinue",
                    type: "button",
                    disabled: !_vm.valid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createApplicant(true)
                    },
                  },
                },
                [_vm._v(" Save And Continue To Applicant ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 ml-4 text-white rounded focus:outline-none focus:shadow-outline",
                  class: {
                    "opacity-50": !_vm.valid,
                    "bg-gray-500": !_vm.valid,
                    "bg-val-button-blue": _vm.valid,
                  },
                  attrs: {
                    "data-cy": "ApplicantModalSaveAndReturn",
                    type: "button",
                    disabled: !_vm.valid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createApplicant(false)
                    },
                  },
                },
                [_vm._v(" Save And Return To Table ")]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }