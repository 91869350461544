export enum EGainsPriority {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High'
}

export const priorityColorMap = {
  [EGainsPriority.LOW]: '#4F955B',
  [EGainsPriority.MEDIUM]: '#FFB53A',
  [EGainsPriority.HIGH]: '#C81111'
};
