import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from './Routes';
import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const recruiterDetailsProps = () => {
    return {
      recruiter: store.getters['recruiterModule/details']
    };
  };
  const meta = {
    roles: [EUserRoles.RECRUITER, EUserRoles.ADMIN, EUserRoles.READ_ONLY],
    requiresAuth: true
  };
  return [
    {
      path: '/recruiter',
      name: Routes.RECRUITER,
      meta,
      component: () => import('@/pages/Recruiter/RecruiterPage.vue'),
      children: [
        {
          path: 'current',
          name: Routes.RECRUITER_CURRENT,
          meta,
          component: () => import('@/pages/Recruiter/RecruiterDetailsPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            try {
              await store.dispatch('recruiterModule/fetchCurrent');
            } catch (error: unknown) {
              if (
                error instanceof Error &&
                error.message.includes('status code 404')
              ) {
                await store.dispatch('recruiterModule/createCurrent');
              }
            }
          }),
          props: recruiterDetailsProps
        },
        {
          path: ':id',
          name: Routes.RECRUITER_DETAILS,
          meta,
          component: () => import('@/pages/Recruiter/RecruiterDetailsPage.vue'),
          beforeEnter: beforeEnterWrapper(async (to) => {
            await store.dispatch('recruiterModule/fetch', to.params.id);
          }),
          props: recruiterDetailsProps
        }
      ]
    }
  ];
};
