
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import NumberInput from '@/components/Forms/Elements/NumberInputComponent.vue';
import { EStates } from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { IEditModal } from '../IModal';

@Component<SponsoringUnitModalComponent>({
  components: {
    DateSelector,
    Modal,
    PhoneInput,
    TextInput,
    NumberInput,
    DropdownComponent
  }
})
export default class SponsoringUnitModalComponent
  extends Vue
  implements IEditModal<SponsoringUnit>
{
  @Prop({ default: '' })
  readonly size!: string;

  sponsoringUnit: SponsoringUnit | null = new SponsoringUnit();
  formState: IFormState = {};
  show = false;
  stateDropdownOptions = getInputOptions(EStates);
  resolve: (value: SponsoringUnit | PromiseLike<SponsoringUnit>) => void =
    (): void => void 0;
  reject: () => void = (): void => void 0;

  close(): void {
    this.show = false;
  }

  public async open(id: number | string): Promise<SponsoringUnit> {
    this.show = true;
    if (id > -1) {
      // edit handling
      const result = await this.$store.dispatch('sponsoringUnitModule/fetch', {
        id
      });
      this.sponsoringUnit = result;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    }
    // create handling
    this.sponsoringUnit = new SponsoringUnit();
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  async done(): Promise<void> {
    if (!this.sponsoringUnit) {
      return this.cancel();
    }
    this.resolve(this.sponsoringUnit);
    // clear form or previous entries from edit can re-render
    this.sponsoringUnit = null;
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  // handle when no entry is made
  get isFormEmpty(): boolean {
    return Object.values(this.sponsoringUnit ?? []).every((item) => !item);
  }
  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1
    );
  }

  get disableButton(): boolean {
    return !(!!this.sponsoringUnit?.unit && this.valid && !this.isFormEmpty);
  }
}
