import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const boardDetailProps = () => {
    return {
      board: store.getters['boardModule/details']
    };
  };
  const meta = {
    roles: [
      EUserRoles.A3_RB,
      EUserRoles.A3_SR_LEADER,
      EUserRoles.ADMIN,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/A3',
      name: Routes.A3,
      meta,
      component: () => import('@/pages/A3/A3Page.vue'),
      children: [
        {
          path: 'boards',
          name: Routes.A3_BOARDS,
          meta,
          component: () => import('@/pages/A3/A3BoardCollectionPage.vue')
        },
        {
          path: 'boards/current',
          name: Routes.A3_BOARD_CURRENT,
          meta,
          component: () => import('@/pages/A3/A3BoardDetailsPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('boardModule/fetchCurrent');
          }),
          props: boardDetailProps
        },
        {
          path: 'boards/archived',
          name: Routes.A3_ARCHIVED_BOARDS,
          meta,
          component: () =>
            import('@/pages/A3/A3ArchivedBoardCollectionPage.vue')
        },
        {
          path: 'boards/closed',
          name: Routes.A3_CLOSED_BOARDS,
          meta,
          component: () => import('@/pages/A3/A3ClosedBoardCollectionPage.vue')
        },
        {
          path: 'boards/:id',
          name: Routes.A3_BOARD,
          meta,
          component: () => import('@/pages/A3/A3BoardDetailsPage.vue'),
          beforeEnter: beforeEnterWrapper(async (to) => {
            await store.dispatch(
              'boardModule/fetchBoardWithApplicants',
              to.params.id
            );
          }),
          props: boardDetailProps
        }
      ]
    }
  ];
};
