import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Type, plainToClass } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';

export class Staff extends BaseEntity {
  uid!: string | null;

  contactInformationEmail!: string;

  @Type(() => Number)
  contactInformationHomephone!: number | null;

  @Type(() => Number)
  contactInformationWorkphone!: number | null;

  contactInformationAddress1!: string | null;

  contactInformationAddress2!: string | null;

  contactInformationCity!: string | null;

  contactInformationState!: string | null;

  contactInformationCountry!: string | null;

  contactInformationZipcode!: string | null;

  contactInformationPreferredcontactmethod!: string[] | null;

  identificationInformationFirstname!: string;

  identificationInformationMiddlename!: string | null;

  identificationInformationLastname!: string;

  identificationInformationSuffix!: string | null;

  identificationInformationSocialsecuritynumber!: string | null;

  identificationInformationDateofbirth!: Date;

  @Type(() => Number)
  identificationInformationEdipi!: number | null;

  identificationInformationPlaceofbirth!: string | null;

  @Type(() => BaseCandidate)
  candidates: BaseCandidate[] = [];

  @Type(() => Number)
  candidateIds: number[] = [];

  get applicants(): BaseCandidate[] {
    return this.candidates;
  }

  set applicants(applicants: BaseCandidate[]) {
    this.candidates = applicants;
  }

  get name(): string {
    return `${this.identificationInformationFirstname} ${this.identificationInformationLastname}`;
  }

  get fullName(): string {
    const {
      identificationInformationFirstname,
      identificationInformationMiddlename,
      identificationInformationLastname
    } = this;
    const middleInitial = identificationInformationMiddlename
      ? identificationInformationMiddlename.charAt(0)
      : '';
    return `${
      identificationInformationFirstname
        ? identificationInformationFirstname
        : ''
    } ${middleInitial} ${
      identificationInformationLastname ? identificationInformationLastname : ''
    }`.trim();
  }

  constructor(partial?: Partial<Staff>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(Staff, partial));
    }
  }
}
