enum Routes {
  APPLICANT = 'applicant',
  APPLICANT_COLLECTION = 'applicant.collection',

  APPLICANT_APPLICATION_CREATE = 'applicant.application.create',
  APPLICANT_APPLICATION_DETAILS = 'applicant.application.details',
  APPLICANT_APPLICATION_COLLECTION = 'applicant.application.collection',
  NEW_APPLICANT = 'new.applicant',

  /**
   * a special route that redirects the user to the profile page
   * this gets around the bug that prevents the user form from not updating
   */
  APPLICANT_REDIRECT = 'v2.applicant.redirect',
  APPLICANT_DETAILS = 'v2.applicant.profile',
  V2_APPLICANT_PROFILE = 'v2.applicant.profile',
  V2_APPLICANT_VITALS = 'v2.applicant.vitals',
  V2_APPLICANT_APPLICATION = 'v2.applicant.application',
  V2_APPLICANT_APPLICATION_DETAILS = 'v2.applicant.application.details',
  V2_APPLICANT_APPLICATION_CREATE = 'v2.applicant.application.create',
  V2_APPLICANT_GAINS = 'v2.applicant.gains',
  V2_APPLICANT_IN_PROCESSING = 'v2.applicant.in-processing',
  V2_APPLICANT_PIPELINE = 'v2.applicant.pipeline',
  V2_APPLICANT_OFFICER_TRAINING_SCHOOL = 'v2.applicant.officer-training-school',
  V2_APPLICANT_DOCUMENTS = 'v2.applicant.documents',
  V2_APPLICANT_NOTES_AND_ORDERS = 'v2.applicant.notes-and-orders',
  V2_APPLICANT_PROCESSING = 'v2.applicant.processing',

  HOME = 'home',
  CONNECT_TEST = 'connect.test',

  NEW_USER = 'new.user',

  AUTH = 'auth',
  AUTH_INVITE = 'auth.invite',
  AUTH_LOGIN = 'auth.login',
  AUTH_LOGIN_SELECT = 'auth.login.select',
  AUTH_LOGOUT = 'auth.logout',
  AUTH_SIGNUP = 'auth.signup',
  AUTH_SIGNUP_VERIFICATION = 'auth.signup.verification',
  AUTH_SIGNUP_VERIFICATION_SUCCESS = 'auth.signup.verification.success',
  AUTH_PASSWORD_FORGOT = 'auth.password.forgot',
  AUTH_PASSWORD_RESET = 'auth.password.reset',
  AUTH_PASSWORD_RESET_CONFIRMATION = 'auth.password.reset.confirmation',
  AUTH_PASSWORD_RESET_SUCCESS = 'auth.password.reset.success',

  INVITATION = 'invitation',

  ADMIN = 'admin',
  ADMIN_BOARDS = 'admin.boards',
  ADMIN_USERS = 'admin.users',
  ADMIN_APPLICANTS = 'admin.applicants',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',

  //TODO: question??? are we still using any of these routes?
  //ANSWER: seems to the NO, but were leaving here to be removed as a part of a hotfix type PR
  A3 = 'A3',
  A3_APPLICANT = 'A3.applicant',
  A3_APPLICANTS = 'A3.applicants',
  A3_APPLICATION = 'A3.application',
  A3_BOARD = 'A3.board',
  A3_BOARDS = 'A3.boards',
  A3_ARCHIVED_BOARDS = 'A3.archived.boards',
  A3_CLOSED_BOARDS = 'A3.closed.boards',
  A3_BOARD_CURRENT = 'A3.boards.current',

  FLIGHT_CHIEF = 'flight.chief',
  FLIGHT_CHIEF_RECRUITER_COLLECTION = 'flight.chief.collection',
  FLIGHT_CHIEF_RECRUITER_DETAILS = 'flight.chief.details',
  FLIGHT_CHIEF_RECRUITER_APPLICANTS_SUBMITTED = 'flight.chief.submitted',

  QA = 'QA',

  RECRUITER = 'recruiter',
  RECRUITER_CURRENT = 'recruiter.current',
  RECRUITER_DETAILS = 'recruiter.details',

  REPORTS_DASHBOARD = 'reports.dashboard',

  STUDENT = 'student',
  STUDENT_DASHBOARD = 'student.dashboard',
  STUDENT_DETAIL = 'student.detail',
  STUDENT_IMMEDIATE_ACTION = 'student.immediate',
  STUDENT_COURSE_PLANNER = 'course.planner',

  GAINS_PRIORITY = 'gains.priority',

  OTS_CLASSES = 'ots.classes',
  OTS_ROSTER_DETAILS = 'ots.roster.details',
  INPRO_CLASSES = 'inpro.classes',
  INPRO_CLASS_DETAILS = 'inpro.class.details',
  INPRO_CLASS_EDIT = 'inpro.class.edit',
  SPONSORING_UNITS = 'sponsoring.units',
  TLN_IMPORT_REPORT = 'tln.import.report',
  TLN_IMPORT_REPORT_DETAILS = 'tln.import.report.details',
  ORDERS_ENDING = 'orders.ending',

  LETTER_EMPLOYEE = 'letter.employee',
  LETTER_HOUSING = 'letter.housing',
  LETTER_TRAFFIC = 'letter.traffic',

  UNIT_REPRESENTATIVE = 'unit.rep',
  UNIT_REPRESENTATIVE_CURRENT = 'unit.rep.current',
  UNIT_REPRESENTATIVE_COLLECTION = 'unit.rep.collection',
  UNIT_REPRESENTATIVE_DETAILS = 'unit.rep.details',
  UNIT_REPRESENTATIVE_SUBMITTED_APPLICANTS = 'unit.rep.submitted.applicants',

  VIEW_DCR = 'view.dcr',
  VIEW_DCR_DETAILS = 'view.dcr.details'
}

export default Routes;
