export function removeTimeZoneOffset(value: Date): Date | null {
  if (value && value.getDate() != value.getUTCDate()) {
    const fullYear = value.getUTCFullYear();
    const month = value.getUTCMonth();
    const dayOfMonth = value.getUTCDate();
    value.setFullYear(fullYear);
    value.setMonth(month);
    value.setDate(dayOfMonth);
    value.setHours(0);
  }
  return value;
}
