var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "absolute flex items-center justify-center w-full h-full bg-gray-300",
    },
    [
      _c(
        "div",
        { staticClass: "px-8 py-8 bg-white min-w-30" },
        [
          _c(
            "div",
            { staticClass: "flex items-center mb-10" },
            [
              _vm.showArrow
                ? _c("icon-component", {
                    staticClass: "mr-4",
                    attrs: { name: "arrowLeft", width: 20, height: 20 },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  })
                : _vm._e(),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("div", { staticClass: "flex flex-grow" }, [
                _c(
                  "div",
                  { staticClass: "flex justify-end flex-grow" },
                  [
                    _vm.showX
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: "home" } } },
                          [
                            _c("icon-component", {
                              attrs: { name: "x", width: 14, height: 14 },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }