export enum DataImportType {
  TLN = 'TLN',
  DCR = 'DCR'
}

export enum ApplicationCompletionStatus {
  NOT_TOUCHED = 'Not Touched',
  PARTIAL = 'Partial',
  COMPLETED = 'Completed'
}
