import { format } from 'date-fns';

/**
 * returns a string in the form day - month - year hour:minute
 *
 * @export
 * @param {(string | Date)} date
 * @returns  {string}
 */
export function formatDateTime(date: string | Date): string {
  return format(new Date(date), 'dd-MMM-yy kk:mm');
}

/**
 * return a string from a date in the form day - month - year by default
 * pass any any format string as the second parameter to get a formatted date string
 *
 * @export
 * @param {(string | Date)} date
 * @param {string} [formatString='dd-MMM-yy']
 * @returns  {string}
 */
export function formatDate(
  date: string | Date,
  formatString = 'dd-MMM-yy'
): string {
  return format(new Date(date), formatString);
}

/**
 * returns a string in the form hour:minute
 *
 * @export
 * @param {(string | Date)} date
 * @returns  {string}
 */
export function formatTime(date: string | Date): string {
  return format(new Date(date), 'kk:mm');
}
