import Bff from '@/api/bff';
import { Staff } from '@/models/Entities/Personas/Staff';
import { User } from '@/models/Entities/User';
import { DisplayNameUtil } from '@/util/displayName';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';

export class RecruiterModule
  extends CrudModule<Staff>
  implements ICrudModule<Staff>
{
  constructor(bff: Bff) {
    const path = 'recruiter';

    super(Staff, bff, path);

    const customActions: ActionTree<IModuleState<Staff>, RootState> = {
      async fetchCurrent({ commit }): Promise<Staff> {
        const data = new Staff(
          await bff.fetch<Staff>(`${path}/current?archivedCandidates=true`)
        );
        commit(MUTATIONS.SET, data);
        return data;
      },
      async createCurrent({ commit }): Promise<Staff> {
        const user: User = this.getters['userModule/currentUser'];
        const { firstName, lastName } = user.displayName
          ? DisplayNameUtil.splitDisplayName(user.displayName)
          : { firstName: '', lastName: '' };
        const payload = {
          uid: user.uid,
          contactInformation: {
            email: user.email
          },
          identificationInformation: {
            firstName,
            lastName
          },
          type: 'recruiter',
          identificationInformationFirstname: firstName,
          identificationInformationLastname: lastName,
          contactInformationEmail: user.email
        };
        type createStaffType = {
          uid: string | null;
          contactInformation: {
            email: string | null;
          };
          identificationInformation: {
            firstName: string;
            lastName: string;
          };
          type: string;
          identificationInformationFirstname: string;
          identificationInformationLastname: string;
          contactInformationEmail: string | null;
        };
        const result = await bff.create<createStaffType, Staff>(
          `${path}`,
          payload
        );
        const data = new Staff(result);
        commit(MUTATIONS.SET, data);
        return data;
      }
    };

    // Apply custom actions
    this.applyActions(customActions);
  }
}
