var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass:
        "fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-screen h-screen bg-white",
      attrs: { "data-cy": "loadingComponent" },
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-col items-center" },
        [
          _c("icon-component", {
            staticClass: "mb-2 animate-pulse",
            attrs: {
              name: "airForce",
              width: 120,
              height: 80,
              fill: "#10182b",
            },
          }),
          _c("p", { staticClass: "justify-center mb-4 text-xl px-7" }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ]),
          _vm.showOtherOptions
            ? _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "button-component",
                    { staticClass: "mr-4", on: { click: _vm.goBack } },
                    [_vm._v("Go Back")]
                  ),
                  _c(
                    "button-component",
                    [
                      _c("router-link", { attrs: { to: _vm.toRoute } }, [
                        _vm._v("Try Again"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }