
import ModalMixin from '@/mixins/ModalMixin';
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';

@Component<ModalComponent>({})
export default class ModalComponent extends Mixins(Vue, ModalMixin) {
  @Prop({ default: '' })
  size!: 'x-small' | 'small' | 'medium' | 'large';

  get width(): string {
    switch (this.size) {
      case 'x-small':
        return 'w-64';
      case 'small':
        return 'w-450';
      case 'medium':
        return 'w-650';
      case 'large':
        return 'w-850';
      default:
        return 'w-1/2';
    }
  }

  closeHandler(): void {
    this.$emit('close');
  }
}
