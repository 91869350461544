
import firebase from 'firebase/app';
import 'firebase/auth';
import { Component, Mixins } from 'vue-property-decorator';
import LoginFormErrorMessageComponent from '@/components/LoginFormErrorMessageComponent.vue';
import SignUpFormComponent from '@/components/Forms/SignUpFormComponent.vue';
import Regex from '@/models/Forms/Regex';
import Routes from '../../router/Routes';
import IconComponent from '@/components/IconComponent.vue';
import DebounceMixin from '@/mixins/DebounceMixin';
import { User } from '@/models/Entities/User';

@Component<SignupPage>({
  components: {
    IconComponent,
    LoginFormErrorMessageComponent,
    SignUpFormComponent
  }
})
export default class SignupPage extends Mixins(DebounceMixin) {
  routes = Routes;
  customErrorMessage = '';
  displayName = '';
  email = '';
  pwInput = '';
  pwConfirmInput = '';
  acceptEULA = false;
  handleSignUp = this.debounce(this.signUp, 750);
  isProcessing = false;

  mounted() {
    if (this.$route.query.refresh === 'true') {
      firebase.auth().signOut();
    }
  }

  get validForm() {
    return (
      !this.emailError &&
      !this.passwordError &&
      !this.confirmPasswordError &&
      this.acceptEULA
    );
  }

  get emailError(): boolean {
    return !Regex.email.test(this.email);
  }
  get passwordError(): boolean {
    return (
      this.pwInput === undefined ||
      this.pwInput == '' ||
      this.pwInput.length < 6
    );
  }
  get confirmPasswordError(): boolean {
    return this.pwConfirmInput != this.pwInput || this.pwConfirmInput == '';
  }

  async signUp() {
    this.isProcessing = true;
    try {
      const password = this.validatePassword();
      this.customErrorMessage = '';
      const auth = firebase.auth();
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, password);
      if (user) {
        await user.updateProfile({
          displayName: this.displayName,
          photoURL: null
        });
        // Send email confirmation
        if (auth && auth.currentUser) {
          await this.$store.dispatch(
            'userModule/setUser',
            User.fromAuthClient(auth.currentUser)
          );
        }
        // nav to confirmation page
        this.$router.push({
          name: Routes.AUTH_SIGNUP_VERIFICATION
        });
      }
    } catch (err: any) {
      this.customErrorMessage = err.message;
    }
    this.isProcessing = false;
  }

  validatePassword(): string {
    if (this.passwordError || this.confirmPasswordError) {
      throw new Error('Invalid password input');
    }
    return this.pwInput;
  }
}
