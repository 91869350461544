import { Route } from 'vue-router';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { routerState } from './types';

export const getters: GetterTree<routerState, RootState> = {
  toRoute(state): Route | null {
    return state.toRoute;
  },
  fromRoute(state): Route | null {
    return state.fromRoute;
  }
};
