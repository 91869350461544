import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';

export class InProcessingClass extends BaseEntity {
  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classStartDate!: Date;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  classEndDate!: Date;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  reserveConferenceRoom!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  lodgingConfirmed!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  legalConfirmed!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  trackReserved!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  scheduleConfirmed!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  businessCards!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  tableTents!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  nameTags!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  folderLabels!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  uDriveRequest!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  uDriveDropOff!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  shuttleRequest!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  emailBriefTimes!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  rosterSenttoComm!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  rosterSenttoSARM!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  rosterSenttoMed!: Date | null;

  candidateIds!: number[];

  constructor(partial?: Partial<InProcessingClass>) {
    super();
    if (partial) {
      Object.assign(this, plainToClass(InProcessingClass, partial));
    }
  }
}
