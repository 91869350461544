import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { Type, plainToClass, Transform } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';

export class OfficerTrainingSchoolClass extends BaseEntity {
  @Type(/* istanbul ignore next */ () => String)
  classNumber!: string | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  startDate!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  graduationDate!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  onSite!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  rosterEmailed!: Date | null;

  candidateIds!: number[];

  constructor(partial?: Partial<OfficerTrainingSchoolClass>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(OfficerTrainingSchoolClass, partial));
    }
  }
}
