import { BadgeType } from '@/enums';
import { MwsType } from '@/models/Entities/Student/Constants';
import { FlightTrainingCourse } from '@/models/Entities/Student/FlightTrainingCourse';
import { plainToClass, Type } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';

class BadgeAndMwsCourseMap extends BaseEntity {
  @Type(() => String)
  public mws!: MwsType;
  @Type(() => String)
  public badge!: BadgeType;
  @Type(() => Number)
  public courseIdsToAdd: number[] = [];
  @Type(() => Number)
  public courseIdsToRemove: number[] = [];
  @Type(() => Number)
  public courseIds: number[] = [];
  @Type(() => FlightTrainingCourse)
  public courses: FlightTrainingCourse[] = [];

  /**
   * property synonym for mws
   *
   * @readonly
   * @memberof BadgeAndMwsCourseMap
   */
  get airCraft(): MwsType {
    return this.mws;
  }

  set airCraft(aircraft: MwsType) {
    this.mws = aircraft;
  }

  constructor(partial?: Partial<BadgeAndMwsCourseMap>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(BadgeAndMwsCourseMap, partial));
    }
  }
}

export { BadgeAndMwsCourseMap };
