
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Board } from '@/models/Entities/Board';
import { IEditModal } from '../IModal';

@Component<EditBoardModalComponent>({
  components: {
    ButtonComponent,
    DateSelector,
    Modal,
    TextInput
  }
})
export default class EditBoardModalComponent
  extends Vue
  implements IEditModal<Board>
{
  @Prop({ default: '' })
  size!: string;

  board: Board | null = null;
  formState: IFormState = {};
  show = false;
  resolve: (value: Board | PromiseLike<Board>) => void = (): void => void 0;
  reject: () => void = (): void => void 0;

  close(): void {
    this.show = false;
  }

  public async open(id: number | string): Promise<Board> {
    this.show = true;
    const result = await this.$store.dispatch('boardModule/fetch', { id });
    this.board = result;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  confirm(): void {
    if (!this.board) {
      return this.cancel();
    }
    this.resolve(this.board);
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1
    );
  }
}
