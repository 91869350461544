// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Overpass/Overpass-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Overpass\";\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\nbody,\n.text-dark {\n  color: #141414;\n}\n\nlabel,\n.label {\n  color: #141414;\n  font-size: 11px;\n  font-weight: 500;\n  text-transform: capitalize;\n}\n\n.bg-primary-dark {\n  background: #10182B;\n}\n\n.text-primary-dark {\n  color: #10182B;\n}\n\n.text-gray-4 {\n  color: #747484;\n}\n\nh1 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 48px;\n}\n\nh2 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 36px;\n}\n\nh3 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 24px;\n}\n\nh4 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 18px;\n}\n\nh5 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 16px;\n}\n\nh6 {\n  font-style: normal;\n  font-family: \"Overpass\";\n  font-weight: 700;\n  font-size: 11px;\n}\n\n.firebase-emulator-warning {\n  display: none;\n}\n\n.fade-enter {\n  opacity: 100;\n}\n\n.fade-enter-active,\n.fade-leave-active {\n  transition: opacity 0.1s ease-in;\n  transition-delay: 0.4s;\n}\n\n.fade-enter,\n.fade-leave-to {\n  opacity: 0;\n}\n\n.slide-fade-enter {\n  max-height: 1px;\n  transform: translateX(1500px);\n}\n\n.slide-fade-enter-active {\n  transition: all 0.3s ease;\n}\n\n.slide-fade-leave-active {\n  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);\n}\n\n.slide-fade-enter-from,\n.slide-fade-leave-to {\n  transform: translateX(1500px);\n}\n\ninput:disabled,\ntextarea:disabled,\ninput:disabled::placeholder,\ntextarea:disabled::placeholder {\n  background-color: #f3f4f6;\n  opacity: 1;\n}", ""]);
// Exports
module.exports = exports;
