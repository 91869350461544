import { EExceptionToPolicyTypes, EPriorServiceComponent } from '@/enums';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { Application } from '@/models/Entities/Application/Application';
import { LabelItem, LabelItems } from '@/models/Entities/types';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';

export type RequiredApplicationFieldItem = {
  field: LabelItem;
  section: LabelItem;
  value: unknown;
  required: boolean;
};

const FieldLabels = {
  ACADEMIC_IS_AVIATION_GRADUATE: 'Aviation Program Graduate?',
  ACADEMIC_INSTITUTION: 'Academic institution',
  ACADEMIC_DEGREE: 'Degree',
  ACADEMIC_MAJOR: 'Academic specialty/major',
  ACADEMIC_GPA: 'Cumulative GPA',
  ACADEMIC_GRADUATION_DATE: 'Date Of Graduation',
  ACADEMIC_ROTC_TRAINING_REPORT: 'upload ROTC training report',
  EXPECTED_ROTC_DATE: 'Expected ROTC graduation',
  APPLICANT_TYPE: 'applicant type',
  AVAILABLE_TRAINING_DATE: 'Date available to start training',
  BASE_PREFERENCE: 'base preference',
  COMMANDER_COMMENTS: 'commander comments',
  COMMANDER_DOES_RECOMMENDS: 'commander does recommend',
  COMMANDER_GRADE: 'commander grade',
  COMMANDER_NAME: 'commander name',
  COMMANDER_RANK_SCORE: 'Commander rank score',
  COMMANDER_TOTAL_RANK: 'Commander total rank',
  DEMOGRAPHICS_DUAL_CITIZEN: 'dual citizen',
  EXCEPTION_TO_POLICY_MEMO: 'Exception to policy upload memo',
  FLIGHT_PHYSICAL_STATUS: 'flight physical status at board',
  FLYING_EXPERIENCE_PRIVATE_LICENSE:
    'Has FAA pilot license (Student or Higher)',
  FLYING_EXPERIENCE_DROPPED_ON_REQUEST: 'Flying Experience Elimination',
  FLYING_EXPERIENCE_DROPPED_ON_EXPLANATION: 'DOR explanation',
  FLYING_EXPERIENCE_CIVILIAN_PILOT_CERTIFICATE: 'Civilian Pilot Certificate',
  FLYING_EXPERIENCE_TOTAL_HOURS: 'Total civilian flight hours',
  FLYING_EXPERIENCE_LAST_DATE_FLOWN: 'Date last logged PIC time',
  FLYING_EXPERIENCE_UPLOAD_PILOTS_LICENSE: 'Upload Pilot License',
  FLYING_EXPERIENCE_UPLOAD_LOG_BOOK: 'Upload Last Page of Pilot Logbook',
  FLYING_EXPERIENCE_FLIGHT_RATING_CATEGORY: 'Civilian Flight Rating Category',
  FLYING_EXPERIENCE_FLIGHT_RATING_CLASS: 'Civilian Flight Rating Class',
  FLYING_EXPERIENCE_INSTRUCTOR_CERTIFICATION: 'Instructor Certification',
  NUMBER_OF_TIMES_APPLIED_TO_UFT: 'number of times applied to UFT',
  PHYSICAL_ASSESSMENT_DATE: 'Assessment date',
  PHYSICAL_ASSESSMENT_DOCUMENT: 'Upload Fitness Test File',
  PHYSICAL_ASSESSMENT_SCORE: 'Assessment score',
  POC_EMAIL: 'POC email',
  POC_FIRST_NAME: 'POC first name',
  POC_LAST_NAME: 'POC last name',
  POC_PHONE: 'POC phone',
  PRIOR_SERVICE_COMPONENT: 'component',
  PRIOR_SERVICE_ARB: 'ARB',
  PRIOR_SERVICE_GRADE: 'Prior service grade',
  PRIOR_SERVICE_AIRFRAME: 'airframe',
  PRIOR_SERVICE_DATE_OF_RANK: 'Date of rank',
  PRIOR_SERVICE_COMMISSION_DATE: 'Total Federal Comm. Service Date',
  PRIOR_SERVICE_DUTY_TITLE: 'duty title',
  PRIOR_SERVICE_DUTY_STATION: 'Organization and Duty station',
  PRIOR_SERVICE_DUTY_PHONE: 'Duty phone number',
  PRIOR_SERVICE_DUTY_PHONE_TYPE: 'Duty phone type',
  PRIOR_SERVICE_TOTAL_FLIGHT_HOURS: 'Military flight hours',
  SCROLL_STATUS: 'scroll status',
  SPONSORSHIP_IS_SPONSORED: 'Sponsored',
  SPONSORSHIP_UNIT: 'Sponsoring unit',
  SPONSORSHIP_LETTER: 'upload sponsorship letter',
  TESTING_AFOQT_DATE: 'testing AFOQT date',
  TESTING_PILOT_SCORE: 'testing pilot score',
  TESTING_CSO_SCORE: 'testing CSO score',
  TESTING_ABM_SCORE: 'testing ABM score',
  TESTING_AA_SCORE: 'testing AA score',
  TESTING_VERBAL_SCORE: 'testing verbal score',
  TESTING_QUANTITATIVE_SCORE: 'testing quantitative score',
  TESTING_PCSM_SCORE: 'testing PCSM score',
  TRAINING_PREFERENCE: 'training preference'
};

// Order by how it lines up on the page
const Sections = {
  APPLICANT_DATA: 'applicant',
  ACADEMIC_DATA: 'academic',
  TRAINING_PREFERENCE: 'training preference',
  APPLICANT_REMARKS: 'applicant remarks',
  BASE_PREFERENCES: 'base preferences',
  BOARD: 'board',
  PRIOR_SERVICE: 'prior service',
  PHYSICAL_ASSESSMENT: 'physical assessment',
  FLYING_EXPERIENCE: 'flying experience',
  ENDORSEMENTS: 'endorsements',
  EXCEPTION_TO_POLICY: 'exception to policy',
  TESTING_DATA: 'testing'
};

export type RequiredSection = {
  section: LabelItem;
  items: RequiredApplicationFields[];
  status: ESectionStatus;
};

export const SectionLabels: LabelItems = convertToKeyValueObj(Sections);
export const FieldItemLabels: LabelItems = convertToKeyValueObj(FieldLabels);

export class RequiredApplicationFields {
  static getApplicationRequiredFields(
    application: Application
  ): RequiredApplicationFieldItem[] {
    return [
      {
        field: FieldItemLabels.SPONSORSHIP_IS_SPONSORED,
        section: SectionLabels.APPLICANT_DATA,
        value: application.sponsorshipIssponsored,
        required: true
      },
      {
        field: FieldItemLabels.FLIGHT_PHYSICAL_STATUS,
        section: SectionLabels.ENDORSEMENTS,
        value: application.flightPhysicalStatusAtBoard,
        required: true
      },
      {
        field: FieldItemLabels.SCROLL_STATUS,
        section: SectionLabels.ENDORSEMENTS,
        value: application.scrollStatus,
        required: true
      },
      {
        field: FieldItemLabels.AVAILABLE_TRAINING_DATE,
        section: SectionLabels.TRAINING_PREFERENCE,
        value: application.availableTrainingDate,
        required: true
      },
      {
        field: FieldItemLabels.ACADEMIC_INSTITUTION,
        section: SectionLabels.ACADEMIC_DATA,
        value: application.academicInformationInstitution,
        required: true
      },
      {
        field: FieldItemLabels.ACADEMIC_MAJOR,
        section: SectionLabels.ACADEMIC_DATA,
        value: application.academicInformationMajor,
        required: true
      },
      {
        field: FieldItemLabels.ACADEMIC_DEGREE,
        section: SectionLabels.ACADEMIC_DATA,
        value: application.academicInformationDegree,
        required: true
      },
      {
        field: FieldItemLabels.ACADEMIC_GRADUATION_DATE,
        section: SectionLabels.ACADEMIC_DATA,
        value: application.academicInformationGraduationdate,
        required: true
      },
      {
        field: FieldItemLabels.ACADEMIC_GPA,
        section: SectionLabels.ACADEMIC_DATA,
        value: application.academicInformationGpa,
        required: true
      },
      {
        field: FieldItemLabels.TRAINING_PREFERENCE,
        section: SectionLabels.TRAINING_PREFERENCE,
        value: application.selectionPreferencesTrainingpreference1,
        required: true
      },
      // Testing Data
      {
        field: FieldItemLabels.TESTING_AFOQT_DATE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationAfoqtdate,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_PILOT_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationPilotscore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_CSO_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationCsoscore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_ABM_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationAbmscore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_AA_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationAascore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_VERBAL_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationVerbalscore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_QUANTITATIVE_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationQuantitativescore,
        required: true
      },
      {
        field: FieldItemLabels.TESTING_PCSM_SCORE,
        section: SectionLabels.TESTING_DATA,
        value: application.testingInformationPcsmscore,
        required: true
      },
      {
        field: FieldItemLabels.DEMOGRAPHICS_DUAL_CITIZEN,
        section: SectionLabels.APPLICANT_DATA,
        value: application.demographicsIsdualcitizen,
        required: true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_PRIVATE_LICENSE,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceHasprivatelicense,
        required: true
      },
      {
        field: FieldItemLabels.APPLICANT_TYPE,
        section: SectionLabels.APPLICANT_DATA,
        value: application.applicationType,
        required: true
      },
      // Fly Experience required fields
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_DROPPED_ON_REQUEST,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceDroppedonrequest,
        required: application.flyExperienceHasprivatelicense === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_DROPPED_ON_EXPLANATION,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceDroppedonrequestexplanation,
        required: application.flyExperienceDroppedonrequest === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_CIVILIAN_PILOT_CERTIFICATE,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceCivilianpilotcertificate,
        required: application.flyExperienceHasprivatelicense === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_TOTAL_HOURS,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceCiviliantotalflighthours,
        required: application.flyExperienceHasprivatelicense === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_LAST_DATE_FLOWN,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceLastdateflown,
        required: application.flyExperienceHasprivatelicense === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_PILOTS_LICENSE,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperiencePilotslicenselocation,
        required: application.flyExperienceHasprivatelicense === true
      },
      {
        field: FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_LOG_BOOK,
        section: SectionLabels.FLYING_EXPERIENCE,
        value: application.flyExperienceLastpageofpilotlogbooklocation,
        required: application.flyExperienceHasprivatelicense === true
      },
      // Sponsorship required fields
      {
        field: FieldItemLabels.SPONSORSHIP_UNIT,
        section: SectionLabels.APPLICANT_DATA,
        value: application.sponsorshipSponsorunit,
        required: application.sponsorshipIssponsored === true
      },
      {
        field: FieldItemLabels.SPONSORSHIP_LETTER,
        section: SectionLabels.APPLICANT_DATA,
        value: application.sponsorshipSponsorshipletterlocation,
        required: application.sponsorshipIssponsored === true
      },
      // Physical Assessment required fields
      {
        field: FieldItemLabels.PHYSICAL_ASSESSMENT_DATE,
        section: SectionLabels.PHYSICAL_ASSESSMENT,
        value: application.physicalAssessmentsDate,
        required:
          application.priorServiceComponent === EPriorServiceComponent.AFR ||
          application.priorServiceComponent === EPriorServiceComponent.REG_AF
      },
      {
        field: FieldItemLabels.PHYSICAL_ASSESSMENT_DOCUMENT,
        section: SectionLabels.PHYSICAL_ASSESSMENT,
        value: application.physicalAssessmentsPhysicalfitnessdocumentlocation,
        required:
          application.priorServiceComponent === EPriorServiceComponent.AFR ||
          application.priorServiceComponent === EPriorServiceComponent.REG_AF
      },
      {
        field: FieldItemLabels.PHYSICAL_ASSESSMENT_SCORE,
        section: SectionLabels.PHYSICAL_ASSESSMENT,
        value: application.physicalAssessmentsScore,
        required:
          application.priorServiceComponent === EPriorServiceComponent.AFR ||
          application.priorServiceComponent === EPriorServiceComponent.REG_AF
      },
      // Prior service required fields
      {
        field: FieldItemLabels.ARB,
        section: SectionLabels.PRIOR_SERVICE,
        value: application.priorServiceArb,
        required: application.isPriorService
      },
      {
        field: FieldItemLabels.PRIOR_SERVICE_COMPONENT,
        section: SectionLabels.PRIOR_SERVICE,
        value: application.priorServiceComponent,
        required: application.isPriorService
      },
      {
        field: FieldItemLabels.PRIOR_SERVICE_GRADE,
        section: SectionLabels.PRIOR_SERVICE,
        value: application.priorServiceGrade,
        required: application.isPriorService
      },
      {
        field: FieldItemLabels.PRIOR_SERVICE_DUTY_TITLE,
        section: SectionLabels.PRIOR_SERVICE,
        value: application.priorServiceDutytitle,
        required: application.isPriorService
      },
      {
        field: FieldItemLabels.PRIOR_SERVICE_DUTY_STATION,
        section: SectionLabels.PRIOR_SERVICE,
        value: application.priorServiceDutystation,
        required: application.isPriorService
      },
      {
        field: FieldItemLabels.ACADEMIC_ROTC_TRAINING_REPORT,
        section: SectionLabels.APPLICANT_DATA,
        value: application.academicInformationRotcfieldtrainingreportlocation,
        required: application.isRotc
      },
      {
        field: FieldItemLabels.EXCEPTION_TO_POLICY_MEMO,
        section: SectionLabels.EXCEPTION_TO_POLICY,
        value: application.exceptionToPolicyMemolocation,
        required:
          (application.exceptionToPolicyReasons &&
            application.exceptionToPolicyReasons.every(
              (i) => i != EExceptionToPolicyTypes.NONE
            ) &&
            application.exceptionToPolicyReasons.length > 0) ||
          false
      },
      {
        field: FieldItemLabels.BASE_PREFERENCE,
        section: SectionLabels.BASE_PREFERENCES,
        value: application.selectionPreferencesBasepreference1,
        required: application.requirePreferredUptBases
      }
    ];
  }
}
