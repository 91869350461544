import Bff from '@/api/bff';
import { ActionTree } from 'vuex';
import { CourseSection } from '../../../models/Entities/Student/CourseSection';
import { RootState } from '../../types';
import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';

export class CourseSectionModule
  extends CrudModule<CourseSection>
  implements ICrudModule<CourseSection>
{
  constructor(bff: Bff) {
    const path = 'section';
    super(CourseSection, bff, path);

    const customActions: ActionTree<IModuleState<CourseSection>, RootState> = {
      async fetchAllWithStudents({ commit }) {
        const sections: CourseSection[] = await bff.fetchAll<CourseSection>(
          `${path}/students/`
        );
        commit(MUTATIONS.SET_ALL, sections);
        return sections;
      }
    };

    // Apply custom actions
    this.applyActions(customActions);
  }
}
