import { OpenProps } from '@/components/types';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { snackBarState } from './types';

export function createActions(): ActionTree<snackBarState, RootState> {
  return {
    async enqueue({ commit }, message: OpenProps) {
      commit('ENQUEUE', message);
    },
    async dequeue({ commit }) {
      commit('DEQUEUE');
    }
  };
}
