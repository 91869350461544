
import { Component, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { IQAresults } from '@/store/types';

@Component<Metrics>({
  components: { IconComponent }
})
export default class Metrics extends Vue {
  seederValues = [
    { label: 500 },
    { label: 1000 },
    { label: 2000 },
    { label: 5000 },
    { label: 10000 }
  ];
  selected = this.seederValues[0].label;

  loading = false;
  error = '';
  status = '';
  seededValue = 0;

  resetStatusBar(): void {
    this.error = '';
    this.status = '';
  }

  get isDisabled(): boolean {
    return this.loading || String(this.seededValue) === String(this.selected);
  }

  async runSeeder(): Promise<IQAresults | void> {
    this.resetStatusBar();
    const payload = { numberOfCandidates: Number(this.selected) };
    this.loading = true;
    try {
      const res = await this.$store.dispatch(
        'qaModule/initiateSeeder',
        payload
      );
      this.seededValue = Number(this.selected);
      if (res.status) {
        this.status = `Seeding of ${this.selected} candidates success. Elapsed time in service: ${res.message}`;
      } else {
        this.status = res.message;
      }
    } catch (err) {
      if (err instanceof Error) {
        this.error = err.message;
      } else {
        this.error = String(err);
      }
    }
    this.loading = false;
  }
}
