/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { create as ApplicantModule } from './applicant';
import { create as createLoadingModule } from './loading';
import { create as createLocalStorageModule } from './localStorage';
import { create as createMenuModule } from './menu';
import { create as ProfileSideNavigationModule } from './profileSideNav';
import { create as createApplicationModule } from './application';
// Crud modules
import { BoardModule } from '@/store/crudModule/modules/BoardModule';
import { CourseSectionModule } from '@/store/crudModule/modules/CourseSectionModule';
import { FlightTrainingCourseModule } from '@/store/crudModule/modules/FlightTrainingCourseModule';
import { OfficerTrainingSchoolClassModule } from '@/store/crudModule/modules/OfficerTrainingSchoolClassModule';
import { RecruiterModule } from '@/store/crudModule/modules/RecruiterModule';
import { StudentModule } from '@/store/crudModule/modules/StudentModule';
import { UnitRepresentativeModule } from '@/store/crudModule/modules/UnitRepresentative';
import { InProcessingClassModule } from './crudModule/modules/InProcessingClassModule';

import Bff from '@/api/bff';
import { SkyPatchApi } from '@/api/SkyPatchApi';
import Config from '@/config';
import { CandidateModule } from '@/store/crudModule/modules/Candidate';
import { FlightTrainingCourseRequirementMapModule } from '@/store/crudModule/modules/FlightTrainingCourseRequirementMapModule';
import { SponsoringUnitModule } from '@/store/crudModule/modules/SponsoringUnit';
import axios from 'axios';
import { RootState } from './types';
import { create as createReportModule } from './reports';
import { create as createRouterModule } from './router';
import { create as createSnackBarModule } from './snackbar';
import { create as createStorageModule } from './storage';
import { create as createMiniTableModule } from './minitable';
import { create as createUserModule, name as userModuleName } from './user';
import { create as createQAModule } from './qa';
import MaterializedModule from './materializedModule/MaterializedModule';

const skypatchAxiosInstance = axios.create({
  baseURL: Config.skypatch.apiUrl + '/api',
  timeout: Config.skypatch.timeout
});

const skypatchApi: SkyPatchApi = new SkyPatchApi(skypatchAxiosInstance);
const bff: Bff = new Bff(skypatchApi);

const applicantModule = ApplicantModule(bff);
const userModule = createUserModule(bff);
const storageModule = createStorageModule(bff);
const menuModule = createMenuModule();
const loadingModule = createLoadingModule();
const routerModule = createRouterModule();
const reportModule = createReportModule();
const applicationModule = createApplicationModule(bff);
const boardModule = new BoardModule(bff);
const profileSideNavigationModule = ProfileSideNavigationModule();
const flightTrainingCourseModule = new FlightTrainingCourseModule(bff);
const flightTrainingCourseRequirementMapModule =
  new FlightTrainingCourseRequirementMapModule(bff);
const recruiterModule = new RecruiterModule(bff);
const unitRepresentativeModule = new UnitRepresentativeModule(bff);
const localStorageModule = createLocalStorageModule();
const snackBarModule = createSnackBarModule();
const courseSectionModule = new CourseSectionModule(bff);
const inProcessingClassModule = new InProcessingClassModule(bff);
const officerTrainingSchoolClassModule = new OfficerTrainingSchoolClassModule(
  bff
);
const studentModule = new StudentModule(bff);
const candidateModule = new CandidateModule(bff);
const sponsoringUnitModule = new SponsoringUnitModule(bff);
const miniTableModule = createMiniTableModule(bff);
const qaModule = createQAModule(bff);
const materializedModule = new MaterializedModule(bff);

const storeOptions: StoreOptions<RootState> = {
  modules: {
    applicantModule,
    boardModule,
    storageModule,
    menuModule,
    loadingModule,
    routerModule,
    recruiterModule,
    studentModule,
    userModule,
    unitRepresentativeModule,
    reportModule,
    localStorageModule,
    flightTrainingCourseModule,
    snackBarModule,
    courseSectionModule,
    officerTrainingSchoolClassModule,
    flightTrainingCourseRequirementMapModule,
    inProcessingClassModule,
    profileSideNavigationModule,
    candidateModule,
    applicationModule,
    sponsoringUnitModule,
    miniTableModule,
    qaModule,
    materializedModule
  }
};

Vue.use(Vuex);

const store = new Vuex.Store<RootState>(storeOptions);

const addTokenToHeader = (): void => {
  skypatchAxiosInstance.interceptors.request.use(async function (config: any) {
    const isLoggedIn = store.getters[`${userModuleName}/isLoggedIn`];
    if (isLoggedIn) {
      const user = store.getters[`${userModuleName}/firebaseUser`];
      const idToken = await user.getIdToken();
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  });
};

if (Config.environment === 'firebase') {
  addTokenToHeader();
}

export default store;
