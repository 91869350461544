import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';
import { RootState } from '@/store/types';
import { Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const meta = {
    roles: [EUserRoles.FLIGHT_CHIEF, EUserRoles.ADMIN, EUserRoles.READ_ONLY],
    requiresAuth: true
  };
  const recruiterDetailProps = () => {
    return {
      recruiter: store.getters['recruiterModule/details']
    };
  };

  const recruiterDetailBeforeEnter = beforeEnterWrapper(async (to: Route) => {
    await store.dispatch('recruiterModule/fetch', to.params.id);
  });

  return [
    {
      path: '/flight-chief',
      name: Routes.FLIGHT_CHIEF,
      meta,
      component: () => import('@/pages/FlightChief/FlightChiefPage.vue'),
      children: [
        {
          path: 'recruiter',
          name: Routes.FLIGHT_CHIEF_RECRUITER_COLLECTION,
          meta,
          component: () =>
            import(
              '@/pages/FlightChief/FlightChiefRecruiterCollectionPage.vue'
            ),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('recruiterModule/fetchAll');
          }),
          props: () => {
            return {
              recruiters: store.getters['recruiterModule/collection']
            };
          }
        },
        {
          path: 'recruiter/:id',
          name: Routes.FLIGHT_CHIEF_RECRUITER_DETAILS,
          meta,
          component: () =>
            import('@/pages/FlightChief/FlightChiefRecruiterDetailsPage.vue'),
          beforeEnter: recruiterDetailBeforeEnter,
          props: recruiterDetailProps
        },
        {
          path: 'recruiter/:id/submitted',
          name: Routes.FLIGHT_CHIEF_RECRUITER_APPLICANTS_SUBMITTED,
          meta,
          component: () =>
            import(
              '@/pages/FlightChief/FlightChiefRecruiterSubmittedApplicantsPage.vue'
            ),
          beforeEnter: recruiterDetailBeforeEnter,
          props: recruiterDetailProps
        }
      ]
    }
  ];
};
