import Bff from '@/api/bff';
import { Application } from '@/models/Entities/Application/Application';
import { SponsoringUnit } from '@/models/Entities/Student/SponsoringUnit';
import { RootState } from '@/store/types';
import { ActionTree, MutationTree } from 'vuex';
import CrudModule from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';

export const SPONSORING_UNIT_MUTATIONS = {};
export class SponsoringUnitModule
  extends CrudModule<SponsoringUnit>
  implements ICrudModule<SponsoringUnit>
{
  constructor(bff: Bff) {
    const path = 'sponsoringUnit';

    super(SponsoringUnit, bff, path);

    const customState: IModuleState<SponsoringUnit> = {
      collection: [],
      details: new SponsoringUnit(),
      application: new Application(),
      isUpdatingApplication: Boolean,
      isCreatingApplication: Boolean
    };

    const customActions: ActionTree<
      IModuleState<SponsoringUnit>,
      RootState
    > = {};

    const customMutations: MutationTree<IModuleState<SponsoringUnit>> = {};

    this.applyMutations(customMutations);
    this.applyState(customState);
    this.applyActions(customActions);
  }
}
