import Bff from '@/api/bff';
import { Module } from 'vuex';
import { RootState } from '../types';
import { createActions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { QAState } from './types';

export function create(bff: Bff): Module<QAState, RootState> {
  const actions = createActions(bff);
  return {
    namespaced: true,
    getters,
    actions,
    mutations
  };
}
