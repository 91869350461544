var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "fixed left-0 right-0 z-50 flex justify-center w-full transition-all duration-500 ease-in-out transform bottom-4",
      class: {
        "translate-y-0": _vm.show,
        "opacity-100": _vm.show,
        "translate-y-20": !_vm.show,
        "opacity-0": !_vm.show,
      },
      attrs: { "data-cy": "snackbarComponent" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-center p-4 py-2 text-sm text-white bg-black rounded-lg shadow-md",
          attrs: { "data-cy": "snackbarIconAndText" },
        },
        [
          _vm._t("default", function () {
            return [
              _vm.icon
                ? _c("icon-component", {
                    staticClass: "mr-4",
                    attrs: {
                      name: _vm.icon.name,
                      width: _vm.icon.width || 14,
                      height: _vm.icon.height || 14,
                      fill: _vm.icon.fill,
                    },
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.message) + " "),
            ]
          }),
          _c(
            "div",
            { staticClass: "ml-4 cursor-pointer", on: { click: _vm.close } },
            [
              _c("icon-component", {
                staticClass: "fill-current",
                attrs: {
                  "data-cy": "snackbarCloseButton",
                  name: "x",
                  width: 14,
                  height: 14,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }