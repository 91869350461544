import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { LoadingState } from './types';

export const getters: GetterTree<LoadingState, RootState> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  message(state): string {
    return state.message;
  }
};
