
import 'reflect-metadata';
import { Component, Vue } from 'vue-property-decorator';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import CreateBoardModal from '@/components/Modals/Board/CreateBoardModalComponent.vue';
import EditBoardModal from '@/components/Modals/Board/EditBoardModalComponent.vue';
import YesNoModal from '@/components/Modals/YesNoModalComponent.vue';
import { ModalMounter } from './ModalMounter';
import SponsoringUnitModalComponent from './Modals/Unit/SponsoringUnitModalComponent.vue';
import OfficerTrainingSchoolClassModalComponent from '@/components/Modals/OfficerTrainingSchoolClass/OfficerTrainingSchoolClassModalComponent.vue';

@Component<ModalMounterComponent>({
  components: {
    CreateApplicantModal,
    YesNoModal,
    EditBoardModal,
    CreateBoardModal,
    SponsoringUnitModalComponent,
    OfficerTrainingSchoolClassModalComponent
  }
})
export default class ModalMounterComponent extends Vue {
  $refs!: {
    createApplicantModal: CreateApplicantModal;
    yesNoModal: YesNoModal;
    createBoardModal: CreateBoardModal;
    editBoardModal: EditBoardModal;
    sponsoringUnitModal: SponsoringUnitModalComponent;
    officerTrainingSchoolClassModal: OfficerTrainingSchoolClassModalComponent;
  };

  created(): void {
    ModalMounter.instance.init(this.$refs);
  }
}
