var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-cy": "labelComponent" } },
    [
      _c("label", [_vm._v(" " + _vm._s(_vm.label) + " ")]),
      _c("help-bubble", { staticClass: "ml-1" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }