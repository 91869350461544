import { EUserRoles } from '@/enums';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { RecruitmentStatus } from '@/models/Entities/Personas/Constants';
import { Student } from '@/models/Entities/Student/Student';
import { beforeEnterWrapper } from '@/router';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const meta = {
    roles: [EUserRoles.ADMIN, EUserRoles.READ_ONLY],
    requiresAuth: true
  };
  return [
    {
      path: '/admin',
      name: Routes.ADMIN,
      meta,
      component: () => import('@/pages/Admin/AdminPage.vue'),
      children: [
        {
          path: 'dashboard',
          name: Routes.ADMIN_DASHBOARD,
          meta: {
            roles: [
              EUserRoles.ADMIN,
              EUserRoles.FLIGHT_CHIEF,
              EUserRoles.READ_ONLY
            ],
            requiresAuth: true
          },
          component: () => import('@/pages/Admin/AdminDashboardPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            await Promise.all([
              store.dispatch('recruiterModule/fetchAll'),
              store.dispatch('userModule/fetchAll', {
                archived: true
              }),
              store.dispatch('boardModule/fetchAll', {
                archived: true
              }),
              store.dispatch('candidateModule/fetchAll', {
                archived: true
              })
            ]);
          }),
          props: () => {
            return {
              applicants: store.getters['candidateModule/collection'].filter(
                (candidate: Applicant) =>
                  candidate.recruitmentStatus === RecruitmentStatus.APPLICANT
              ),
              recruiters: store.getters['recruiterModule/collection'],
              users: store.getters['userModule/users'],
              boards: store.getters['boardModule/collection'],
              students: store.getters['candidateModule/collection'].filter(
                (candidate: Student) =>
                  candidate.recruitmentStatus === RecruitmentStatus.STUDENT
              )
            };
          }
        }
      ]
    }
  ];
};
