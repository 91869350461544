import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { LoadingState } from './types';

export function createActions(): ActionTree<LoadingState, RootState> {
  return {
    startLoading({ commit }) {
      commit('SET_IS_LOADING', true);
    },
    stopLoading({ commit }) {
      commit('SET_IS_LOADING', false);
    },
    setMessage({ commit }, message: string) {
      commit('SET_MESSAGE', message);
    }
  };
}
