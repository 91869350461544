import { SectionStatusData } from '@/components/types';
import { MutationTree } from 'vuex';
import { SideNavigationState } from './type';

export const mutations: MutationTree<SideNavigationState> = {
  SET_SECTION_STATUS(state, sectionStatus: SectionStatusData[]) {
    state.sectionStatus = sectionStatus;
  },
  REMOVE_ENTRY(state, { sectionHeader, entryIndex }) {
    state.sectionStatus
      .find((section: SectionStatusData) => section.header === sectionHeader)
      ?.entries.splice(entryIndex, 1);
  }
};
