import { SectionStatusData } from '@/components/types';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { SideNavigationState } from './type';

export function createActions(): ActionTree<SideNavigationState, RootState> {
  return {
    setSections({ commit }, data: SectionStatusData[]) {
      commit('SET_SECTION_STATUS', data);
    },
    removeEntry({ commit }, { sectionHeader, entryIndex }) {
      commit('REMOVE_ENTRY', { sectionHeader, entryIndex });
    },
    clear({ commit }) {
      commit('SET_SECTION_STATUS', []);
    }
  };
}
