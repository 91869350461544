import config from '@/config';
import Routes from '@/router/Routes';
import store from '@/store';
import { RootState } from '@/store/types';
import { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import wait from '../util/wait';

export const generateRoutes: (
  store: Store<RootState>
) => RouteConfig[] = () => [
  {
    path: '/invite/:hash',
    name: Routes.INVITATION,
    component: () => import('@/pages/Auth/InvitationPage.vue'),
    async beforeEnter(_to, _from, next) {
      await store.dispatch('loadingModule/stopLoading');
      await wait(1000);
      if (
        !store.getters['userModule/isLoggedIn'] &&
        config.environment === 'firebase'
      ) {
        next({ name: Routes.AUTH_LOGIN, query: { invite: 'true' } });
      } else {
        next();
      }
    }
  }
];
