class FormInputState {
  isValid: boolean;
  errorMessage: string;

  constructor({ isValid = false, errorMessage = '' }) {
    this.isValid = isValid;
    this.errorMessage = errorMessage;
  }
}

interface IFormState {
  [key: string]: FormInputState;
}

export { IFormState, FormInputState };
