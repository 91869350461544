var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-1", attrs: { id: _vm.id } }, [
    _c("label", { staticClass: "field-labels" }, [
      _vm._v(" " + _vm._s(_vm.label) + " "),
    ]),
    _c(
      "div",
      {
        staticClass:
          "flex items-center w-full px-3 py-2 text-sm leading-tight text-gray-700 bg-white border rounded appearance-none focus:outline-none focus:shadow-outline",
        class: { "border-red-500": _vm.showError, "bg-gray-100": _vm.readonly },
      },
      [
        _vm.visibility === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model",
                },
              ],
              staticClass: "w-full outline-none",
              attrs: {
                disabled: _vm.readonly,
                autocomplete: "new-password",
                "data-cy": "socialSecurityNumberInput",
                name: "ssnInput",
                maxLength: "11",
                placeholder: "••• - •• - ••••",
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.model)
                  ? _vm._i(_vm.model, null) > -1
                  : _vm.model,
              },
              on: {
                keypress: function ($event) {
                  return _vm.isNumber($event)
                },
                blur: _vm.onBlur,
                input: function ($event) {
                  return _vm.handleJumpingCursor(
                    $event.target,
                    $event.inputType
                  )
                },
                change: function ($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
              },
            })
          : _vm.visibility === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model",
                },
              ],
              staticClass: "w-full outline-none",
              attrs: {
                disabled: _vm.readonly,
                autocomplete: "new-password",
                "data-cy": "socialSecurityNumberInput",
                name: "ssnInput",
                maxLength: "11",
                placeholder: "••• - •• - ••••",
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.model, null) },
              on: {
                keypress: function ($event) {
                  return _vm.isNumber($event)
                },
                blur: _vm.onBlur,
                input: function ($event) {
                  return _vm.handleJumpingCursor(
                    $event.target,
                    $event.inputType
                  )
                },
                change: function ($event) {
                  _vm.model = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model",
                },
              ],
              staticClass: "w-full outline-none",
              attrs: {
                disabled: _vm.readonly,
                autocomplete: "new-password",
                "data-cy": "socialSecurityNumberInput",
                name: "ssnInput",
                maxLength: "11",
                placeholder: "••• - •• - ••••",
                type: _vm.visibility,
              },
              domProps: { value: _vm.model },
              on: {
                keypress: function ($event) {
                  return _vm.isNumber($event)
                },
                blur: _vm.onBlur,
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.model = $event.target.value
                  },
                  function ($event) {
                    return _vm.handleJumpingCursor(
                      $event.target,
                      $event.inputType
                    )
                  },
                ],
              },
            }),
        _vm.showCheckMark
          ? _c("font-awesome-icon", {
              staticClass: "h-4 mr-4 overflow-visible cursor-pointer",
              style: {
                color: "green",
              },
              attrs: { icon: ["fas", "check"] },
              on: { click: _vm.copyToClipboard },
            })
          : _vm._e(),
        _vm.ssn && !_vm.showCheckMark
          ? _c("font-awesome-icon", {
              staticClass: "h-4 mr-4 overflow-visible cursor-pointer",
              attrs: { icon: ["far", "clipboard"] },
              on: { click: _vm.copyToClipboard },
            })
          : _vm._e(),
        _c("font-awesome-icon", {
          staticClass: "h-4 overflow-visible cursor-pointer",
          attrs: { icon: _vm.visible ? ["fas", "eye"] : ["fas", "eye-slash"] },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleVisibility.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }