var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(
        "sign-up-form-component",
        { attrs: { title: "Create an email account" } },
        [
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" Email address ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: {
                  id: "email",
                  type: "text",
                  placeholder: "Enter your email",
                  "data-cy": "email",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm.emailError
                ? _c("login-form-error-message-component", {
                    attrs: {
                      message: "Enter a valid email address",
                      "data-cy": "emailError",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-1 text-xs text-gray-600",
                attrs: { for: "email" },
              },
              [_vm._v(" Display Name ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.displayName,
                  expression: "displayName",
                },
              ],
              staticClass:
                "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
              attrs: {
                id: "displayName",
                type: "text",
                placeholder: "Enter a display name",
                "data-cy": "displayName",
              },
              domProps: { value: _vm.displayName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.displayName = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" Create a password ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pwInput,
                    expression: "pwInput",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: {
                  id: "password",
                  type: "password",
                  placeholder: "Create a password",
                  "data-cy": "password",
                },
                domProps: { value: _vm.pwInput },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pwInput = $event.target.value
                  },
                },
              }),
              _vm.passwordError
                ? _c("login-form-error-message-component", {
                    attrs: {
                      message: "Password must be at least 6 characters",
                      "data-cy": "passwordError",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "block mb-1 text-xs text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v(" Confirm your password ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pwConfirmInput,
                    expression: "pwConfirmInput",
                  },
                ],
                staticClass:
                  "w-full px-3 py-2 text-gray-700 border rounded outline-none appearance-none",
                attrs: {
                  id: "pwConfirmInput",
                  type: "password",
                  placeholder: "Confirm your password",
                  "data-cy": "passwordConfirm",
                },
                domProps: { value: _vm.pwConfirmInput },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pwConfirmInput = $event.target.value
                  },
                },
              }),
              _vm.confirmPasswordError
                ? _c("login-form-error-message-component", {
                    attrs: {
                      message: "Passwords do not match",
                      "data-cy": "passwordConfirmError",
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "flex items-center mt-6" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "inline w-5 h-5 mt-3 mr-2 bg-white border border-black rounded",
                    attrs: { "data-cy": "acceptTermsOfService" },
                    on: {
                      click: function ($event) {
                        _vm.acceptEULA = !_vm.acceptEULA
                      },
                    },
                  },
                  [
                    _vm.acceptEULA
                      ? _c("icon-component", {
                          attrs: { name: "checkmark", width: 20, height: 20 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "inline pt-3 text-xs" }, [
                  _c("span", [_vm._v("I have read & accept the ")]),
                  _c("span", { staticClass: "underline" }, [
                    _vm._v("Terms of Service "),
                  ]),
                  _c("span", [_vm._v("and ")]),
                  _c("span", { staticClass: "underline" }, [
                    _vm._v("Privacy Policy "),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "flex items-center justify-end mt-6" }, [
            _c(
              "button",
              {
                staticClass:
                  "px-6 py-2 font-bold text-white bg-black rounded disabled:opacity-50",
                attrs: {
                  type: "button",
                  disabled: !_vm.validForm || _vm.isProcessing,
                  "data-cy": "submitButton",
                },
                on: { click: _vm.handleSignUp },
              },
              [_vm._v(" Create an account ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _vm.customErrorMessage != ""
                ? _c("login-form-error-message-component", {
                    staticClass: "block",
                    attrs: { message: _vm.customErrorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }