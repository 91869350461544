/* eslint-disable @typescript-eslint/no-explicit-any */
import Bff from '@/api/bff';
import { SearchDto } from '@/models/Dtos/common/searchDto';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { ActionTree } from 'vuex';
import wait from '../../util/wait';
import { RootState } from '../types';
import { ApplicantState } from './types';

const SIMULATED_LOAD_TIMEOUT = 700;
let createApplicationTimer: ReturnType<typeof setTimeout>;
let updateApplicationTimer: ReturnType<typeof setTimeout>;
let updateApplicantTimer: ReturnType<typeof setTimeout>;

export function createActions(bff: Bff): ActionTree<ApplicantState, RootState> {
  return {
    async fetchAll({ commit }): Promise<Applicant[]> {
      const data = await bff.fetchApplicants();
      commit('SET_ALL_APPLICANTS', data);
      return data;
    },
    async fetchAllWithArchived({ commit }): Promise<Applicant[]> {
      let applicants = await bff.fetchAll<Applicant>('applicant?archived=true');
      applicants = applicants.map((json) => new Applicant(json));
      commit('SET_ALL_APPLICANTS', applicants);
      return applicants;
    },
    async fetch({ commit }, id: number): Promise<Applicant> {
      const data = await bff.fetchApplicant(id);
      commit('SET_APPLICANT', data);
      return data;
    },

    async fetchWithOtsAndInProClasses(
      { commit },
      id: number
    ): Promise<Applicant> {
      const data = new Applicant(
        await bff.fetch<Applicant>(
          `applicant/${id}?relations=officerTrainingSchoolClass,inProcessingClass,sections`
        )
      );
      commit('SET_APPLICANT', data);
      return data;
    },

    createApplicant(_, applicant: Applicant) {
      return bff.createApplicant(applicant);
    },
    async patchApplicant({ commit }, { applicantId, payload }) {
      let result;
      commit('SET_IS_UPDATING', true);
      try {
        result = await bff.patchApplicant(applicantId, payload);
      } catch (err) {
        result = err;
      }
      clearTimeout(updateApplicantTimer);
      updateApplicantTimer = setTimeout(() => {
        commit('SET_IS_UPDATING', false);
      }, SIMULATED_LOAD_TIMEOUT);
      commit('UPSERT_APPLICANT', result);
      return result;
    },
    async deleteApplicant(_, applicantId: number) {
      await bff.deleteApplicant(applicantId);
    },
    async restoreApplicant(_, applicantId: number) {
      await bff.fetch<Applicant>(`applicant/${applicantId}/restore`);
    },
    patchApplicantLog(_, { logId, payload }) {
      return bff.patchApplicantLog(logId, payload);
    },
    async fetchApplicantLogs({ commit }, id: number) {
      const logs = await bff.fetchApplicantLogs(id);
      commit('SET_APPLICANT_LOGS', logs);
      return logs;
    },
    async fetchLogsWithSelectParameters(
      _,
      query: SearchDto<Log, never>
    ): Promise<SearchResponseDto<Log>> {
      const result: SearchResponseDto<Log> = await bff.fetch<
        SearchResponseDto<Log>
      >(`log/log/find`, query);
      result.data = result.data.map((json) => new Log(json));
      return result;
    },
    async createApplicantLog({ dispatch }, { id, log }) {
      const result = await bff.createApplicantLog(log);
      await dispatch('fetch', id);
      return result;
    },
    async fetchApplications({ commit }, query) {
      const result = await bff.fetchApplications(query);
      commit('SET_APPLICATIONS', result);
      return result;
    },
    async findApplications(_, query) {
      return await bff.fetchApplications(query);
    },
    async fetchApplication({ commit }, { applicantId, applicationId }) {
      const result = await bff.fetchApplication(applicantId, applicationId);
      commit('SET_APPLICATION', result);
      return result;
    },
    async createApplication({ commit }, application) {
      let result;
      commit('SET_IS_CREATING_APPLICATION', true);
      try {
        result = await bff.createApplication(application);
      } catch (err) {
        result = err;
      }
      clearTimeout(createApplicationTimer);
      await wait(SIMULATED_LOAD_TIMEOUT);
      commit('SET_IS_CREATING_APPLICATION', false);
      return result;
    },
    deleteApplication({ dispatch }, { applicantId, applicationId }) {
      bff
        .deleteApplication(applicationId)
        .then(async () => {
          await dispatch('fetch', applicantId);
          await dispatch('fetchApplications', applicantId);
        })
        .catch((err) => {
          console.log('Delete failed \n', err);
        });
    },
    async patchApplication({ commit }, { applicationId, payload }) {
      let result;
      commit('SET_IS_UPDATING_APPLICATION', true);
      try {
        result = await bff.patch(
          `applicant/application/${applicationId}`,
          payload
        );
      } catch (err) {
        result = err;
      }
      clearTimeout(updateApplicationTimer);
      await wait(SIMULATED_LOAD_TIMEOUT);
      commit('SET_IS_UPDATING_APPLICATION', false);
      commit('SET_APPLICATION', result);
      return result;
    },

    importApplicants(_, payload: File) {
      return bff.ingestFile(payload, 'applicants');
    }
  };
}
