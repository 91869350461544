import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import Bff from '@/api/bff';
import { Staff } from '@/models/Entities/Personas/Staff';

export class UnitRepresentativeModule
  extends CrudModule<Staff>
  implements ICrudModule<Staff>
{
  constructor(bff: Bff) {
    const path = 'unitRepresentative';

    super(Staff, bff, path);

    const customActions: ActionTree<IModuleState<Staff>, RootState> = {
      async fetchCurrent({ commit }): Promise<Staff> {
        const data: Staff = new Staff(
          await bff.fetch<Staff>(`${path}/current`)
        );
        commit(MUTATIONS.SET, data);
        return data;
      }
    };

    // Apply custom actions
    this.applyActions(customActions);
  }
}
