/* eslint-disable @typescript-eslint/no-explicit-any */
import { EUserRoles } from '@/enums';
import { beforeEnterWrapper } from '@/router';
import { RootState } from '@/store/types';
import { Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import Routes from './Routes';

export const generateRoutes: (store: Store<RootState>) => RouteConfig[] = (
  store
) => {
  const studentDetailsProp = () => {
    return {
      students: store.getters['studentModule/collection']
    };
  };

  const studentFromParamsProp = (from: any) => {
    return { ...from.params, ...studentDetailsProp };
  };

  const meta = {
    roles: [
      EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER,
      EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER,
      EUserRoles.ADMIN,
      EUserRoles.READ_ONLY
    ],
    requiresAuth: true
  };
  return [
    {
      path: '/340th/student',
      meta,
      component: () => import('@/pages/340th/Student/StudentPage.vue'),
      children: [
        {
          path: '',
          name: Routes.STUDENT_DASHBOARD,
          meta,
          component: () =>
            import('@/pages/340th/Student/StudentDashboardPage.vue'),
          props: (from) => {
            return {
              title: from.params.title
            };
          }
        },
        {
          path: 'immediate',
          name: Routes.STUDENT_IMMEDIATE_ACTION,
          meta,
          component: () =>
            import('@/pages/340th/Student/StudentImmediateActionPage.vue'),
          props: studentFromParamsProp
        },
        {
          path: 'otsClasses',
          name: Routes.OTS_CLASSES,
          meta,
          component: () => import('@/pages/340th/Student/OtsClassesPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('officerTrainingSchoolClassModule/fetchAll');
          }),
          props: () => {
            return {
              classes:
                store.getters['officerTrainingSchoolClassModule/collection']
            };
          }
        },
        {
          path: 'otsClasses/:otsId',
          name: Routes.OTS_ROSTER_DETAILS,
          meta,
          component: () =>
            import('@/pages/340th/Student/OtsRosterDetailsPage.vue'),
          props: (from) => {
            return {
              otsId: from.params.otsId
            };
          }
        },
        {
          path: 'inproGroups',
          name: Routes.INPRO_CLASSES,
          meta,
          component: () =>
            import('@/pages/340th/Student/InProcessingClassPage.vue'),
          beforeEnter: beforeEnterWrapper(async () => {
            await store.dispatch('inProcessingClassModule/fetchAll');
          }),
          props: () => {
            return {
              inProcessingClasses:
                store.getters['inProcessingClassModule/collection']
            };
          }
        },
        {
          path: 'inproGroups/:id',
          name: Routes.INPRO_CLASS_EDIT,
          meta,
          component: () =>
            import('@/pages/340th/Student/InProcessingClassDetailsPage.vue'),
          beforeEnter: beforeEnterWrapper(async (to: Route) => {
            await store.dispatch('inProcessingClassModule/fetch', to.params.id);
          }),
          props: () => {
            return {
              inProcessingClass:
                store.getters['inProcessingClassModule/details']
            };
          }
        },
        {
          path: 'inproGroups/roster/:id',
          name: Routes.INPRO_CLASS_DETAILS,
          meta,
          component: () =>
            import('@/pages/340th/Student/InProcessingRosterPage.vue'),
          props: (from) => {
            return {
              id: from.params.id
            };
          }
        },
        {
          path: 'sponsoringUnits',
          name: Routes.SPONSORING_UNITS,
          meta,
          component: () =>
            import('@/pages/340th/Student/SponsoringUnitPage.vue')
        },
        {
          path: 'tlnImport',
          name: Routes.TLN_IMPORT_REPORT,
          meta,
          component: () => import('@/pages/TLN/TlnImportPage.vue')
        },
        {
          path: 'tlnImport/:tlnImportId',
          name: Routes.TLN_IMPORT_REPORT_DETAILS,
          meta,
          component: () => import('@/pages/TLN/TlnRowLogPage.vue'),
          props: (from) => {
            return {
              tlnImportId: from.params.tlnImportId
            };
          }
        },
        {
          path: 'dcrImport',
          name: Routes.VIEW_DCR,
          meta,
          component: () => import('@/pages/DCR/DcrImportPage.vue')
        },
        {
          path: 'dcrImport/:dcrImportId',
          name: Routes.VIEW_DCR_DETAILS,
          meta,
          component: () => import('@/pages/DCR/DcrRowLogPage.vue'),
          props: (from) => {
            return {
              dcrImportId: from.params.dcrImportId
            };
          }
        }
      ]
    }
  ];
};
