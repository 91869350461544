
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component<ButtonComponent>({})
export default class ButtonComponent extends Vue {
  @Prop({ default: true })
  border!: boolean;

  @Prop({ default: 'val-button-blue' })
  textOrBorderColor!: string;

  @Prop({ default: 'white' })
  bg!: string;

  @Prop()
  disabled?: boolean;

  @Emit('click')
  clickHandler(evt: Event): Event {
    return evt;
  }

  get classes(): string[] {
    return [
      `text-${this.textOrBorderColor}`,
      this.border ? `border border-${this.textOrBorderColor}` : '',
      this.disabled ? 'bg-gray-500 cursor-not-allowed' : `bg-${this.bg}`
    ];
  }
}
