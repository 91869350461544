var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOutside",
          rawName: "v-clickOutside",
          value: _vm.hideCalendar,
          expression: "hideCalendar",
        },
      ],
      staticClass: "relative flex flex-col w-auto text-sm",
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        {
          staticClass: "w-full text-left focus:outline-none",
          class: {
            "cursor-default": _vm.readonly,
            "cursor-pointer": !_vm.readonly,
          },
        },
        [
          _vm.label
            ? _c("label", [
                _c(
                  "a",
                  {
                    class: [
                      _vm.link
                        ? "underline text-val-button-blue"
                        : "field-labels",
                    ],
                    attrs: { href: _vm.link !== null ? _vm.link : "" },
                  },
                  [_vm._v(_vm._s(_vm.label))]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "flex items-center w-full px-3 py-2 leading-tight bg-white border rounded h-9 whitespace-nowrap",
              class: {
                "border-red-500": _vm.showError,
                "bg-gray-100": _vm.readonly,
              },
              attrs: { "data-cy": "dateSelectorButtonClickableArea" },
            },
            [
              _c(
                "button",
                {
                  class: {
                    "border-red-500": _vm.showError,
                    "bg-gray-100": _vm.readonly,
                    "cursor-default": _vm.readonly,
                    "cursor-pointer": !_vm.readonly,
                  },
                  attrs: {
                    "data-cy": "dateSelectorButton",
                    disabled: _vm.disableDateEntry,
                    type: "button",
                  },
                  on: { click: _vm.handleOnClick, blur: _vm.onBlur },
                },
                [
                  _c("icon-component", {
                    staticClass: "mr-2 text-gray-300",
                    attrs: { name: "calendar", width: 17, height: 16 },
                  }),
                ],
                1
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                ref: "dateInputField",
                staticClass: "w-full outline-none",
                attrs: {
                  type: "text",
                  name: "dateInput",
                  placeholder: _vm.placeholder,
                  disabled: _vm.disableDateEntry,
                  maxlength: "11",
                },
                domProps: { value: _vm.model },
                on: {
                  blur: _vm.onBlur,
                  focus: _vm.handleFocus,
                  keypress: function ($event) {
                    return _vm.validateEntries($event)
                  },
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.model = $event.target.value
                    },
                    function ($event) {
                      return _vm.handleCursor($event.target, $event.inputType)
                    },
                  ],
                },
              }),
              _vm.showClearButton
                ? _c(
                    "button",
                    {
                      staticClass: "ml-auto",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clearValue.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("icon-component", {
                        staticClass: "text-gray-500",
                        attrs: {
                          "data-cy": "clearDateSelectorButton",
                          name: "x",
                          width: 12,
                          height: 12,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "relative" }, [
        _vm.showCalendar
          ? _c(
              "div",
              {
                staticClass:
                  "absolute z-50 p-2 bg-white border border-gray-300 rounded shadow",
                class: {
                  "-top-24": _vm.alignment === "middle",
                  "top-0": _vm.alignment === "top",
                  "bottom-9": _vm.alignment === "bottom",
                },
                staticStyle: { width: "max-content" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex items-center justify-between pb-2 border-b",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "px-3 text-xl font-bold cursor-pointer",
                        class: {
                          "text-gray-500": _vm.isFirstYear && _vm.isFirstMonth,
                        },
                        attrs: { disabled: _vm.disableButton, type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.subtractMonth.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" < ")]
                    ),
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("div", { staticClass: "mr-2" }, [
                        _vm._v(_vm._s(_vm.month)),
                      ]),
                      _c(
                        "select",
                        {
                          staticClass: "outline-none",
                          attrs: { name: _vm.year },
                          domProps: { value: _vm.year },
                          on: {
                            change: function ($event) {
                              return _vm.changeYear($event.target.value)
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        _vm._l(_vm.yearRange, function (year) {
                          return _c(
                            "option",
                            { key: year, domProps: { value: year } },
                            [_vm._v(" " + _vm._s(year) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "px-3 text-xl font-bold cursor-pointer",
                        class: {
                          "text-gray-500": _vm.isLastYear & _vm.isLastMonth,
                        },
                        attrs: { disabled: _vm.disableButton, type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addMonth.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" > ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "grid grid-cols-7",
                    attrs: { "data-cy": "dateSelectorSelectionGrid" },
                  },
                  [
                    _vm._l(_vm.days, function (day, index) {
                      return _c(
                        "div",
                        {
                          key: "day_" + index,
                          staticClass:
                            "flex items-center justify-center p-2 font-semibold",
                        },
                        [_vm._v(" " + _vm._s(day) + " ")]
                      )
                    }),
                    _vm._l(_vm.firstDayOfMonth, function (_, index) {
                      return _c(
                        "div",
                        {
                          key: "blank_" + index,
                          staticClass: "flex items-center justify-center p-2",
                        },
                        [_vm._v("   ")]
                      )
                    }),
                    _vm._l(_vm.daysInMonth, function (date, index) {
                      return _c(
                        "button",
                        {
                          key: "current-day_" + index,
                          staticClass:
                            "flex items-center justify-center p-2 text-gray-600 rounded cursor-pointer hover:bg-blue-200 focus:outline-none",
                          class: {
                            "bg-gray-200":
                              date == _vm.todaysDate &&
                              _vm.month == _vm.todaysMonth &&
                              _vm.year == _vm.todaysYear,
                            "bg-blue-300":
                              date == _vm.selectedDate &&
                              _vm.month == _vm.selectedMonth &&
                              _vm.year == _vm.selectedYear,
                          },
                          attrs: { "data-cy": "date_" + date },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.updateValue(date)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(date) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm.showError
        ? _c(
            "div",
            { staticClass: "mt-2 text-xs leading-tight text-red-500" },
            [_vm._v(" " + _vm._s(_vm.dateError || _vm.errorText) + " ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }