var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.close } }, [
        _c("div", { staticClass: "p-4 px-8 text-xs" }, [
          _c("h3", { staticClass: "my-4" }, [_vm._v("OTS Class")]),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 gap-4 my-4" },
            [
              _c("text-input", {
                attrs: {
                  "data-cy": "officerTrainingSchoolClassModalClassNumberInput",
                  label: "Class Number",
                  errorText: "Please enter a class name",
                  required: true,
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState(
                      "officerTrainingSchool.classNumber",
                      $event
                    )
                  },
                },
                model: {
                  value: _vm.officerTrainingSchool.classNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.officerTrainingSchool, "classNumber", $$v)
                  },
                  expression: "officerTrainingSchool.classNumber",
                },
              }),
              _c("date-selector", {
                staticClass: "col-start-1",
                attrs: {
                  "data-cy": "officerTrainingSchoolClassModalStartDateInput",
                  label: "Start date",
                  required: true,
                  errorText: "Please enter a start date",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState(
                      "officerTrainingSchool.startDate",
                      $event
                    )
                  },
                },
                model: {
                  value: _vm.officerTrainingSchool.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.officerTrainingSchool, "startDate", $$v)
                  },
                  expression: "officerTrainingSchool.startDate",
                },
              }),
              _c("date-selector", {
                attrs: {
                  "data-cy":
                    "officerTrainingSchoolClassModalGraduationDateInput",
                  label: "Class graduation",
                  required: true,
                  errorText: "Please enter a class graduation date",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.updateFormState(
                      "officerTrainingSchool.graduationDate",
                      $event
                    )
                  },
                },
                model: {
                  value: _vm.officerTrainingSchool.graduationDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.officerTrainingSchool, "graduationDate", $$v)
                  },
                  expression: "officerTrainingSchool.graduationDate",
                },
              }),
              _c("date-selector", {
                attrs: {
                  "data-cy":
                    "officerTrainingSchoolClassModalRosterEmailedDateInput",
                  label: "Roster emailed",
                },
                model: {
                  value: _vm.officerTrainingSchool.rosterEmailed,
                  callback: function ($$v) {
                    _vm.$set(_vm.officerTrainingSchool, "rosterEmailed", $$v)
                  },
                  expression: "officerTrainingSchool.rosterEmailed",
                },
              }),
              _c("date-selector", {
                attrs: {
                  "data-cy":
                    "officerTrainingSchoolClassModalRosterOnSiteDateInput",
                  label: "On site",
                },
                model: {
                  value: _vm.officerTrainingSchool.onSite,
                  callback: function ($$v) {
                    _vm.$set(_vm.officerTrainingSchool, "onSite", $$v)
                  },
                  expression: "officerTrainingSchool.onSite",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex items-center justify-end" }, [
            _c(
              "button",
              {
                staticClass:
                  "px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                attrs: {
                  "data-cy": "officerTrainingSchoolClassModalCancelButton",
                  type: "button",
                },
                on: { click: _vm.cancel },
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass:
                  "px-4 py-2 text-white rounded focus:outline-none focus:shadow-outline",
                class: {
                  "opacity-50": !_vm.valid,
                  "bg-gray-500": !_vm.valid,
                  "bg-blue-500": _vm.valid,
                },
                attrs: {
                  "data-cy": "officerTrainingSchoolClassModalSubmitButton",
                  type: "button",
                  disabled: !_vm.valid,
                },
                on: { click: _vm.done },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      !!_vm.officerTrainingSchool.id
                        ? "Save changes"
                        : "Create class"
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }