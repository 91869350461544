import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseEntity } from '../BaseEntity';
import {
  StudentStatus,
  Soc,
  QuotaAction,
  QuotaStatus,
  QuotaType
} from './Constants';
import { FlightTrainingCourse } from './FlightTrainingCourse';

export class FlightTrainingProgress extends BaseEntity {
  @Type(/* istanbul ignore next */ () => String)
  trainingLineNumber!: string | null; //student training ID

  @Type(/* istanbul ignore next */ () => String)
  phase!: string | null;

  @Type(/* istanbul ignore next */ () => String)
  quotaType!: QuotaType | null;

  @Type(/* istanbul ignore next */ () => String)
  status!: QuotaStatus | null;

  @Type(/* istanbul ignore next */ () => String)
  action!: QuotaAction | null;

  @Type(/* istanbul ignore next */ () => String)
  studentStatus!: StudentStatus | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  studentStatusEffectiveDate!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  dateSubAllocated!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  quotaRcdChangeDate!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  confirmDate!: Date | null;

  @Type(/* istanbul ignore next */ () => Date)
  @Transform(({ value }) => removeTimeZoneOffset(value))
  confirmSuspDate!: Date | null;

  @Type(/* istanbul ignore next */ () => String)
  tngLocationCode!: string | null;

  @Type(/* istanbul ignore next */ () => Number)
  officerAdsc!: number | null;

  @Type(/* istanbul ignore next */ () => String)
  ead!: string | null;

  @Type(/* istanbul ignore next */ () => String)
  soc!: Soc | null;

  @Type(/* istanbul ignore next */ () => String)
  det!: string | null;

  @Type(/* istanbul ignore next */ () => String)
  cpfld!: string | null;

  @Type(/* istanbul ignore next */ () => String)
  tprAfsc!: string | null;

  @Type(/* istanbul ignore next */ () => String)
  tprAfscType!: string | null;

  @Type(/* istanbul ignore next */ () => BaseCandidate)
  student!: BaseCandidate;

  @Type(/* istanbul ignore next */ () => FlightTrainingCourse)
  course: FlightTrainingCourse = new FlightTrainingCourse();

  @Type(/* istanbul ignore next */ () => Number)
  courseId!: number;

  constructor(partial?: Partial<FlightTrainingProgress>) {
    super(partial);
    if (partial) {
      Object.assign(this, plainToClass(FlightTrainingProgress, partial));
    }
  }
}
