import Bff from '@/api/bff';
import { BadgeAndMwsCourseMap } from '@/models/Entities/Student/BadgeAndMwsCourseMap';
import { ActionTree } from 'vuex';
import { FlightTrainingCourse } from '../../../models/Entities/Student/FlightTrainingCourse';
import { RootState } from '../../types';
import CrudModule, { MUTATIONS } from '../CrudModule';
import { ICrudModule, IModuleState } from '../ICrudModule';

export class FlightTrainingCourseModule
  extends CrudModule<FlightTrainingCourse>
  implements ICrudModule<FlightTrainingCourse>
{
  constructor(bff: Bff) {
    const path = 'flightTrainingCourse';
    super(FlightTrainingCourse, bff, path);

    const customActions: ActionTree<
      IModuleState<FlightTrainingCourse>,
      RootState
    > = {
      async fetchAllRequired(
        { commit },
        payload: { badge: string; mws: string }
      ) {
        const data: FlightTrainingCourse =
          await bff.fetch<FlightTrainingCourse>(
            `${path}/required/${payload.badge}/${payload.mws}`
          );
        commit(MUTATIONS.SET, data);
        return data;
      },
      patchRequired(_, payload: BadgeAndMwsCourseMap) {
        return bff.patch<BadgeAndMwsCourseMap>(`${path}/required/`, payload);
      }
    };

    // Apply custom actions
    this.applyActions(customActions);
  }
}
