/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_USER_ROLE, EUserRoles } from '@/enums';

import { plainToClass } from 'class-transformer';

interface IFirebaseUser {
  email: string | null;
  displayName: string | null;
  photoUrl: string | null;
}

interface IUser {
  uid: string | null;
  email: string | null;
  displayName: string | null;
  photoUrl: string | null;
  role: EUserRoles | null;
}

export interface IAuthUser {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
}

class User {
  public uid: string | null = null;
  public email: string | null = null;
  public displayName: string | null = null;
  public photoUrl: string | null = null;
  public role: EUserRoles | null = null;
  public deletedAt: Date | undefined;

  constructor(user?: Partial<IUser>) {
    if (user) {
      Object.assign(this, plainToClass(User, user));
    }
  }

  static toPatchDto(user: any): User {
    return new User({
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoUrl: user.photoUrl,
      role: user.role
    });
  }

  /**
   * indicates if the user has been "soft deleted" and should not
   * show up in some views
   *
   * @readonly
   * @type {boolean}
   * @memberof User
   */
  get isArchived(): boolean {
    return !!this.deletedAt;
  }

  static fromAuthClient(authUser: IAuthUser): User {
    return new User({
      uid: authUser.uid,
      email: authUser.email,
      displayName: authUser.displayName,
      photoUrl: authUser.photoURL,
      role: DEFAULT_USER_ROLE
    });
  }
}

export { IUser, User, IFirebaseUser };
