import { Vue, Component } from 'vue-property-decorator';
import { OpenProps } from '@/components/types';

@Component
export default class SnackbarMixin extends Vue {
  showSnackbar(data: OpenProps): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message: data.message,
      timeout: data.timeout,
      icon: data.icon
    });
  }
}
